import { useAuthStore } from '@/store/auth';
import { useRoute, useRouter } from 'vue-router';
import axios from '@axios';
import qs from 'qs';
import { ContentType } from '@/contentTypes';

export const useAuth = () => {
    const authStore = useAuthStore();
    const $router = useRouter();
    const $route = useRoute();

    async function checkAuth() {
        if (authStore.getToken) {
            try {
                const { data } = await axios.get(
                    `/users/me?${qs.stringify({
                        populate: {
                            permissions: {
                                fields: ['value'],
                            },
                            buildings: {
                                fields: ['id'],
                            },
                            centrals: {
                                fields: ['id'],
                            },
                            company: {
                                fields: ['id'],
                            },
                            profilePicture: true,
                        },
                    })}`,
                );
                authStore.setUser(data);
            } catch (error) {
                authStore.$reset();
                const authRequired = $route.matched.some((record) => record.meta.authRequired);
                if (authRequired) {
                    $router.push({ name: 'Login' });
                }
            }
        }
    }

    function can(permission: string) {
        return authStore.getUser?.permissions.some((p: ContentType<'NibblePermission'>) => p.value === permission);
    }

    return {
        checkAuth,
        can,
    };
};
