import { onMounted } from 'vue';

export function useTable(id: string) {
    onMounted(() => {
        document.getElementById(id)?.addEventListener('mouseover', (e) => {
            const target = e.target as HTMLElement;
            let currentElement: HTMLElement | null = target;
            while (currentElement && currentElement.tagName !== 'TR') {
                currentElement = currentElement.parentElement;
            }

            if (currentElement && currentElement.getAttribute('attr') === 'isNew') {
                currentElement.removeAttribute('attr');
            }
        });
    });
}
