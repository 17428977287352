<template>
    <v-form
        v-if="_building"
        @submit.prevent="onSubmit">
        <v-row>
            <v-col
                cols="12"
                sm="6"
                md="4"
                xl="4">
                <v-label class="tw-mb-1 tw-text-lg tw-text-dark tw-opacity-100">{{ $t('views.buildings.coordinates') }}</v-label>
                <div class="gps-input tw-flex tw-items-center">
                    <v-text-field
                        :placeholder="$t('views.buildings.lat')"
                        v-model="building.lat"
                        :readonly="!isManager || isLoading"></v-text-field>
                    <span class="tw-shrink">|</span>
                    <v-text-field
                        :placeholder="$t('views.buildings.lng')"
                        v-model="building.lng"
                        :readonly="!isManager || isLoading"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="10"
                md="8"
                xl="8">
                <v-label class="tw-mb-1 tw-text-lg tw-text-dark tw-opacity-100">{{ $t('views.buildings.address') }}</v-label>
                <v-text-field
                    v-model="building.address"
                    :readonly="!isManager || isLoading"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4">
                <v-label class="tw-mb-1 tw-text-lg tw-text-dark tw-opacity-100">{{ $t('fields.email') }}</v-label>
                <v-text-field
                    type="email"
                    v-model="building.email"
                    :readonly="!isManager || isLoading"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4">
                <v-label class="tw-mb-1 tw-text-lg tw-text-dark tw-opacity-100">{{ $t('views.buildings.phone') }}</v-label>
                <v-text-field
                    v-model="building.phone"
                    :readonly="!isManager || isLoading"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4">
                <v-label class="tw-mb-1 tw-text-lg tw-text-dark tw-opacity-100">{{ $t('views.buildings.company') }}</v-label>
                <v-text-field
                    :model-value="companyName"
                    readonly></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4">
                <v-label class="tw-mb-1 tw-text-lg tw-text-dark tw-opacity-100">{{ $t('views.buildings.manager') }}</v-label>
                <ioAutocomplete
                    :error-messages="autocompleteMessages"
                    :query="_building"
                    v-model="building.manager"
                    :fetch-items="fetchUsers"
                    :readonly="!isManager || isLoading" />
            </v-col>
            <v-col
                cols="12"
                md="8"
                class="tw-flex tw-items-end tw-justify-end">
                <div v-if="isManager">
                    <v-btn
                        type="submit"
                        :loading="isLoading"
                        color="primary">
                        {{ $t('shared.save') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { ContentType } from '@/contentTypes';
    import { PropType, computed, ref, watch, inject, Ref } from 'vue';
    import { useAuthStore } from '../../store/auth';
    import ioAutocomplete from '@/components/ioAutocomplete.vue';
    import { isArray, set } from 'lodash';
    import { getUsers } from '@/api/users';
    import { useI18n } from 'vue-i18n';
    import { updateBuilding } from '@/api/buildings';
    import { useAlert } from '@/composables/useAlert';
    import useError from '@/composables/useError';

    const _building = inject<Ref<ContentType<'Building'>>>('building');

    const isLoading = ref(false);
    const $alert = useAlert();
    const $error = useError();

    const building = ref<ContentType<'Building', false>>({
        name: '',
        address: '',
        email: '',
        phone: '',
        lat: null,
        lng: null,
        manager: null,
    });
    const { t } = useI18n();
    const authStore = useAuthStore();

    const isManager = computed(() => _building?.value?.manager?.id == authStore.user?.id);

    const autocompleteMessages = computed(() => {
        const messages = [];
        if (isManager.value && authStore.getUser.id != building.value.manager) messages.push(t('views.buildings.updateManagerWarning'));
        return messages;
    });

    const companyName = computed(() => _building?.value?.company?.name ?? 'N/A');

    async function fetchUsers({ page, search, ids }: { page?: number; search?: string; ids?: [number] }) {
        const query = {};

        set(query, 'company.id', _building?.value?.company?.id);
        const $or = [];
        if (search) {
            $or.push({ name: { $containsi: search } });
            $or.push({ email: { $containsi: search } });
        }

        if (isArray(ids) && ids.length > 0) {
            $or.push({ id: { $in: ids } });
        }

        return getUsers({
            fields: ['id', 'name', 'email'],
            filters: query,
            pagination: {
                page,
            },
        });
    }

    async function onSubmit() {
        if (!_building) return;
        isLoading.value = true;

        try {
            await updateBuilding(_building.value.id as number, building.value);
            $alert.showAlert({
                type: 'success',
                text: t('views.buildings.updateSuccess'),
            });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: $error.getErrorMessage(error, 'views.buildings.errors'),
            });
        } finally {
            isLoading.value = false;
        }
    }

    watch(
        () => _building?.value,
        (newValue) => {
            if (!newValue)
                building.value = {
                    name: '',
                    address: '',
                    email: '',
                    phone: '',
                    lat: null,
                    lng: null,
                    manager: null,
                };
            else
                building.value = {
                    name: newValue?.name,
                    address: newValue?.address,
                    email: newValue?.email,
                    phone: newValue?.phone,
                    lat: newValue?.lat,
                    lng: newValue?.lng,
                    manager: newValue?.manager?.id ?? null,
                };
        },
        { immediate: true },
    );
</script>

<style>
    .gps-input {
        @apply tw-overflow-hidden tw-rounded-xl tw-border tw-border-gray-600 tw-bg-gray-50 !important;
        .v-field__outline__start,
        .v-field__outline__end {
            display: none;
        }
    }
</style>
