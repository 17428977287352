// Utilities
import { DeviceInstalled } from '@/contentTypes';
import { defineStore } from 'pinia';

export const useBlueprintStore = defineStore('blueprint', {
    state: () => ({
        devices: [] as DeviceInstalled[],
    }),
    getters: {
        getDevicesForBlueprint(): DeviceInstalled[] {
            return this.devices.filter((device) => !!device.blueprintData);
        },
        getDevicesForSidebar(): DeviceInstalled[] {
            return this.devices.filter((device) => !device.blueprintData);
        },
    },
    actions: {
        setDevices(value: DeviceInstalled[]) {
            this.devices = value;
        },
        updateDevice(id: number, blueprintData: any) {
            const device = this.devices.find((device) => device.id === id);
            if (device) {
                device.blueprintData = blueprintData;
            }
        },
    },
});
