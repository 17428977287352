<template>
    <v-form
        ref="form"
        v-if="zone"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">{{ $t('views.centrals.configurations.title') }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.zones.name') }}
                </v-label>
                <div>
                    <v-text-field
                        :readonly="isLoading || !$auth.can('edit_zones')"
                        :rules="[$rules.required]"
                        hide-details="auto"
                        background-color="primary"
                        v-model="zone.name"
                        variant="outlined"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.zone.configurations.fields.mode') }}
                </v-label>
                <div>
                    <v-select
                        :readonly="isLoading || !$auth.can('edit_zones')"
                        :items="zoneModes"
                        v-model="zone.mode"
                        item-title="text"
                        item-value="value">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.zone.configurations.fields.type') }}
                </v-label>
                <div>
                    <v-select
                        :readonly="isLoading || !$auth.can('edit_zones')"
                        :items="zoneTypes"
                        v-model="zone.type"
                        item-title="text"
                        item-value="value">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.zone.configurations.fields.delay') }}
                </v-label>
                <div>
                    <v-text-field
                        :rules="[$rules.required, $rules.integer, (v) => $rules.min(parseInt(v), 0), (v) => $rules.max(parseInt(v), 600)]"
                        :hint="$t('zoneRules.delaySounder.hint')"
                        :readonly="isLoading || !$auth.can('edit_zones')"
                        type="number"
                        :suffix="$t('shared.sec')"
                        v-model="zone.delaySounder"
                        hide-spin-buttons></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.zone.configurations.fields.cancelTime') }}
                </v-label>
                <div>
                    <v-text-field
                        :readonly="isLoading || !$auth.can('edit_zones')"
                        :rules="[$rules.required, $rules.integer, (v) => $rules.min(parseInt(v), 300), (v) => $rules.max(parseInt(v), 1800)]"
                        :hint="$t('zoneRules.delayCancellation.hint')"
                        v-model="zone.delayCancellation"
                        hide-spin-buttons
                        type="number"
                        :suffix="$t('shared.sec')"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.zone.configurations.fields.delayConfirmation') }}
                </v-label>
                <div>
                    <v-text-field
                        :readonly="isLoading || !$auth.can('edit_zones')"
                        :rules="[$rules.required, $rules.integer, (v) => $rules.min(parseInt(v), 0), (v) => $rules.max(parseInt(v), 60)]"
                        :hint="$t('zoneRules.delayConfirmation.hint')"
                        v-model="zone.delayConfirmation"
                        hide-spin-buttons
                        type="number"
                        :suffix="$t('shared.sec')"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.zone.configurations.fields.maxISafeLED') }}
                </v-label>
                <div>
                    <v-text-field
                        :readonly="isLoading || !$auth.can('edit_zones')"
                        :rules="[$rules.required, $rules.integer, (v) => $rules.min(parseInt(v), 0), (v) => $rules.max(parseInt(v), 5)]"
                        :hint="$t('zoneRules.maxISafeLED.hint')"
                        v-model="zone.maxISafeLED"
                        hide-spin-buttons
                        type="number"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.zone.configurations.fields.networkZones') }}
                </v-label>
                <div>
                    <v-btn-toggle
                        :disabled="isLoading || !$auth.can('edit_zones')"
                        mandatory
                        color="primary"
                        v-model="zone.networkMode"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true"> {{ $t('shared.yes') }} </v-btn>
                        <v-btn :value="false"> {{ $t('shared.no') }} </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.zone.configurations.fields.address') }}
                </v-label>
                <div>
                    <v-text-field
                        readonly
                        :value="zone.physicalAddress"></v-text-field>
                </div>
            </v-col>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    v-if="$auth.can('edit_zones')"
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { PropType, computed } from 'vue';
    import { ContentType } from '@/contentTypes';
    import { ref } from 'vue';
    import { updateZone } from '@/api/zones';
    //@ts-ignore
    import { Zone_Mode, Zone_Type } from '@/contentTypes.d.ts';
    import { useI18n } from 'vue-i18n';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import useRules from '@/composables/rules';
    import { useAlert } from '@/composables/useAlert';
    import useError from '../../composables/useError';
    import { useAuth } from '@/composables/useAuth';

    const { t } = useI18n();
    const $rules = useRules();
    const $alert = useAlert();
    const $error = useError();
    const $auth = useAuth();
    const isLoading = ref(false);

    const props = defineProps({
        zone: {
            type: Object as PropType<ContentType<'Zone'>>,
            required: true,
        },
    });

    const form = ref<VForm>();
    const zone = ref<ContentType<'Zone'>>(props.zone);

    const zoneModes = computed(() => {
        return Object.values(Zone_Mode).map((value) => {
            return {
                text: t(`zoneModes.${value}`),
                value: value,
            };
        });
    });

    const zoneTypes = computed(() => {
        return Object.values(Zone_Type).map((value) => {
            return {
                text: t(`zoneTypes.${value}`),
                value: value,
            };
        });
    });

    async function onSubmit() {
        if (!form.value) return;

        const { valid } = await form.value.validate();

        if (!valid) return;

        isLoading.value = true;

        try {
            await updateZone(zone.value.id as number, {
                name: zone.value.name,
                mode: zone.value.mode,
                type: zone.value.type,
                delaySounder: zone.value.delaySounder,
                delayCancellation: zone.value.delayCancellation,
                delayConfirmation: zone.value.delayConfirmation,
                networkMode: zone.value.networkMode,
                maxISafeLED: zone.value.maxISafeLED,
                physicalAddress: zone.value.physicalAddress,
            });

            $alert.showAlert({
                text: t('views.zones.updateSuccess'),
                type: 'success',
            });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                text: $error.getErrorMessage(error, 'views.zones'),
                type: 'error',
            });
        } finally {
            isLoading.value = false;
        }
    }
</script>

<style scoped></style>
