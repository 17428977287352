import moment from 'moment';

export function useDate(date: Date | null | string | undefined) {
    const formattedDate = (() => {
        if (date) {
            return moment(date).format('HH:mm (DD/MM/YYYY)');
        } else {
            return 'N/A';
        }
    })();

    return {
        formattedDate,
    };
}
