export enum User_Locale {
    Pt = 'PT',
    En = 'EN',
}

export enum Central_DayNight {
    Day = 'day',
    Night = 'night',
    ProgrammedDay = 'programmed (day)',
    ProgrammedNight = 'programmed (night)',
}
export enum Central_Sounders {
    Common = 'common',
    Zonal = 'zonal',
}
export enum Central_SoundersTone {
    AlwaysEvacuating = 'always evacuating',
    Normal = 'normal',
}

export enum Configuration_FieldType {
    String = 'string',
    Integer = 'integer',
    Float = 'float',
    Decimal = 'decimal',
    Boolean = 'boolean',
    Enumeration = 'enumeration',
}

export enum Device_Type {
    None = 'none',
    Detector = 'detector',
    Input = 'input',
    Output = 'output',
    Sounder = 'sounder',
    InputOutput = 'input/output',
    Callpoint = 'callpoint',
    Beam = 'beam',
}

export enum DeviceInstalled_Status {
    Unknown = 'unknown',
    Online = 'online',
    Offline = 'offline',
    CommFailure = 'comm failure',
    DuplicateAddress = 'duplicate address',
    Missing = 'missing',
}
export enum DeviceInstalled_AlarmType {
    Default = 'default',
    Zone = 'zone',
    General = 'general',
}
export enum DeviceInstalled_AlarmStatus {
    Fault = 'fault',
    Normal = 'normal',
    PreAlarm = 'pre-alarm',
    Alarm = 'alarm',
    Unknown = 'unknown',
    On = 'on',
    Off = 'off',
    Open = 'open',
    Short = 'short',
}

export enum Event_Type {
    Alarm = 'alarm',
    Failure = 'failure',
    Information = 'information',
    System = 'system',
}
export enum Event_Origin {
    Device = 'device',
    Loop = 'loop',
    Zone = 'zone',
    Central = 'central',
    System = 'system',
}

export enum Loop_Status {
    Unknown = 'unknown',
    Online = 'online',
    Offline = 'offline',
    CommFailure = 'comm failure',
    Disabled = 'disabled',
}

export enum Zone_Mode {
    Normal = 'normal',
    Devices2TypeA = 'devices2TypeA',
    Devices2TypeB = 'devices2TypeB',
}
export enum Zone_Type {
    Normal = 'normal',
    DisableDay = 'disableDay',
    DisableNight = 'disableNight',
}

export interface Media<P extends boolean = true> {
    id?: number;
    name: string;
    alternativeText?: string;
    caption?: string;
    width?: number | null;
    height?: number | null;
    formats?: { thumbnail: MediaFormat; small: MediaFormat; medium: MediaFormat; large: MediaFormat };
    hash?: string;
    ext?: string;
    mime: string;
    size: number | null;
    url: string;
    previewUrl?: string;
    provider: string;
    provider_metadata?: any;
    folderPath?: string;
    createdAt?: string | null;
    updatedAt?: string | null;
}

export interface Role<P extends boolean = true> {
    id?: number;
    name: string;
    description?: string;
    type?: string;
    users?: P extends true ? User<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface User<P extends boolean = true> {
    id?: number;
    username: string;
    email: string;
    provider?: string;
    password?: string;
    resetPasswordToken?: string;
    confirmationToken?: string;
    confirmed?: boolean;
    blocked?: boolean;
    role?: P extends true ? Role<P> | null : number | null;
    buildings?: P extends true ? Building<P>[] : number[];
    events?: P extends true ? Event<P>[] : number[];
    centrals?: P extends true ? Central<P>[] : number[];
    name: string;
    company?: P extends true ? Company<P> | null : number | null;
    profilePicture?: P extends true ? Media<P> | null : number | null;
    permissions?: P extends true ? NibblePermission<P>[] : number[];
    locale?: User_Locale;
    notificationTokens?: P extends true ? UserNotificationToken<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface EmailTemplate<P extends boolean = true> {
    id?: number;
    templateReferenceId?: number | null;
    design?: any;
    name?: string;
    subject?: string;
    bodyHtml?: string;
    bodyText?: string;
    enabled?: boolean;
    tags?: any;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface AppTranslation<P extends boolean = true> {
    id?: number;
    locale: string;
    json?: any;
    createdAt?: Date | null;
    updatedAt?: Date | null;
    locale: string;
    localizations?: AppTranslation[];
}

export interface Building<P extends boolean = true> {
    id?: number;
    users?: P extends true ? User<P>[] : number[];
    name: string;
    address?: string;
    phone?: string;
    email?: string;
    company?: P extends true ? Company<P> | null : number | null;
    floors?: P extends true ? Floor<P>[] : number[];
    lat?: number | null;
    lng?: number | null;
    manager?: P extends true ? User<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Central<P extends boolean = true> {
    id?: number;
    name: string;
    model?: string;
    serialNumber?: string;
    firmware?: string;
    status?: string;
    zones?: P extends true ? Zone<P>[] : number[];
    loops?: P extends true ? Loop<P>[] : number[];
    events?: P extends true ? Event<P>[] : number[];
    floor?: P extends true ? Floor<P> | null : number | null;
    users?: P extends true ? User<P>[] : number[];
    lastSeen?: Date | null;
    adminEnrollmentKey?: string;
    dayNight?: Central_DayNight;
    maintLast?: Date | null;
    maintInterval?: number | null;
    autoLogoutTime?: number | null;
    commonDelay?: number | null;
    investigationDelay?: number | null;
    resound?: boolean;
    disableDevicePrealarms?: boolean;
    sounders?: Central_Sounders;
    soundersTone?: Central_SoundersTone;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Company<P extends boolean = true> {
    id?: number;
    name: string;
    buildings?: P extends true ? Building<P>[] : number[];
    users?: P extends true ? User<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Configuration<P extends boolean = true> {
    id?: number;
    field?: string;
    fieldType?: Configuration_FieldType;
    fieldEnumeration?: string;
    deviceConfigurations?: P extends true ? DeviceConfiguration<P>[] : number[];
    defaultValue?: string;
    devices?: P extends true ? Device<P>[] : number[];
    required?: boolean;
    min?: number | null;
    max?: number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Device<P extends boolean = true> {
    id?: number;
    model: string;
    manufacturer?: string;
    type?: Device_Type;
    picture?: P extends true ? Media<P> | null : number | null;
    configurations?: P extends true ? Configuration<P>[] : number[];
    group: number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface DeviceConfiguration<P extends boolean = true> {
    id?: number;
    configuration?: P extends true ? Configuration<P> | null : number | null;
    value?: string;
    device?: P extends true ? DeviceInstalled<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface DeviceInstalled<P extends boolean = true> {
    id?: number;
    device?: P extends true ? Device<P> | null : number | null;
    name?: string;
    loop?: P extends true ? Loop<P> | null : number | null;
    physicalAddress?: string;
    zone?: P extends true ? Zone<P> | null : number | null;
    status?: DeviceInstalled_Status;
    events?: P extends true ? Event<P>[] : number[];
    configurations?: P extends true ? DeviceConfiguration<P>[] : number[];
    serialNumber?: string;
    enable?: boolean;
    modeIO?: boolean;
    group?: number | null;
    alarmType?: DeviceInstalled_AlarmType;
    alarmStatus?: DeviceInstalled_AlarmStatus;
    blueprintData?: any;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Event<P extends boolean = true> {
    id?: number;
    type?: Event_Type;
    origin?: Event_Origin;
    central?: P extends true ? Central<P> | null : number | null;
    device?: P extends true ? DeviceInstalled<P> | null : number | null;
    loop?: P extends true ? Loop<P> | null : number | null;
    zone?: P extends true ? Zone<P> | null : number | null;
    description?: string;
    inProgress?: boolean;
    occurredAt?: Date | null;
    arrivedAt?: Date | null;
    user?: P extends true ? User<P> | null : number | null;
    acknowledgedAt?: Date | null;
    message?: string;
    building?: P extends true ? Building<P> | null : number | null;
    company?: P extends true ? Company<P> | null : number | null;
    floor?: P extends true ? Floor<P> | null : number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Floor<P extends boolean = true> {
    id?: number;
    name?: string;
    blueprint?: P extends true ? Media<P> | null : number | null;
    building?: P extends true ? Building<P> | null : number | null;
    centrals?: P extends true ? Central<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Loop<P extends boolean = true> {
    id?: number;
    loopID?: string;
    firmware?: string;
    protocol?: string;
    status?: Loop_Status;
    framesA?: number;
    errorsA?: number;
    current?: number;
    impedance?: number;
    central?: P extends true ? Central<P> | null : number | null;
    devices?: P extends true ? DeviceInstalled<P>[] : number[];
    events?: P extends true ? Event<P>[] : number[];
    framesB?: number | null;
    errorsB?: number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface NibblePermission<P extends boolean = true> {
    id?: number;
    value?: string;
    users?: P extends true ? User<P>[] : number[];
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface UserNotificationToken<P extends boolean = true> {
    id?: number;
    user?: P extends true ? User<P> | null : number | null;
    token?: string;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface Zone<P extends boolean = true> {
    id?: number;
    name: string;
    mode: Zone_Mode;
    type: Zone_Type;
    physicalAddress?: string;
    central?: P extends true ? Central<P> | null : number | null;
    devices?: P extends true ? DeviceInstalled<P>[] : number[];
    events?: P extends true ? Event<P>[] : number[];
    networkMode?: boolean;
    enable?: boolean;
    iSafe?: boolean;
    delaySounder?: number | null;
    delayConfirmation?: number | null;
    delayCancellation?: number | null;
    maxISafeLED?: number | null;
    deviceCatDisabledWithZone?: number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface MediaFormat {
    name: string;
    hash: string;
    ext: string;
    mime: string;
    width: number;
    height: number;
    size: number;
    path: string;
    url: string;
}
export interface ContentTypes<P extends boolean = true> {
    Media: Media<P>;
    Role: Role<P>;
    User: User<P>;
    EmailTemplate: EmailTemplate<P>;
    AppTranslation: AppTranslation<P>;
    Building: Building<P>;
    Central: Central<P>;
    Company: Company<P>;
    Configuration: Configuration<P>;
    Device: Device<P>;
    DeviceConfiguration: DeviceConfiguration<P>;
    DeviceInstalled: DeviceInstalled<P>;
    Event: Event<P>;
    Floor: Floor<P>;
    Loop: Loop<P>;
    NibblePermission: NibblePermission<P>;
    UserNotificationToken: UserNotificationToken<P>;
    Zone: Zone<P>;
}
export enum ContentTypesUID {
    Media = 'plugin::upload.file',
    Role = 'plugin::users-permissions.role',
    User = 'plugin::users-permissions.user',
    EmailTemplate = 'plugin::email-designer.email-template',
    AppTranslation = 'api::app-translation.app-translation',
    Building = 'api::building.building',
    Central = 'api::central.central',
    Company = 'api::company.company',
    Configuration = 'api::configuration.configuration',
    Device = 'api::device.device',
    DeviceConfiguration = 'api::device-configuration.device-configuration',
    DeviceInstalled = 'api::device-installed.device-installed',
    Event = 'api::event.event',
    Floor = 'api::floor.floor',
    Loop = 'api::loop.loop',
    NibblePermission = 'api::nibble-permission.nibble-permission',
    UserNotificationToken = 'api::user-notification-token.user-notification-token',
    Zone = 'api::zone.zone',
}
export type ContentType<T extends keyof ContentTypes, P extends boolean = true> = ContentTypes<P>[T];

export interface APIResponseMany<T extends keyof ContentTypes> {
    data: ContentType<T>[];
    meta: {
        pagination: {
            page: number;
            pageSize: number;
            pageCount: number;
            total: number;
        };
    };
}

export interface APIResponseSingle<T extends keyof ContentTypes> {
    data: ContentType<T>;
}

export interface APIRequestParams<T extends keyof ContentTypes> {
    populate?: any;
    fields?: (keyof ContentType<T, false>)[];
    locale?: string | string[];
    filters?: any;
    sort?:
        | `${string & keyof ContentType<T, false>}:asc`
        | `${string & keyof ContentType<T, false>}:desc`
        | (`${string & keyof ContentType<T, false>}:asc` | `${string & keyof ContentType<T, false>}:desc`)[];
    pagination?: {
        page?: number;
        pageSize?: number;
    };
}
