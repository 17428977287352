<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">{{ $t('views.centrals.configurations.title') }}</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.name') }}
                </v-label>
                <div>
                    <v-text-field
                        :readonly="isLoading || !$auth.can('edit_centrals')"
                        :rules="[$rules.required]"
                        v-model="central.name"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> dayNight </v-label>
                <div>
                    <v-select
                        :readonly="isLoading || !$auth.can('edit_centrals')"
                        :items="dayNightOptions"
                        v-model="central.dayNight"
                        item-title="text"
                        item-value="value">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.configurations.fields.delay') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        :readonly="isLoading || !$auth.can('edit_centrals')"
                        :rules="[$rules.required, $rules.integer, (v) => $rules.min(parseInt(v), 0), (v) => $rules.max(parseInt(v), 600)]"
                        :hint="$t('centralRules.commonDelay.hint')"
                        hide-spin-buttons
                        v-model="central.commonDelay"
                        :suffix="$t('shared.sec')"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.configurations.fields.investigation_time') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        :rules="[$rules.required, $rules.integer, (v) => $rules.min(parseInt(v), 0), (v) => $rules.max(parseInt(v), 600)]"
                        :readonly="isLoading || !$auth.can('edit_centrals')"
                        :hint="$t('centralRules.investigationDelay.hint')"
                        hide-spin-buttons
                        v-model="central.investigationDelay"
                        :suffix="$t('shared.sec')"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.configurations.fields.siren_mode') }}
                </v-label>
                <div>
                    <v-select
                        :readonly="isLoading || !$auth.can('edit_centrals')"
                        :items="soundersOptions"
                        v-model="central.sounders"
                        item-title="text"
                        item-value="value">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.configurations.fields.sirensTone') }} </v-label>
                <div>
                    <v-select
                        :readonly="isLoading || !$auth.can('edit_centrals')"
                        :items="soundersToneOptions"
                        v-model="central.soundersTone"
                        item-title="text"
                        item-value="value">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.configurations.fields.logout_time') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        hide-spin-buttons
                        :rules="[$rules.required, $rules.integer, (v) => $rules.min(parseInt(v), 0), (v) => $rules.max(parseInt(v), 3600)]"
                        :hint="$t('centralRules.autoLogoutTime.hint')"
                        :readonly="isLoading || !$auth.can('edit_centrals')"
                        v-model="central.autoLogoutTime"
                        :suffix="$t('shared.sec')"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                    {{ $t('views.centrals.configurations.fields.maintenance_warnings') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        hide-spin-buttons
                        :rules="[$rules.required, $rules.integer, (v) => $rules.min(parseInt(v), 0), (v) => $rules.max(parseInt(v), 24)]"
                        :hint="$t('centralRules.maintInterval.hint')"
                        :readonly="isLoading || !$auth.can('edit_centrals')"
                        :suffix="$t('shared.months')"
                        v-model="central.maintInterval"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.configurations.fields.resound') }} </v-label>
                <div>
                    <v-btn-toggle
                        :disabled="isLoading || !$auth.can('edit_centrals')"
                        mandatory
                        color="primary"
                        v-model="central.resound"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true"> {{ $t('shared.yes') }} </v-btn>
                        <v-btn :value="false"> {{ $t('shared.no') }} </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.configurations.fields.disableDevicePrealarms') }} </v-label>
                <div>
                    <v-btn-toggle
                        :disabled="isLoading || !$auth.can('edit_centrals')"
                        mandatory
                        color="primary"
                        v-model="central.disableDevicePrealarms"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true"> {{ $t('shared.yes') }} </v-btn>
                        <v-btn :value="false"> {{ $t('shared.no') }} </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    v-if="$auth.can('edit_centrals')"
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import useRules from '@/composables/rules';
    import { useAlert } from '@/composables/useAlert';
    import useError from '@/composables/useError';
    import { ref, inject } from 'vue';
    import type { Ref } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    //@ts-ignore
    import { Central_DayNight, Central_Sounders, Central_SoundersTone } from '@/contentTypes.d.ts';
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { ContentType } from '../../contentTypes';
    import { updateCentral } from '@/api/centrals';
    import { useAuth } from '../../composables/useAuth';

    const $rules = useRules();
    const $alert = useAlert();
    const $error = useError();
    const $auth = useAuth();
    const { t } = useI18n();

    const isLoading = ref(false);
    const central = inject<Ref<ContentType<'Central'>>>(
        'central',
        ref({
            name: '',
        }),
    );
    const form = ref<VForm>();

    const dayNightOptions = computed(() => {
        return Object.values(Central_DayNight).map((value) => {
            return {
                text: t(`centralDayNight.${value}`),
                value: value,
            };
        });
    });

    const soundersOptions = computed(() => {
        return Object.values(Central_Sounders).map((value) => {
            return {
                text: t(`centralSounders.${value}`),
                value: value,
            };
        });
    });

    const soundersToneOptions = computed(() => {
        return Object.values(Central_SoundersTone).map((value) => {
            return {
                text: t(`centralSoundersTone.${value}`),
                value: value,
            };
        });
    });

    async function onSubmit() {
        if (!form.value) return;

        const { valid } = await form.value.validate();

        if (!valid) return;

        isLoading.value = true;

        try {
            await updateCentral(central.value.id as number, {
                name: central.value.name,
                dayNight: central.value.dayNight,
                commonDelay: central.value.commonDelay,
                investigationDelay: central.value.investigationDelay,
                sounders: central.value.sounders,
                soundersTone: central.value.soundersTone,
                autoLogoutTime: central.value.autoLogoutTime,
                maintInterval: central.value.maintInterval,
                resound: central.value.resound,
                disableDevicePrealarms: central.value.disableDevicePrealarms,
            });

            $alert.showAlert({
                text: t('views.centrals.updateSuccess'),
                type: 'success',
            });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                text: $error.getErrorMessage(error, 'views.centrals'),
                type: 'error',
            });
        } finally {
            isLoading.value = false;
        }
    }
</script>

<style scoped></style>
