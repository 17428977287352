<template>
    <v-row
        align="start"
        justify="start"
        class="fill-width h-auto justify-start align-content-start">
        <v-col
            cols="12"
            class="tw-flex tw-flex-wrap tw-gap-2">
            <v-text-field
                class="tw-grow max-sm:tw-w-full"
                :model-value="search"
                @update:model-value="onSearch"
                clearable
                :placeholder="$t('shared.search')"></v-text-field>
            <v-btn-toggle
                multiple
                :model-value="typeFilters"
                @update:model-value="setFilters"
                class="tw-ml-auto">
                <v-btn
                    v-for="(details, type) in eventTypeDetails"
                    :key="type"
                    :color="details.bgColor"
                    :value="type"
                    :class="`text-${typeFilters.includes(type) ? details.color : details.bgColor} tw-border tw-border-gray`"
                    :icon="details.icon"></v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>
    <v-row v-if="isLoading">
        <v-col cols="12">
            <v-progress-linear
                :active="isLoading"
                color="primary"
                indeterminate></v-progress-linear>
        </v-col>
    </v-row>
    <template v-if="building">
        <v-card
            v-for="central in centralsFiltered"
            :key="central.id"
            variant="outlined"
            class="mx-auto pa-5 h-100 tw-my-6"
            color="gray">
            <v-row align="center">
                <v-col cols="9">
                    <v-btn
                        variant="text"
                        color="dark"
                        class="md:tw-text-2xl tw-text-lg tw-font-semibold tw-p-0"
                        :to="{
                            name: 'Central-Zones',
                            params: {
                                id: central.id,
                            },
                        }"
                        >{{ central.name }}</v-btn
                    >
                </v-col>
                <v-col cols="3">
                    <h6
                        :class="[getStatusDetails(central.status)?.twBgColor, getStatusDetails(central.status)?.twColor]"
                        class="tw-float-end tw-w-min tw-rounded-md tw-px-3 tw-py-1">
                        {{ getStatusDetails(central.status)?.text }}
                    </h6>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    md="6">
                    <v-card
                        class="mx-auto h-100 tw-flex tw-p-3 xs:tw-p-5"
                        variant="outlined"
                        color="gray">
                        <div class="tw-flex tw-w-full tw-items-center tw-justify-between max-xs:tw-flex-col max-xs:tw-gap-4">
                            <p>{{ $t('fields.zones') }}: {{ central.zones.count }}</p>
                            <div class="tw-flex tw-justify-end tw-gap-4">
                                <v-badge
                                    v-for="(event, i) in getEventTypes(central, 'zones')"
                                    :content="event.count"
                                    :color="event.count ? 'primary' : 'gray'"
                                    class="tw-cursor-pointer"
                                    :class="`tw-z-${getEventTypes(central, 'zones').length - i}`"
                                    @click.stop="
                                        $router.push({
                                            name: 'Central-Zones',
                                            params: {
                                                id: central.id,
                                            },
                                            query: {
                                                type: event.type,
                                            },
                                        })
                                    "
                                    :key="event.type">
                                    <v-icon
                                        :color="event.color"
                                        :icon="event.icon"
                                        :class="`${event.twBgColor} tw-rounded-md tw-p-4`"
                                        size="large"></v-icon>
                                </v-badge>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col
                    cols="12"
                    md="6">
                    <v-card
                        class="mx-auto h-100 tw-flex tw-p-3 xs:tw-p-5"
                        variant="outlined"
                        color="gray">
                        <div class="tw-flex tw-w-full tw-items-center tw-justify-between max-xs:tw-flex-col max-xs:tw-gap-4">
                            <p>{{ $t('fields.devices') }}: {{ central.devices.count }}</p>
                            <div class="tw-flex tw-justify-end tw-gap-4">
                                <v-badge
                                    v-for="(event, i) in getEventTypes(central, 'devices')"
                                    :content="event.count"
                                    :color="event.count ? 'primary' : 'gray'"
                                    class="tw-cursor-pointer"
                                    :class="`tw-z-${getEventTypes(central, 'devices').length - i}`"
                                    @click.stop="
                                        $router.push({
                                            name: 'Central-Devices',
                                            params: {
                                                id: central.id,
                                            },
                                            query: {
                                                type: event.type,
                                            },
                                        })
                                    "
                                    :key="event.type">
                                    <v-icon
                                        :color="event.color"
                                        :icon="event.icon"
                                        :class="`${event.twBgColor} tw-rounded-md tw-p-4`"
                                        size="large"></v-icon>
                                </v-badge>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <div v-if="!centralsFiltered.length">
            <v-row class="tw-mt-8">
                <v-col cols="12">
                    <p
                        v-show="hasFilters"
                        class="tw-text-center">
                        {{ $t('views.buildings.noCentralsWithFilters') }}
                    </p>
                    <p
                        v-show="!hasFilters"
                        class="tw-text-center">
                        {{ $t('views.buildings.noCentrals') }}
                    </p>
                </v-col>
            </v-row>
        </div>
    </template>
</template>

<script setup lang="ts">
    import { getBuildingCentralsEvents } from '@/api/buildings';
    import { computed, watch, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { centralStatus, eventTypeDetails } from '@/config/config';
    import _ from 'lodash';
    import { BuildingCentralsEventsCount } from '@/types';
    import { useAlert } from '@/composables/useAlert';
    import { useError } from '@/composables/useError';
    import { useConfig } from '@/composables/useConfig';
    import { useSocket } from '@/composables/useSocket';

    const $route = useRoute();
    const $router = useRouter();
    const $alert = useAlert();
    const $error = useError();
    const $config = useConfig();
    const $socket = useSocket();

    const isLoading = ref(false);
    const search = ref('');
    const searchTimeoutId = ref<NodeJS.Timeout>();
    const building = ref<BuildingCentralsEventsCount>();

    const typeFilters = computed(() => {
        return $route.query.type ? _.castArray($route.query.type) : [];
    });

    const hasFilters = computed(() => {
        return typeFilters.value.length > 0 || search.value;
    });

    function getEventTypes(central: BuildingCentralsEventsCount['centrals'][0], deviceType: 'zones' | 'devices' = 'zones') {
        const eventTypes = [];
        for (const type in central[deviceType].events) {
            eventTypes.push({
                type,
                // @ts-ignore
                count: central[deviceType].events[type],
                ...eventTypeDetails[type as keyof typeof eventTypeDetails],
            });
        }
        return eventTypes;
    }

    function setFilters(typesSelected: (string | null)[]) {
        const query = {
            ..._.cloneDeep($route.query),
            type: typesSelected,
        };
        $router.replace({ query });
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.replace({ query });
    }

    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str;
            setRouterQuery();
        }, 250);
    }

    const centralsFiltered = computed(() => {
        if (!building.value) return [];
        // filter the centrals which devices.events contains the selected types > 0 of typeFilters
        const firstFilter = building.value.centrals.filter((central) => {
            const zoneEvents = _.get(central, 'zones.events', {});
            const deviceEvents = _.get(central, 'devices.events', {});
            return (
                typeFilters.value.length === 0 ||
                typeFilters.value.some((type) => {
                    return (zoneEvents[type as keyof typeof zoneEvents] ?? 0) > 0 || (deviceEvents[type as keyof typeof deviceEvents] ?? 0) > 0;
                })
            );
        });

        // filter the centrals which name contains the search string
        return firstFilter.filter((central) => {
            return central.name.toLowerCase().includes(search.value.toLowerCase());
        });
    });

    watch(
        () => $route.params.id,
        () => {
            init();
        },
    );

    async function init() {
        isLoading.value = true;
        try {
            $socket.emit('building:dashboard', $route.params.id);
            const { data } = await getBuildingCentralsEvents($route.params.id as string, {
                sort: ['name:asc'],
            });
            building.value = data.data;
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: $error.getErrorMessage(error, 'views.events.errors'),
            });
        } finally {
            isLoading.value = false;
        }
    }

    function getStatusDetails(status: any) {
        return $config.getStatus.value(status as keyof typeof centralStatus);
    }

    $socket.on('central:status', (data: { centralId: number; status: string }) => {
        if (!building.value) return;
        const central = building.value.centrals.find((c) => c.id == data.centralId);
        if (central) central.status = data.status;
    });

    init();
</script>

<style scoped></style>
