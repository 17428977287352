<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">{{ $t('views.users.title') }}</h1>
                <v-btn
                    :to="{ name: 'User', params: { id: 'new' } }"
                    color="primary"
                    >{{ $t('views.users.add_btn') }}</v-btn
                >
            </v-col>
        </v-row>
        <!-- Barra de pesquisa -->
        <v-row
            id="toolbarUsers"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-bg-white tw-mb-1">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    :model-value="search"
                    @update:model-value="onSearch"
                    clearable
                    :placeholder="$t('shared.search')"></v-text-field>
                <v-btn
                    size="large"
                    @click="$query.toggleView"
                    density="comfortable"
                    class="text-gray rounded-lg tw-mr-2 tw-border tw-border-gray"
                    :icon="$query.isGrid.value ? 'mdi-format-list-bulleted' : 'mdi-view-grid'"></v-btn>
            </v-col>
        </v-row>
        <!-- Lista de utilizadores -->
        <div v-show="$query.isGrid.value">
            <v-row
                align="start"
                justify="start"
                class="fill-width h-auto justify-start align-content-start">
                <v-col
                    v-for="(user, index) in users"
                    :key="user.id"
                    cols="12"
                    md="6"
                    xl="4"
                    xxl="3">
                    <CardUser
                        @click="$router.push({ name: 'User', params: { id: user.id } })"
                        :user="user" />
                    <span
                        v-if="index + 1 == users.length - Math.round(pageSize / 2)"
                        v-intersect="onIntersect"></span>
                </v-col>
                <v-col
                    v-if="isLoading || (page != pageCount && pageCount != 0)"
                    cols="12"
                    class="tw-flex tw-justify-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"></v-progress-circular>
                </v-col>
            </v-row>
        </div>
        <div v-show="!$query.isGrid.value">
            <v-row>
                <v-col cols="12">
                    <v-data-table-virtual
                        id="tableUsers"
                        hover
                        color="primary"
                        density="comfortable"
                        item-value="id"
                        :show-select="$auth.can('remove_users')"
                        v-model:items-per-page="pageSize"
                        @click:row="onRowClick"
                        v-model="selectedUsers"
                        :headers="headers"
                        :items="users"
                        fixed-header
                        :loading="isLoading"
                        @update:options="onOptionsUpdated">
                        <template #item.name="{ item }">
                            <div class="tw-flex tw-items-center tw-justify-items-start tw-gap-4">
                                <ioImg
                                    :media="item.profilePicture"
                                    aspect-ratio="1"
                                    max-width="40"
                                    cover
                                    class="tw-rounded-md tw-border tw-border-gray-300"
                                    format="thumbnail"
                                    contentType="User" />
                                <p>{{ item.name }}</p>
                            </div>
                        </template>
                        <template #item.email="{ item }">
                            <p>{{ item.email }}</p>
                        </template>
                        <template #item.actions="{ item, index }">
                            <v-btn
                                :to="{
                                    name: 'User',
                                    params: { id: item.id },
                                }"
                                icon="mdi-chevron-right"
                                color="primary"
                                variant="text"
                                size="large">
                            </v-btn>
                            <span
                                v-if="index + 1 == users.length - Math.round(pageSize / 2)"
                                v-intersect="onIntersect"></span>
                        </template>
                        <template #header.actions>
                            <v-btn
                                density="comfortable"
                                @click="isConfirmOpen = true"
                                v-show="selectedUsers.length"
                                class="tw-mr-auto"
                                color="red"
                                >{{ $t('views.users.deleteSelected') }}</v-btn
                            >
                        </template>
                        <template #body.append>
                            <tr v-if="isLoading || (page != pageCount && pageCount != 0)">
                                <td
                                    :colspan="headers && headers.length ? headers.length + 1 : 1"
                                    class="tw-text-center">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary"></v-progress-circular>
                                </td>
                            </tr>
                        </template>
                    </v-data-table-virtual>
                </v-col>
            </v-row>
        </div>
    </v-container>
    <ConfirmDelete
        :title="$t('views.users.deleteTitle')"
        :text="$t('views.users.deleteText')"
        v-model="isConfirmOpen"
        @action="onDelete" />
</template>

<script setup lang="ts">
    import _ from 'lodash';
    import { useRoute, useRouter } from 'vue-router';
    import { computed, ref, watch } from 'vue';
    import CardUser from './CardUser.vue';
    import { useI18n } from 'vue-i18n';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import { getUsers, deleteUsers } from '@/api/users';
    import { pageSizes } from '@/config/config';
    import { useAuthStore } from '@/store/auth';
    import { User } from '@/contentTypes';
    import { useQuery } from '@/composables/useQuery';
    import ioImg from '@/components/ioImg.vue';
    import { useAuth } from '@/composables/useAuth';
    import { VDataTableOptions } from '@/types';
    import ConfirmDelete from '@/components/ConfirmDelete.vue';
    import useError from '@/composables/useError';
    import { useAlert } from '@/composables/useAlert';
    import { useDisplay } from '@/composables/useDisplay';

    const { toolbarHeight: toolbarUsersHeight } = useDisplay('toolbarUsers');

    const $route = useRoute();
    const $router = useRouter();
    const $query = useQuery();
    const $auth = useAuth();
    const $alert = useAlert();
    const $error = useError();
    const authStore = useAuthStore();
    const search = ref(_.get($route, 'query.search', '') as string);
    const { t } = useI18n();
    const isLoading = ref(false);
    const page = ref(1);
    const pageSize = ref(pageSizes[2]);
    const pageCount = ref(0);
    const searchTimeoutId = ref<NodeJS.Timeout>();
    const users = ref<User[]>([]);
    const firstUpdate = ref(true);
    const selectedUsers = ref<number[]>([]);
    const isConfirmOpen = ref(false);

    const sort = ref<VDataTableOptions['sortBy']>([
        {
            key: 'name',
            order: 'asc',
        },
    ]);

    const headers = computed(
        () =>
            [
                {
                    title: t('views.users.table.user'),
                    key: 'name',
                },
                {
                    title: t('views.users.table.email'),
                    key: 'email',
                },
                {
                    title: '',
                    key: 'actions',
                    align: 'end',
                    sortable: false,
                },
            ] as VDataTableServer['headers'],
    );

    const query = computed(() => {
        return {
            populate: ['profilePicture'],
            filters: {
                id: {
                    $ne: authStore.getUser.id,
                },
                company: {
                    users: {
                        id: {
                            $in: authStore.getUser.id,
                        },
                    },
                },
                $or: [
                    {
                        name: {
                            $containsi: search.value,
                        },
                    },
                    {
                        email: {
                            $containsi: search.value,
                        },
                    },
                    {
                        username: {
                            $containsi: search.value,
                        },
                    },
                ],
            },
            pagination: {
                page: page.value,
                pageSize: pageSize.value,
            },
        };
    });

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            page: page.value,
            pageSize: pageSize.value,
            sort: sort.value.map((s) => `${s.key}:${s.order}`),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.replace({ query });
    }

    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function onOptionsUpdated(options: VDataTableOptions) {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }

        sort.value = options.sortBy;

        setRouterQuery();
    }

    function onRowClick(e: Event, o: any) {
        $router.push({
            name: 'User',
            params: { id: o.item.id },
        });
    }

    function onIntersect(isIntersecting: boolean) {
        if (!isIntersecting) return;
        if (page.value < pageCount.value) {
            page.value++;
        }
    }

    async function init(showLoading = true, reset = false) {
        isLoading.value = showLoading;
        try {
            const { data } = await getUsers(query.value);
            if (reset) users.value = data.data;
            else users.value.push(...data.data);
            pageCount.value = data.meta.pagination.pageCount;
        } catch (error) {
            console.error(error);
        } finally {
            isLoading.value = false;
        }
    }

    async function onDelete(action: string) {
        if (action == 'no') {
            return;
        }

        isLoading.value = true;

        try {
            await deleteUsers(selectedUsers.value);
            $alert.showAlert({
                text: t('views.users.deleteSuccess'),
                type: 'success',
            });
        } catch (error) {
            $alert.showAlert({
                text: $error.getErrorMessage(error, 'views.users.error'),
                type: 'error',
            });
            console.error(error);
        } finally {
            isLoading.value = false;
            isConfirmOpen.value = false;
            init(true, true);
        }
    }

    watch(
        query,
        (newQuery, oldQuery) => {
            if (!_.isEqual(newQuery, oldQuery)) {
                let showLoading = false;
                // if there is something different in the query other than the page, reset the page to 1
                if (!_.isEqual(_.omit(newQuery, 'pagination.page'), _.omit(oldQuery, 'pagination.page'))) {
                    showLoading = true;
                    page.value = 1;
                    pageCount.value = 0;
                    users.value = [];
                }
                init(showLoading);
            }
        },
        {
            deep: true,
            immediate: true,
        },
    );
</script>

<style lang="scss">
    @screen sm {
        #tableUsers .v-table__wrapper {
            overflow: unset !important;
        }
        #tableUsers .v-table__wrapper thead {
            top: calc(var(--v-layout-top) + v-bind(toolbarUsersHeight));
            z-index: 5;
        }
    }
</style>
