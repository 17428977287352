export const pageSizes = [12, 24, 48];

export const eventTypeDetails = {
    alarm: {
        twBgColor: 'tw-bg-red',
        bgColor: 'red',
        icon: 'mdi-fire',
        color: 'white',
        twColor: 'tw-text-white',
        text: 'eventTypes.alarm',
    },
    failure: {
        twBgColor: 'tw-bg-yellow',
        bgColor: 'yellow',
        icon: 'mdi-alert',
        color: 'white',
        twColor: 'tw-text-white',
        text: 'eventTypes.failure',
    },
    information: {
        twBgColor: 'tw-bg-blue',
        bgColor: 'blue',
        icon: 'mdi-information-variant',
        color: 'white',
        twColor: 'tw-text-white',
        text: 'eventTypes.information',
    },
    system: {
        twBgColor: 'tw-bg-brown',
        bgColor: 'brown',
        icon: 'mdi-cog',
        color: 'white',
        twColor: 'tw-text-white',
        text: 'eventTypes.system',
    },
};

export const eventTypeUnknown = {
    twBgColor: 'tw-bg-gray',
    bgColor: 'gray',
    icon: 'mdi-help',
    color: 'white',
    twColor: 'tw-text-white',
    text: 'eventTypes.unknown',
};

export const loopStatusDetails = {
    online: {
        twBgColor: 'tw-bg-green-200',
        bgColor: 'green-200',
        icon: 'mdi-play',
        color: 'green',
        twColor: 'tw-text-green',
        text: 'loopStatus.online',
    },
    offline: {
        twBgColor: 'tw-bg-red-200',
        bgColor: 'red-200',
        icon: 'mdi-stop',
        color: 'red',
        twColor: 'tw-text-red',
        text: 'loopStatus.offline',
    },
    unknown: {
        twBgColor: 'tw-bg-gray-200',
        bgColor: 'gray-200',
        icon: 'mdi-help',
        color: 'gray',
        twColor: 'tw-text-dark',
        text: 'loopStatus.unknown',
    },
    'comm failure': {
        twBgColor: 'tw-bg-yellow-200',
        bgColor: 'yellow-200',
        icon: 'mdi-alert',
        color: 'yellow',
        twColor: 'tw-text-yellow',
        text: 'loopStatus.commFailure',
    },
    disabled: {
        twBgColor: 'tw-bg-gray-200',
        bgColor: 'gray-200',
        icon: 'mdi-cancel',
        color: 'gray',
        twColor: 'tw-text-dark',
        text: 'loopStatus.disabled',
    },
};

export const zoneStatusDetails = {
    normal: {
        status: 'Normal',
        class: 'tw-text-green',
    },
    alarm: {
        class: 'tw-text-red',
    },
    failure: {
        class: 'tw-text-yellow',
    },
    'pre-alarm': {
        class: 'tw-text-yellow',
    },
    test: {
        class: 'tw-text-blue',
    },
};

export const zoneEnabledDetails = {
    enabled: {
        status: 'Ativo',
        class: 'tw-text-green',
    },
    disabled: {
        status: 'Inativo',
        class: 'tw-text-red',
    },
};

type yesNoOption = { value: boolean; text: string };
export const yesNoOptions: yesNoOption[] = [
    {
        value: true,
        text: 'shared.yes',
    },
    {
        value: false,
        text: 'shared.no',
    },
];

export const deviceTypes = [
    {
        value: 'none',
        text: 'deviceTypes.none',
        img: 'none.svg',
    },
    {
        value: 'beam',
        text: 'deviceTypes.beam',
        img: 'beam.svg',
    },
    {
        value: 'callpoint',
        text: 'deviceTypes.callpoint',
        img: 'callpoint.svg',
    },
    {
        value: 'central',
        text: 'deviceTypes.central',
        img: 'NURIA.svg',
    },
    {
        value: 'detector',
        text: 'deviceTypes.detector',
        img: 'detector.svg',
    },
    {
        value: 'input',
        text: 'deviceTypes.input',
        img: 'input.svg',
    },
    {
        value: 'output',
        text: 'deviceTypes.output',
        img: 'output.svg',
    },
    {
        value: 'input/output',
        text: 'deviceTypes.input_output',
        img: 'inout.svg',
    },
    {
        value: 'sounder',
        text: 'deviceTypes.sounder',
        img: 'sounder.svg',
    },
];

export const languages = [
    {
        locale: 'pt',
        text: 'Português',
        img: 'pt.svg',
    },
    {
        locale: 'en',
        text: 'English',
        img: 'en.svg',
    },
];

export const centralStatus = {
    offline: {
        twBgColor: 'tw-bg-red',
        bgColor: 'red',
        color: 'white',
        twColor: 'tw-text-white',
        text: 'centralStatus.offline',
    },
    OK: {
        twBgColor: 'tw-bg-green',
        bgColor: 'green',
        color: 'white',
        twColor: 'tw-text-white',
        text: 'centralStatus.OK',
    },
};

export const searchResultsColors = {
    loops: 'tw-bg-orange',
    zones: 'tw-bg-brown',
    buildings: 'tw-bg-blue',
    devicesInstalled: 'tw-bg-gray-100',
    floors: 'tw-bg-primary',
    centrals: 'tw-bg-primary',
    users: 'tw-bg-primary',
};

export const searchResultsIcons = {
    loops: 'mdi-chip',
    zones: 'mdi-vector-square',
    buildings: 'mdi-home-city',
    devicesInstalled: 'mdi-device',
    floors: 'mdi-floor-plan',
    centrals: 'mdi-cog',
    users: 'mdi-account',
};

export const entityTypes = {
    centrals: {
        value: 'centrals',
        text: 'searchResults.centrals',
        img: new URL('../assets/images/devices/NURIA.svg', import.meta.url).href,
    },
    buildings: {
        value: 'buildings',
        text: 'searchResults.buildings',
        icon: 'mdi-home-city',
    },
    devices: {
        value: 'devices',
        text: 'searchResults.devices',
        img: new URL('../assets/images/devices/callpoint.svg', import.meta.url).href,
    },
};
