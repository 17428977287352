<template>
    <h1 class="auth-title">
        {{ $t('auth.createAccount') }}
    </h1>
    <v-form
        ref="form"
        @submit.prevent="onSubmit"
        class="auth-form">
        <v-text-field
            v-model.trim="companyName"
            class="auth-input"
            autocomplete="organization"
            :rules="[rules.required]"
            type="text"
            name="organization"
            :placeholder="$t('fields.companyName')"></v-text-field>
        <v-text-field
            v-model.trim="name"
            class="auth-input"
            autocomplete="given-name family-name"
            :rules="[rules.required]"
            type="text"
            name="name"
            :placeholder="$t('fields.name')"></v-text-field>
        <v-text-field
            v-model.trim="email"
            class="auth-input"
            autocomplete="email"
            :rules="[rules.required, rules.email]"
            type="email"
            name="username"
            :placeholder="$t('fields.email')"></v-text-field>
        <v-text-field
            v-model="password"
            class="auth-input"
            name="new-password"
            autocomplete="new-password"
            :rules="[rules.required]"
            :type="isPasswordVisible ? 'text' : 'password'"
            :placeholder="$t('fields.password')"
            :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-inner="isPasswordVisible = !isPasswordVisible"></v-text-field>

        <div v-if="alertMessage">
            <v-alert
                :type="alertType"
                variant="tonal"
                density="compact"
                >{{ alertMessage }}</v-alert
            >
        </div>

        <v-btn
            :loading="isLoading"
            type="submit"
            color="primary"
            class="auth-btn"
            >{{ $t('auth.register') }}</v-btn
        >
        <router-link
            class="tw-mt-10 tw-text-center tw-underline"
            :to="{ name: 'Login' }">
            {{ $t('auth.alreadyHaveAccount') }}
        </router-link>
    </v-form>
</template>

<script setup lang="ts">
    import axios from '@axios';
    import useRules from '@/composables/rules';
    import { ref } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import _ from 'lodash';
    import slugify from 'slugify';
    import { useI18n } from 'vue-i18n';
    import { register } from '@/api/users';

    const authStore = useAuthStore();
    const $router = useRouter();
    const {t, locale} = useI18n();

    const rules = useRules();
    const form = ref<VForm>();
    const isLoading = ref(false);
    const isPasswordVisible = ref(false);

    const email = ref('');
    const password = ref('');
    const name = ref('');
    const companyName = ref('');

    const alertMessage = ref('');
    const alertType = ref<'success' | 'error'>('success');

    async function onSubmit() {
        if (!form.value) return;

        alertMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            const { data } = await register({
                email: email.value,
                password: password.value,
                companyName: companyName.value,
                name: name.value,
                username: email.value,
                // @ts-ignore
                locale: locale.value,
            });

            alertType.value = 'success';
            alertMessage.value = t('auth.registerSuccess');

            form.value.reset();
        } catch (error) {
            alertType.value = 'error';
            let messageKey = slugify(_.get(error, 'response.data.error.message', 'unknown_error'), {
                replacement: '_',
                trim: true,
                lower: true,
            });
            console.log('messageKey', `'auth.errors.${messageKey}'`);

            alertMessage.value = t(`auth.errors.${messageKey}`);
        } finally {
            isLoading.value = false;
        }
    }
</script>

<style></style>
