export const theme = {
    colors: {
        transparent: '#FFFFFF00',
        current: 'currentColor',
        white: '#FFFFFF',
        black: '#000000',
        dark: {
            200: '#565656',
            DEFAULT: '#333333',
        },
        primary: {
            DEFAULT: '#D92027',
        },
        red: {
            200: '#FFCED8',
            DEFAULT: '#b13232',
        },
        blue: {
            200: '#CCECFF',
            DEFAULT: '#1096e3',
        },
        brown: {
            200: '#ddd6d1',
            DEFAULT: '#907c6c',
        },
        orange: {
            200: '#FFE8CC',
            DEFAULT: '#FFB400',
        },
        yellow: {
            200: '#f6edbf',
            DEFAULT: '#feb92c',
        },
        green: {
            100: '#f6fcf7',
            200: '#c6edc8',
            DEFAULT: '#1AB724',
        },
        purple: {
            200: '#E6D3F5',
            300: '#909CD1',
            DEFAULT: '#9B51E0',
        },
        gray: {
            50: '#F9F9F9',
            100: '#F2F2F2',
            200: '#EAEAEA',
            300: '#D8D8D8',
            400: '#BBBBBB',
            DEFAULT: '#B4B4B4',
            600: '#8B8B8B',
            700: '#707070',
        },
    },
    fontFamily: {
        sans: ['Montserrat', 'sans-serif'],
    },
} as any;

export default theme;

export function flatColors() {
    const flatColors: any = {};
    for (const color in theme.colors) {
        if (['transparent', 'current'].includes(color)) continue;
        if (typeof theme.colors[color] === 'string') {
            flatColors[color] = theme.colors[color];
        } else {
            for (const shade in theme.colors[color]) {
                if (shade != 'DEFAULT') flatColors[`${color}-${shade}`] = theme.colors[color][shade];
                else flatColors[color] = theme.colors[color][shade];
            }
        }
    }
    return flatColors;
}
