<template>
    <v-card
        class="mx-auto pa-5 h-100"
        variant="outlined"
        color="gray">
        <div class="tw-flex tw-h-full tw-w-full">
            <div class="tw-flex tw-grow tw-flex-col tw-text-sm tw-font-medium">
                <p class="tw-mb-1">{{ $t(type.text) }}</p>
                <p class="tw-mt-auto tw-text-dark">{{ props.number }}</p>
            </div>
            <div
                :class="`${type.twBgColor}`"
                class="tw-flex tw-aspect-square tw-h-full tw-max-h-[50px] tw-w-full tw-max-w-[50px] tw-items-center tw-justify-center tw-rounded-md">
                <v-icon
                    :color="type.color"
                    :icon="type.icon"
                    size="large"></v-icon>
            </div>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { eventTypeDetails } from '@/config/config';
    import { PropType } from 'vue';
    import { computed } from 'vue';

    const props = defineProps({
        type: {
            type: String as PropType<keyof typeof eventTypeDetails>,
            required: true,
        },
        number: {
            type: String,
            default: 0,
        },
    });

    const type = computed(() => {
        return eventTypeDetails[props.type];
    });
</script>

<style scoped></style>
