import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/user-notification-tokens';
export const API_REF = ContentTypesUID.UserNotificationToken;

export async function addNotificationToken(token: string): AxiosPromise<APIResponseSingle<'UserNotificationToken'>> {
    return axios.post(`${API_URL}`, { token });
}
