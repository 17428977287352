<template>
    <v-navigation-drawer
        disable-route-watcher
        width="400"
        location="right"
        class="tw-bg-gray-100 tw-p-10 tw-pt-12"
        floating
        mobile-breakpoint="xl"
        v-model="modelValue">
        <div class="tw-flex tw-flex-col tw-gap-4">
            <div class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-xl tw-font-semibold">{{ $t('shared.filters') }}</h1>
                <v-btn
                    @click="modelValue = false"
                    color="gray-300"
                    density="comfortable"
                    class="rounded-lg tw-text-dark tw-shadow-none"
                    icon="mdi-close"></v-btn>
            </div>
            <div>
                <v-label class="tw-mb-1 tw-font-medium tw-text-dark tw-opacity-100">{{ $t('views.filters.building') }}</v-label>
                <ioAutocomplete
                    clearable
                    @change="onBuildingsChange"
                    :fetch-items="fetchBuildings"
                    v-model="selectedBuilding" />
            </div>
            <div>
                <v-label class="tw-mb-1 tw-font-medium tw-text-dark tw-opacity-100">{{ $t('views.filters.central') }}</v-label>
                <ioAutocomplete
                    :query="selectedBuilding"
                    :disabled="!selectedBuilding"
                    clearable
                    @change="onCentralsChange"
                    :fetch-items="fetchCentrals"
                    v-model="selectedCentral" />
            </div>
            <div>
                <v-label class="tw-mb-1 tw-font-medium tw-text-dark tw-opacity-100">{{ $t('views.filters.zone') }}</v-label>
                <ioAutocomplete
                    :query="selectedCentral"
                    :disabled="!selectedCentral"
                    clearable
                    @change="onZonesChange"
                    :fetch-items="fetchZones"
                    v-model="selectedZone" />
            </div>
            <div>
                <v-label class="tw-mb-1 tw-font-medium tw-text-dark tw-opacity-100">{{ $t('views.filters.device') }}</v-label>
                <ioAutocomplete
                    :query="selectedZone"
                    :disabled="!selectedZone"
                    clearable
                    @change="onDevicesChange"
                    :fetch-items="fetchDevices"
                    v-model="selectedDevice" />
            </div>
            <div>
                <v-label class="tw-mb-1 tw-font-medium tw-text-dark tw-opacity-100">{{ $t('views.filters.ocurredAt') }}</v-label>
                <div class="tw-flex tw-flex-col tw-gap-2">
                    <VueDatePicker
                        range
                        v-model="occurredAt"
                        :max-date="today"
                        @update:model-value="onOccurredAtChange" />
                </div>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import ioAutocomplete from '@/components/ioAutocomplete.vue';
    import VueDatePicker from '@vuepic/vue-datepicker';
    import { ref } from 'vue';
    import { getBuildings } from '@/api/buildings';
    import { getZones } from '@/api/zones';
    import { getDevicesInstalled } from '@/api/devicesInstalled';
    import { useAuthStore } from '@/store/auth';
    import { get, has, isArray, set } from 'lodash';
    import { useRoute, useRouter } from 'vue-router';
    import { flatten, unflatten } from 'flat';
    import { getCentrals } from '@/api/centrals';
    import { nextTick } from 'vue';

    const modelValue = defineModel({
        default: false,
    });

    const authStore = useAuthStore();
    const $router = useRouter();
    const $route = useRoute();

    const selectedUser = ref<number>();
    const selectedBuilding = ref<number>();
    const selectedZone = ref<number>();
    const selectedDevice = ref<number>();
    const selectedCentral = ref<number>();

    const occurredAt = ref<Date[]>([]);
    // today at 23:59:59
    const today = new Date();
    today.setHours(23, 59, 59, 999);

    async function fetchBuildings({ page, search, ids }: { page?: number; search?: string; ids?: [number] }) {
        const query = {};

        set(query, '$or[0].floors.centrals.users.id', authStore.getUser.id);
        set(query, '$or[1].users.id', authStore.getUser.id);

        if (search) {
            set(query, '$or[0].name.$containsi', search);
            set(query, '$or[1].name.$containsi', search);
        }

        if (isArray(ids) && ids.length > 0) {
            set(query, '$or[2].id.$in', ids);
        }

        return getBuildings({
            fields: ['id', 'name'],
            filters: query,
            pagination: {
                page,
            },
        });
    }

    async function fetchZones({ page, search, ids }: { page?: number; search?: string; ids?: [number] }) {
        const query: any = {
            $or: [
                {
                    central: {
                        floor: {
                            building: {
                                users: {
                                    id: authStore.getUser.id,
                                },
                            },
                        },
                    },
                },
                {
                    central: {
                        users: {
                            id: authStore.getUser.id,
                        },
                    },
                },
            ],
        };

        if (search) {
            for (let or of query.$or) {
                set(or, 'name.$containsi', search);
            }
        }

        if (selectedCentral.value) {
            for (let or of query.$or) {
                set(or, 'central.id', selectedCentral.value);
            }
        }

        if (isArray(ids) && ids.length > 0) {
            query.$or.push({
                id: {
                    $in: ids,
                },
            });
        }

        return getZones({
            fields: ['id', 'name'],
            filters: query,
            pagination: {
                page,
            },
        });
    }

    async function fetchDevices({ page, search, ids }: { page?: number; search?: string; ids?: [number] }) {
        const query: any = {
            $or: [
                {
                    loop: {
                        central: {
                            floor: {
                                building: {
                                    users: {
                                        id: authStore.getUser.id,
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    loop: {
                        central: {
                            users: {
                                id: authStore.getUser.id,
                            },
                        },
                    },
                },
            ],
        };

        if (search) {
            for (let or of query.$or) {
                set(or, 'name.$containsi', search);
            }
        }

        if (selectedZone.value) {
            for (let or of query.$or) {
                set(or, 'zone.id', selectedZone.value);
            }
        }

        if (isArray(ids) && ids.length > 0) {
            query.$or.push({
                id: {
                    $in: ids,
                },
            });
        }

        return getDevicesInstalled({
            fields: ['id', 'name'],
            filters: query,
            pagination: {
                page,
            },
        });
    }

    async function fetchCentrals({ page, search, ids }: { page?: number; search?: string; ids?: [number] }) {
        const query = {
            $or: [
                {
                    floor: {
                        building: {
                            users: {
                                id: authStore.getUser.id,
                            },
                        },
                    },
                },
                {
                    users: {
                        id: authStore.getUser.id,
                    },
                },
            ],
        } as any;

        if (search) {
            for (let or of query.$or) {
                set(or, 'name.$containsi', search);
            }
        }

        if (selectedBuilding.value) {
            for (let or of query.$or) {
                set(or, 'floor.building.id', selectedBuilding.value);
            }
        }

        if (isArray(ids) && ids.length > 0) {
            query.$or.push({
                id: {
                    $in: ids,
                },
            });
        }

        return getCentrals({
            fields: ['id', 'name'],
            filters: query,
            pagination: {
                page,
            },
        });
    }

    function onBuildingsChange(value: number[] | number | null) {
        const query = unflatten($route.query);

        set(query as object, 'filters.building', value ? value : undefined);

        const flatQuery: object = flatten(query, {
            safe: true,
        });

        $router.replace({
            query: {
                ...flatQuery,
            },
        });
    }

    function onZonesChange(value: number[] | number | null) {
        const query = unflatten($route.query);

        set(query as object, 'filters.zone', value ?? undefined);
        set(query as object, 'filters.building', selectedBuilding.value ?? undefined);
        set(query as object, 'filters.central', selectedCentral.value ?? undefined);

        const flatQuery: object = flatten(query, {
            safe: true,
        });

        $router.replace({
            query: {
                ...flatQuery,
            },
        });
    }

    function onDevicesChange(value: number[] | number | null) {
        const query = unflatten($route.query);

        set(query as object, 'filters.device', value ?? undefined);

        const flatQuery: object = flatten(query, {
            safe: true,
        });

        $router.replace({
            query: {
                ...flatQuery,
            },
        });
    }

    async function onCentralsChange(value: number[] | number | null) {
        await nextTick();
        const query = unflatten($route.query);

        set(query as object, 'filters.building', selectedBuilding.value ?? undefined);
        set(query as object, 'filters.central', value ?? undefined);

        const flatQuery: object = flatten(query, {
            safe: true,
        });

        $router.replace({
            query: {
                ...flatQuery,
            },
        });
    }

    async function onOccurredAtChange(value: Date[]) {
        const query = unflatten($route.query);

        const startAt = value && isArray(value) && value.length > 0 ? value[0].toISOString() : undefined;
        const endAt = value && isArray(value) && value.length > 0 ? value[1].toISOString() : undefined;

        set(query as object, 'filters.occurredAtStart', startAt);
        set(query as object, 'filters.occurredAtEnd', endAt);

        const flatQuery: object = flatten(query, {
            safe: true,
        });

        $router.replace({
            query: {
                ...flatQuery,
            },
        });
    }

    function init() {
        const query: object = unflatten($route.query);

        if (has(query, 'filters.central')) {
            const central = get(query, 'filters.central', undefined);
            selectedCentral.value = central ? parseInt(central) : undefined;
        }

        if (has(query, 'filters.building')) {
            const building = get(query, 'filters.building', undefined);
            selectedBuilding.value = building ? parseInt(building) : undefined;
        }

        if (has(query, 'filters.zone')) {
            const zone = get(query, 'filters.zone', undefined);
            selectedZone.value = zone ? parseInt(zone) : undefined;
        }

        if (has(query, 'filters.device')) {
            const device = get(query, 'filters.device', undefined);
            selectedDevice.value = device ? parseInt(device) : undefined;
        }

        if (has(query, 'filters.occurredAtStart') && has(query, 'filters.occurredAtEnd')) {
            const occurredAtStart = get(query, 'filters.occurredAtStart', undefined);
            const occurredAtEnd = get(query, 'filters.occurredAtEnd', undefined);
            if (occurredAtStart && occurredAtEnd) occurredAt.value = [new Date(occurredAtStart), new Date(occurredAtEnd)];
        }
    }

    init();
</script>

<style scoped></style>
