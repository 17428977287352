<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">{{ $t('views.events.title') }}</h1>
            </v-col>
        </v-row>
        <!-- Barra de pesquisa -->
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    :model-value="search"
                    @update:model-value="onSearch"
                    clearable
                    :placeholder="$t('shared.search')"></v-text-field>
                <v-btn
                    size="large"
                    @click="filtersOpen = !filtersOpen"
                    density="comfortable"
                    class="text-gray rounded-lg tw-border tw-border-gray"
                    icon="mdi-tune"></v-btn>
                <v-btn
                    size="large"
                    @click="$query.toggleView"
                    density="comfortable"
                    class="text-gray rounded-lg tw-mr-2 tw-border tw-border-gray"
                    :icon="$query.isGrid.value ? 'mdi-format-list-bulleted' : 'mdi-view-grid'"></v-btn>
                <v-btn-toggle
                    multiple
                    :model-value="typeFilters"
                    @update:model-value="setFilters"
                    class="tw-ml-auto">
                    <v-btn
                        v-for="(details, type) in eventTypeDetails"
                        :key="type"
                        :color="details.bgColor"
                        :value="type"
                        :class="`text-${typeFilters.includes(type) ? details.color : details.bgColor} tw-border tw-border-gray`"
                        :icon="details.icon"></v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <!-- Cards Eventos -->
        <div v-show="$query.isGrid.value">
            <v-row id="events-grid">
                <v-col
                    v-for="(event, index) in events"
                    :key="event.id"
                    cols="12"
                    :md="filtersOpen ? 12 : 6"
                    :lg="filtersOpen ? 12 : 4"
                    :xl="filtersOpen ? 6 : 4"
                    :xxl="filtersOpen ? 4 : 3">
                    <span
                        v-if="index + 1 == items.length - Math.round(pageSize / 2)"
                        v-intersect="onIntersect"></span>
                    <CardEvent
                        @mouseover="event.isNew = false"
                        @click="onEdit"
                        :event="event" />
                </v-col>
                <v-col
                    v-if="isLoading || (page != pageCount && pageCount != 0)"
                    cols="12"
                    class="tw-flex tw-justify-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"></v-progress-circular>
                </v-col>
                <v-col
                    cols="12"
                    v-if="!events.length && !isLoading">
                    <p
                        v-show="!hasFilters"
                        class="tw-text-center">
                        {{ $t('views.events.noEvents') }}
                    </p>
                    <p
                        v-show="hasFilters"
                        class="tw-text-center">
                        {{ $t('views.events.noEventsWithFilters') }}
                    </p>
                </v-col>
            </v-row>
        </div>
        <v-row v-show="!$query.isGrid.value">
            <v-col
                cols="12"
                class="tw-relative">
                <v-data-table-virtual
                    id="eventsTable"
                    hover
                    color="primary"
                    :loading="isLoading"
                    density="comfortable"
                    :no-data-text="hasFilters ? $t('views.events.noEventsWithFilters') : $t('views.events.noEvents')"
                    :headers="headers"
                    :row-props="rowProps"
                    :items="items"
                    fixed-header
                    @click:row="onRowClick"
                    :sort-by="sort"
                    @update:options="onOptionsUpdated">
                    <template #item.originName="{ item }">
                        {{ useEvent(item).originName }}
                    </template>
                    <template #item.building.name="{ item }">
                        {{ useEvent(item).building?.name }}
                    </template>
                    <template #item.occurredAt="{ item, index }">
                        {{ useDate(item.occurredAt as Date).formattedDate }}
                        <span
                            v-if="index + 1 == items.length - Math.round(pageSize / 2)"
                            v-intersect="onIntersect"></span>
                    </template>
                    <template #item.type="{ item }">
                        <v-icon
                            :color="useEvent(item).typeDetails.color"
                            size="x-large"
                            :class="`tw-bg-${useEvent(item).typeDetails.bgColor} tw-my-1 tw-rounded-md tw-p-5`">
                            {{ useEvent(item).typeDetails.icon }}
                        </v-icon>
                    </template>
                    <template #body.append>
                        <tr v-if="page != pageCount && pageCount != 0">
                            <td
                                :colspan="headers && headers.length ? headers.length : 1"
                                class="tw-text-center">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"></v-progress-circular>
                            </td>
                        </tr>
                    </template>
                </v-data-table-virtual>
            </v-col>
        </v-row>
    </v-container>
    <FilterEvents v-model="filtersOpen" />
    <AcknowledgeEvent
        @success="init"
        v-model="isDialogOpen"
        :events="selectedEvents" />
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import CardEvent from './CardEvent.vue';
    import FilterEvents from './FilterEvents.vue';
    import AcknowledgeEvent from './AcknowledgeEvent.vue';
    import _, { has, set } from 'lodash';
    import { ContentType } from '@/contentTypes';
    import { eventTypeDetails, pageSizes } from '@/config/config';
    import { useRoute, useRouter } from 'vue-router';
    import { getEvents } from '@/api/events';
    import { VDataTableOptions } from '@/types';
    import { useDate } from '@/composables/useDate';
    import { useEvent } from '@/composables/useEvent';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import { watch } from 'vue';
    import { unflatten } from 'flat';
    import { useI18n } from 'vue-i18n';
    import { useQuery } from '@/composables/useQuery';
    import { useSocket } from '../../composables/useSocket';
    import { useDisplay } from '@/composables/useDisplay';
    import { onMounted } from 'vue';
    import { useTable } from '../../composables/useTable';

    const { t } = useI18n();

    let abortController: AbortController | null = null;

    const { toolbarHeight: toolbarEventsHeight } = useDisplay('events-search');

    useTable('eventsTable');
    const $route = useRoute();
    const $socket = useSocket();
    const $router = useRouter();
    const $query = useQuery();
    const isLoading = ref(false);
    const firstUpdate = ref(true);
    const isDialogOpen = ref(false);
    const page = ref($route.query?.page ? Number($route.query.page) : 1);
    const pageSize = ref(48);
    const pageCount = ref(0);
    const events = ref<ContentType<'Event'>[]>([]);
    const filtersOpen = ref(false);
    const search = ref($route.query.search as string);
    const searchTimeoutId = ref<NodeJS.Timeout>();
    const selectedEvents = ref<ContentType<'Event'>[]>([]);

    const typeFilters = computed(() => {
        return $route.query.type ? _.castArray($route.query.type) : [];
    });

    const sort = ref<VDataTableOptions['sortBy']>($query.routeQuerySort.value);

    const items = computed(() => {
        return events.value;
    });

    const hasFilters = computed(() => _.has(query.value, 'customFilters') && Object.keys(query.value.customFilters).length > 0);

    const query = computed(() => {
        const _query = {
            customFilters: {},
            populate: {
                zone: {
                    fields: ['name'],
                },
                central: {
                    fields: ['name'],
                },
                device: {
                    fields: ['name'],
                },
                loop: {
                    fields: ['loopID'],
                },
                building: {
                    fields: ['name'],
                },
                floor: {
                    fields: ['name'],
                },
            },
            fields: ['type', 'origin', 'occurredAt', 'message', 'description'],
            pagination: {
                page: page.value,
                pageSize: pageSize.value,
            },
            sort: sort.value.map((s) => `${s.key}:${s.order}`),
        };

        if (search.value) {
            set(_query, 'customFilters.search', search.value);
        }

        const routeQuery: any = unflatten($route.query);

        if (has(routeQuery, 'filters.device')) {
            set(_query, 'customFilters.devices', routeQuery.filters.device);
        } else if (has(routeQuery, 'filters.zone')) {
            set(_query, 'customFilters.zones', routeQuery.filters.zone);
        } else if (has(routeQuery, 'filters.central')) {
            set(_query, 'customFilters.centrals', routeQuery.filters.central);
        } else if (has(routeQuery, 'filters.building')) {
            set(_query, 'customFilters.buildings', routeQuery.filters.building);
        }

        if (has(routeQuery, 'filters.occurredAtStart')) {
            set(_query, 'customFilters.occurredAtStart', routeQuery.filters.occurredAtStart);
        }

        if (has(routeQuery, 'filters.occurredAtEnd')) {
            set(_query, 'customFilters.occurredAtEnd', routeQuery.filters.occurredAtEnd);
        }

        if (typeFilters.value.length > 0) {
            set(_query, 'customFilters.type', typeFilters.value);
        }

        return _query;
    });

    const headers = computed(
        () =>
            [
                // {
                //     title: 'ID',
                //     key: 'id',
                // },
                {
                    title: t('views.events.fields.date'),
                    key: 'occurredAt',
                },
                {
                    title: t('views.events.fields.type'),
                    key: 'type',
                    align: 'center',
                    sortable: false,
                },
                {
                    title: t('views.events.fields.building'),
                    key: 'building.name',
                },
                {
                    title: t('views.events.fields.name'),
                    key: 'originName',
                    sortable: false,
                },
                {
                    title: t('views.events.fields.description'),
                    key: 'description',
                },
                {
                    title: '',
                    key: 'actions',
                    align: 'end',
                },
            ] as VDataTableServer['headers'],
    );

    function onOptionsUpdated(options: VDataTableOptions) {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }

        page.value = options.page;

        sort.value = options.sortBy;

        setRouterQuery();
    }

    function setFilters(typesSelected: (string | null)[]) {
        const query = {
            ..._.cloneDeep($route.query),
            type: typesSelected,
        };
        $router.replace({ query });
    }

    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str;
            setRouterQuery();
        }, 250);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            page: page.value,
            pageSize: pageSize.value,
            sort: sort.value.map((s) => `${s.key}:${s.order}`),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.replace({ query });
    }

    function onEdit(item: ContentType<'Event'>) {
        selectedEvents.value = [item];
        isDialogOpen.value = true;
    }

    function onRowClick(e: any, row: any) {
        onEdit(row.item);
    }

    function onIntersect(isIntersecting: boolean) {
        if (!isIntersecting) return;

        if (page.value < pageCount.value) {
            page.value++;
        }
    }

    watch(
        query,
        (newQuery, oldQuery) => {
            if (!_.isEqual(newQuery, oldQuery)) {
                let showLoading = false;
                // if there is something different in the query other than the page, reset the page to 1
                if (!_.isEqual(_.omit(newQuery, 'pagination.page'), _.omit(oldQuery, 'pagination.page'))) {
                    showLoading = true;
                    page.value = 1;
                    pageCount.value = 0;
                    events.value = [];
                }

                init(showLoading);
            }
        },
        {
            deep: true,
            flush: 'post',
        },
    );

    async function checkQueryEvent() {
        if ($route.query.event) {
            const event = events.value.find((event) => event.id == parseInt($route.query.event as string));
            if (event) {
                onEdit(event);
            }
            // remove the event from the query
            $router.replace({ query: { ...$route.query, event: undefined } });
        }
    }

    function rowProps({
        item,
    }: {
        item: ContentType<'Event'> & {
            isNew?: boolean;
        };
    }) {
        return {
            attr: item.isNew ? 'isNew' : undefined,
        } as VDataTableServer['$props']['rowProps'];
    }

    async function init(loading = true, fromSocket = false) {
        let wasCanceled = false;
        if (abortController) {
            abortController.abort();
        }
        isLoading.value = loading;

        $socket.emit('events', query.value);
        try {
            abortController = new AbortController();
            const { data } = await getEvents(query.value, {
                signal: abortController.signal,
            });

            if (fromSocket) {
                const newEvents = [];
                for (const event of data.data) {
                    const found = events.value.find((e) => e.id == event.id);
                    if (!found) {
                        event.isNew = true;
                        newEvents.push(event);
                    }
                }
                events.value.unshift(...newEvents);
            } else {
                events.value.push(...data.data);
            }

            pageCount.value = data.meta.pagination.pageCount;

            checkQueryEvent();
        } catch (error) {
            console.error(error);
            if (_.get(error, 'code') == 'ERR_CANCELED') {
                wasCanceled = true;
            }
        } finally {
            if (!wasCanceled) isLoading.value = false;
            abortController = null;
        }
    }

    $socket.on('events', (data) => {
        init(false, true);
    });

    init();
</script>

<style lang="scss">
    .v-data-table__tr[attr='isNew'] {
        background-color: rgb(var(--v-theme-green-200));
    }

    @screen sm {
        #eventsTable .v-table__wrapper {
            overflow: unset !important;
        }
        #eventsTable .v-table__wrapper thead {
            top: calc(var(--v-layout-top) + v-bind(toolbarEventsHeight));
            z-index: 1;
        }
    }
</style>
