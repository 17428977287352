import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import { BuildingEventStatusCount, DataWrapper, BuildingCentralsEventsCount, BuildingFloorDevicesCount } from '@/types';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/buildings';
export const API_REF = ContentTypesUID.Building;

export async function getBuildings(query: APIRequestParams<'Building'> = {}): AxiosPromise<APIResponseMany<'Building'>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`);
}

export async function getBuildingEventCount(query: APIRequestParams<'Building'> = {}): AxiosPromise<DataWrapper<BuildingEventStatusCount[]>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}/count-active-events?${params}`);
}

export async function getBuilding(id: string, query: APIRequestParams<'Building'> = {}): AxiosPromise<APIResponseSingle<'Building'>> {
    return axios.get(`${API_URL}/${id}?${qs.stringify(query)}`);
}

export async function getBuildingCentralsEvents(id: string, query = {}): AxiosPromise<DataWrapper<BuildingCentralsEventsCount>> {
    return axios.get(`${API_URL}/${id}/centrals-events-count?${qs.stringify(query)}`);
}

export async function countDevicesPerCentral(id: string, query = {}): AxiosPromise<DataWrapper<BuildingFloorDevicesCount[]>> {
    return axios.get(`${API_URL}/${id}/centrals-devices-count?${qs.stringify(query)}`);
}

export async function updateBuilding(id: number, data: ContentType<'Building', false>, query: APIRequestParams<'Building'> = {}): AxiosPromise<APIResponseSingle<'Building'>> {
    const params = qs.stringify(query);
    return axios.put(`${API_URL}/${id}?${params}`, { data });
}

export async function createBuilding(data: ContentType<'Building', false>, query: APIRequestParams<'Building'> = {}): AxiosPromise<APIResponseSingle<'Building'>> {
    const params = qs.stringify(query);
    return axios.post(`${API_URL}?${params}`, { data });
}