<template>
    <v-card
        :to="{
            name: 'Events',
            query: {
                type: props.type,
            },
        }"
        class="mx-auto tw-h-full xl:tw-p-5 tw-p-3 tw-w-full"
        :variant="active ? 'flat' : 'outlined'"
        :color="active ? type.bgColor : 'gray'">
        <div class="tw-flex tw-h-full tw-w-full">
            <div class="tw-flex tw-grow tw-flex-col tw-text-sm tw-font-medium">
                <p class="tw-mb-1" :class="active ? type.twColor : 'tw-text-gray'">{{ $t(type.text) }}</p>
                <p class="tw-mt-auto" :class="active ? type.twColor : 'tw-text-dark'">{{ $t('views.events.active') }}: {{ active }}</p>
                <!-- <p class="tw-text-dark">{{ $t('views.events.pending') }}: {{ pending }}</p> -->
            </div>
            <div
                :class="`${type.twBgColor}`"
                class="tw-flex tw-aspect-square tw-h-full tw-max-h-[50px] tw-w-full tw-max-w-[50px] tw-items-center tw-justify-center tw-rounded-md">
                <v-icon
                    :color="type.color"
                    :icon="type.icon"
                    size="large"></v-icon>
            </div>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { eventTypeDetails } from '@/config/config';
    import { PropType } from 'vue';
    import { computed } from 'vue';

    const props = defineProps({
        type: {
            type: String as PropType<keyof typeof eventTypeDetails>,
            required: true,
        },
        active: {
            type: Number,
            default: 0,
        },
        pending: {
            type: Number,
            default: 0,
        },
    });

    const type = computed(() => {
        return eventTypeDetails[props.type];
    });
</script>

<style scoped></style>
