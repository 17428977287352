<template>
    <v-dialog v-model="isOpen">
        <v-card
            class="mx-auto pa-8"
            max-width="900"
            width="100%">
            <div class="tw-flex tw-items-center tw-gap-2">
                <router-link :to="useEvent(props.events[0]).originTo" class="tw-mr-auto tw-text-xl tw-font-semibold">
                    {{ useEvent(props.events[0]).originName }}
                </router-link>
                <v-icon
                    :color="type.color"
                    size="x-large"
                    :class="`tw-bg-${type.bgColor} tw-my-1 tw-rounded-md tw-p-5`">
                    {{ type.icon }}
                </v-icon>
                <v-btn
                    @click="isOpen = false"
                    color="gray-200"
                    density="comfortable"
                    class="text-gray rounded-lg tw-shadow-none"
                    icon="mdi-close"></v-btn>
            </div>
            <div class="tw-mt-4 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-text-sm">
                <p class="tw-flex tw-gap-2 tw-font-medium tw-text-gray-600">
                    <template v-for="(step, i) in useEvent(props.events[0]).steps">
                        <router-link :to="step.to">{{ step.name }}</router-link :to="step.to">
                        <span v-if="i < useEvent(props.events[0]).steps.length - 1">&gt;</span>
                    </template>
                </p>
                <p class="tw-text-gray-400">{{ useDate(props.events[0].occurredAt).formattedDate }}</p>
            </div>
            <div class="tw-mt-10 tw-flex tw-flex-col tw-flex-wrap">
                <p class="tw-font-medium">{{ $t('views.events.fields.description') }}</p>
                <p class="tw-mt-2 tw-text-sm tw-text-gray-600">
                    {{ props.events[0].description }}
                </p>
            </div>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { ContentType } from '@/contentTypes';
    import { useEvent } from '@/composables/useEvent';
    import { useDate } from '@/composables/useDate';

    const isOpen = defineModel<boolean>();
    const props = defineProps<{
        events: ContentType<'Event'>[];
    }>();
    const $emit = defineEmits<{
        (e: 'success'): void;
    }>();

    const type = computed(() => {
        return useEvent(props.events[0]).typeDetails;
    });
</script>

<style scoped></style>
