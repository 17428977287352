import { useAppStore } from '@/store/app';
import { useI18n } from 'vue-i18n';
import { useLocale as useLocaleVuetify } from 'vuetify';

export function useLocale() {
    const appStore = useAppStore();
    const { locale } = useI18n({ useScope: 'global' });
    const { current } = useLocaleVuetify();
    return {
        setLocale(value: string) {
            locale.value = value;
            current.value = value;
            appStore.setLocale(value);
        },
        getLocale() {
            let locale = appStore.getLocale;
            if (!locale) {
                locale = navigator.language;
                if (locale.includes('pt')) {
                    locale = 'pt';
                } else {
                    locale = 'en';
                }
            }
            return locale;
        },
    };
}
