<template>
    <Sidebar />
    <Navbar />
    <v-main>
        <ioAlert />
        <!-- <v-btn
            @click="appStore.setIsSidebarOpen(!isSidebarOpen)"
            class="tw-z-10"
            position="fixed"
            :location="'left top'"
            icon="mdi-menu"
            rounded=""></v-btn> -->
        <router-view />
        <Search />
        <v-btn-group
            v-if="appStore.isPWA"
            class="lg:tw-hidden tw-fixed tw-bottom-4 tw-left-4 tw-z-[1005]">
            <v-btn
                @click="$router.back()"
                :color="$route.meta.canGoBack ? 'primary' : 'gray-300'"
                :readonly="!$route.meta.canGoBack"
                class="tw-grow tw-text-xl tw-px-8"
                size="small"
                icon="mdi-chevron-left">
            </v-btn>
            <v-btn
                @click="$router.forward()"
                :color="$route.meta.canGoForward ? 'primary' : 'gray-300'"
                :readonly="!$route.meta.canGoForward"
                class="tw-grow tw-text-xl tw-px-8"
                size="small"
                icon="mdi-chevron-right">
            </v-btn>
        </v-btn-group>
    </v-main>
</template>

<script lang="ts" setup>
    import Sidebar from './Sidebar.vue';
    import Navbar from './Navbar.vue';
    import { useAppStore } from '../store/app';
    import { computed } from 'vue';
    import ioAlert from '@/components/ioAlert.vue';
    import { useAuth } from '@/composables/useAuth';
    import useNotifications from '@/composables/useNotifications';
    import { addNotificationToken } from '@/api/notificationTokens';
    import Search from './Search.vue';

    const appStore = useAppStore();
    const $auth = useAuth();
    const _notifications = useNotifications();

    const isSidebarOpen = computed(() => appStore.isSidebarOpen);

    $auth.checkAuth();

    async function init() {
        const token = await _notifications.requestPermission();
        if (token) addNotificationToken(token);
    }

    init();
</script>
