<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">{{ $t('views.centrals.enrollment.addCentral') }}</h1>
            </v-col>
        </v-row>
        <v-stepper
            flat
            v-model="currentStep"
            alt-labels
            :items="[$t('views.centrals.enrollment.readCentral'), $t('views.centrals.enrollment.addBuilding'), $t('views.centrals.enrollment.addFloor')]">
            <template v-slot:item.1>
                <v-row class="tw-m-0">
                    <v-col
                        cols="12"
                        class="tw-flex tw-flex-col tw-gap-4">
                        <h2 class="tw-text-lg tw-font-bold">{{ $t('views.centrals.enrollment.readCentral') }}</h2>
                        <div>
                            <p>
                                {{ $t('views.centrals.enrollment.qrcodeInstructions') }}
                            </p>
                            <p>
                                {{ $t('views.centrals.enrollment.qrcodeInstructions2') }}
                            </p>
                        </div>

                        <div class="tw-mt-4 tw-flex tw-justify-center">
                            <v-btn
                                @click="showReader = true"
                                color="primary"
                                >{{ $t('views.centrals.enrollment.readCode') }}</v-btn
                            >
                        </div>
                        <qrcode-stream
                            v-if="showReader"
                            @error="onStreamError"
                            @detect="onStreamDetect"
                            class="tw-fixed tw-aspect-video max-md:tw-inset-0 max-md:tw-z-[5000] md:tw-relative">
                            <v-btn
                                @click="showReader = false"
                                class="tw-absolute tw-bottom-4 tw-left-1/2 -tw-translate-x-1/2"
                                >{{ $t('shared.cancel') }}</v-btn
                            >
                        </qrcode-stream>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.2>
                <v-row class="tw-m-0">
                    <v-col
                        cols="12"
                        class="tw-flex tw-flex-col tw-gap-4">
                        <h2 class="tw-text-lg tw-font-bold">{{ $t('views.centrals.enrollment.addBuilding') }}</h2>
                        <p>{{ $t('views.centrals.enrollment.addBuildingInstructions') }}</p>
                        <!-- if there is no building, add a new one -->
                        <ioAutocomplete
                            clearable
                            select-first
                            :extra-data="buildingsExtraData"
                            @change="onBuildingsChange"
                            :fetch-items="fetchBuildings"
                            v-model="selectedBuilding" />
                    </v-col>
                </v-row>
                <v-row
                    class="tw-m-0"
                    v-if="selectedBuilding == 'new'">
                    <v-col
                        cols="12"
                        sm="6">
                        <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.enrollment.buildingName') }}* </v-label>
                        <v-text-field
                            v-model="newBuilding.name"
                            :rules="[$rules.required]" />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6">
                        <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.enrollment.buildingAddress') }} </v-label>
                        <v-text-field v-model="newBuilding.address" />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6">
                        <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.enrollment.buildingPhone') }} </v-label>
                        <v-text-field v-model="newBuilding.phone" />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6">
                        <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.enrollment.buildingEmail') }} </v-label>
                        <v-text-field v-model="newBuilding.email" />
                    </v-col>
                    <v-col
                        cols="6"
                        sm="4"
                        md="3">
                        <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.buildings.lat') }} </v-label>
                        <v-text-field v-model="newBuilding.lat" />
                    </v-col>
                    <v-col
                        cols="6"
                        sm="4"
                        md="3">
                        <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.buildings.lng') }} </v-label>
                        <v-text-field v-model="newBuilding.lng" />
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.3>
                <v-row class="tw-m-0">
                    <v-col
                        cols="12"
                        class="tw-flex tw-flex-col tw-gap-4">
                        <h2 class="tw-text-lg tw-font-bold">{{ $t('views.centrals.enrollment.addFloor') }}</h2>
                        <p>{{ $t('views.centrals.enrollment.addFloorInstructions') }}</p>
                        <!-- if there is no building, add a new one -->
                        <ioAutocomplete
                            clearable
                            select-first
                            :query="selectedBuilding"
                            :extra-data="floorsExtraData"
                            @change="onFloorChange"
                            :fetch-items="fetchFloors"
                            v-model="selectedFloor" />
                    </v-col>
                </v-row>
                <v-row
                    class="tw-m-0"
                    v-if="selectedFloor == 'new'">
                    <v-col
                        cols="12"
                        sm="6">
                        <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.enrollment.floorName') }}* </v-label>
                        <v-text-field
                            v-model="newFloor.name"
                            :rules="[$rules.required]" />
                    </v-col>
                </v-row>
            </template>
            <template #actions="{ prev, next }">
                <div class="tw-mt-8 tw-flex tw-items-center tw-justify-between">
                    <v-btn
                        :readonly="!canPrev"
                        :color="!canPrev ? 'gray-200' : 'white'"
                        @click="prev"
                        >{{ $t('shared.goBack') }}</v-btn
                    >
                    <v-btn
                        v-if="!isLastStep"
                        :disabled="!canNext"
                        color="primary"
                        @click="next"
                        >{{ $t('shared.next') }}</v-btn
                    >
                    <v-btn
                        v-else
                        color="primary"
                        :disabled="!canNext"
                        @click="onSubmit">
                        {{ $t('shared.save') }}
                    </v-btn>
                </div>
            </template>
        </v-stepper>
    </v-container>
</template>

<script setup lang="ts">
    import _ from 'lodash';
    import ioAutocomplete from '@/components/ioAutocomplete.vue';
    import { createBuilding, getBuildings } from '@/api/buildings';
    import { Building, Floor } from '@/contentTypes';
    import { computed, ref } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import { QrcodeStream } from 'vue-qrcode-reader';
    import useRules from '@/composables/rules';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';
    import { createFloor, getFloors } from '@/api/floor';
    import { adminEnrollment } from '@/api/centrals';
    import { useRouter } from 'vue-router';
    import { useAppStore } from '@/store/app';

    const authStore = useAuthStore();
    const appStore = useAppStore();
    const $rules = useRules();
    const $alert = useAlert();
    const { t } = useI18n();
    const isLoading = ref(false);
    const $router = useRouter();

    const buildingsExtraData = computed(() => {
        return [
            {
                id: 'new',
                name: 'Adicionar novo edifício',
            },
        ];
    });
    const floorsExtraData = computed(() => {
        return [
            {
                id: 'new',
                name: 'Adicionar novo piso',
            },
        ];
    });
    const newBuilding = ref<Building>({
        // @ts-ignore
        name: null,
        // @ts-ignore
        address: null,
        // @ts-ignore
        phone: null,
        // @ts-ignore
        email: null,
        lat: null,
        lng: null,
    });
    const newFloor = ref<Floor>({
        name: '',
        building: null,
    });
    const currentStep = ref(1);
    const selectedBuilding = ref<number | string | null>(null);
    const selectedFloor = ref<number | string | null>(null);
    const centralCode = ref('');
    const showReader = ref(false);

    const canNext = computed(() => {
        if (currentStep.value == 1) return !!centralCode.value;

        if (currentStep.value == 2) return (selectedBuilding.value && selectedBuilding.value != 'new') || (selectedBuilding.value == 'new' && newBuilding.value.name);

        if (currentStep.value == 3) return (selectedFloor.value && selectedFloor.value != 'new') || (selectedFloor.value == 'new' && newFloor.value.name);
    });

    const canPrev = computed(() => currentStep.value > 1);

    const isLastStep = computed(() => currentStep.value == 3);

    // fetch buildings
    async function fetchBuildings({ page, search, ids }: { page?: number; search?: string; ids?: [number] }) {
        const query = {};

        _.set(query, '$or[0].floors.centrals.users.id', authStore.getUser.id);
        _.set(query, '$or[1].users.id', authStore.getUser.id);

        if (search) {
            _.set(query, '$or[0].name.$containsi', search);
            _.set(query, '$or[1].name.$containsi', search);
        }

        if (_.isArray(ids) && ids.length > 0) {
            _.set(query, '$or[2].id.$in', ids);
        }

        return getBuildings({
            fields: ['id', 'name'],
            filters: query,
            pagination: {
                page,
            },
        });
    }

    function onBuildingsChange(value: number | string | null) {
        selectedBuilding.value = value;
    }

    function onFloorChange(value: number | string | null) {
        selectedFloor.value = value;
    }

    async function fetchFloors({ page, search, ids }: { page?: number; search?: string; ids?: [number] }) {
        if (!selectedBuilding.value || selectedBuilding.value == 'new')
            return {
                data: [],
            };
        const query = {};

        _.set(query, '$or[0].centrals.users.id', authStore.getUser.id);
        _.set(query, '$or[0].building.id', selectedBuilding.value);
        _.set(query, '$or[1].building.users.id', authStore.getUser.id);
        _.set(query, '$or[1].building.id', selectedBuilding.value);

        if (search) {
            _.set(query, '$or[0].name.$containsi', search);
            _.set(query, '$or[1].name.$containsi', search);
        }

        if (_.isArray(ids) && ids.length > 0) {
            _.set(query, '$or[2].id.$in', ids);
        }

        return getFloors({
            fields: ['id', 'name'],
            filters: query,
            pagination: {
                page,
            },
        });
    }

    function onStreamDetect(result: any) {
        const [SN, key] = result[0].rawValue.split(';');
        if (SN.length !== 5 || key.length !== 64) {
            showReader.value = false;
            $alert.showAlert({
                text: t('views.centrals.enrollment.invalidQRCode'),
                type: 'error',
            });
            return;
        }

        centralCode.value = result[0].rawValue;
        showReader.value = false;
        currentStep.value++;
    }

    function onStreamError(error: string) {
        console.error(error);
    }

    async function onSubmit() {
        let centralId = null;
        let buildingId: number | null = null;
        let floorId = null;
        isLoading.value = true;

        try {
            if (selectedBuilding.value == 'new') {
                const { data } = await createBuilding({
                    ...newBuilding.value,
                    users: [authStore.getUser.id],
                    company: authStore.getUser.company.id,
                    floors: [],
                    manager: authStore.getUser.id,
                });
                buildingId = _.get(data, 'data.id', 0) as number;
                if (!buildingId) {
                    $alert.showAlert({
                        text: t('views.centrals.enrollment.errorOnBuilding'),
                        type: 'error',
                    });
                    return;
                }
            } else {
                buildingId = selectedBuilding.value as number;
            }
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                text: t('views.centrals.enrollment.errorOnBuilding'),
                type: 'error',
            });
            isLoading.value = false;
            return;
        }

        try {
            if (selectedFloor.value == 'new') {
                const { data } = await createFloor({
                    ...newFloor.value,
                    building: buildingId,
                    centrals: [],
                    blueprint: null,
                });
                floorId = _.get(data, 'data.id');
                if (!floorId) {
                    $alert.showAlert({
                        text: t('views.centrals.enrollment.errorOnFloor'),
                        type: 'error',
                    });
                    return;
                }
            } else {
                floorId = selectedFloor.value;
            }
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                text: t('views.centrals.enrollment.errorOnFloor'),
                type: 'error',
            });
            isLoading.value = false;
            return;
        }

        try {
            const [SN, key] = centralCode.value.split(';');
            const { data } = await adminEnrollment(SN, {
                key,
                floor: floorId,
            });

            centralId = _.get(data, 'central.id');
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                text: t('views.centrals.enrollment.errorOnCentral'),
                type: 'error',
            });
            isLoading.value = false;
            return;
        }

        appStore.triggerSidebar();

        $alert.showAlert({
            text: t('views.centrals.enrollment.success'),
            type: 'success',
        });

        $router.push({ name: 'Central', params: { id: centralId } });

        isLoading.value = false;
    }
</script>

<style>
    .v-stepper-header {
        box-shadow: none;
    }
    .v-stepper-window {
        margin: 0 !important;
    }
    .v-stepper--alt-labels .v-stepper-item {
        flex-basis: 95px;
        padding-inline: 0;
    }
</style>
