import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/zones';
export const API_REF = ContentTypesUID.Zone;

export async function getZones(query: APIRequestParams<'Zone'> = {}): AxiosPromise<APIResponseMany<'Zone'>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`);
}

export async function getZone(id: string, query: APIRequestParams<'Zone'> = {}): AxiosPromise<APIResponseSingle<'Zone'>> {
    return axios.get(`${API_URL}/${id}?${qs.stringify(query)}`);
}

export async function updateZone(id: number, data: ContentType<'Zone', false>): AxiosPromise<APIResponseSingle<'Zone'>> {
    return axios.put(`${API_URL}/${id}`, { data });
}
