<template>
    <v-app>
        <router-view />
        <PromptUpdate />
    </v-app>
</template>

<script setup lang="ts">
    import PromptUpdate from '@/components/PromptUpdate.vue';

    import { ref } from 'vue';
    import { useLocale } from './composables/useLocale';
    import { getTranslations } from './api/translations';
    import { useI18n } from 'vue-i18n';
    import { onMounted } from 'vue';
    import { useAppStore } from './store/app';

    const appStore = useAppStore();
    const i18n = useI18n({ useScope: 'global' });
    const $locale = useLocale();
    $locale.setLocale($locale.getLocale());

    async function fetchTranslations() {
        const { data } = await getTranslations({
            populate: 'localizations',
        });

        i18n.mergeLocaleMessage(data.data.locale, data.data.json);

        for (const locale of data.data.localizations ?? []) {
            i18n.mergeLocaleMessage(locale.locale, locale.json);
        }
    }

    if (import.meta.env.PROD) fetchTranslations();

    onMounted(() => {
        window.addEventListener('appinstalled', () => {
            appStore.setPWA(true);
        });
        navigator.serviceWorker.onmessage = (event) => {
            if (event.data.messageType === 'notification-clicked') {
                window.location.href = event.data.notification.click_action;
            }
        };
    });
</script>
