<template>
    <v-card
        class="mx-auto tw-h-full tw-w-full tw-p-3 xl:tw-p-5"
        variant="outlined"
        color="gray">
        <div class="tw-flex tw-h-full tw-w-full">
            <div class="tw-flex tw-grow tw-flex-col tw-text-sm tw-font-medium">
                <p class="tw-mb-1 tw-text-gray">{{ entityType?.text }}</p>
                <p class="tw-mt-auto tw-text-dark tw-text-3xl">{{ value }}</p>
            </div>
            <div class="tw-flex tw-aspect-square tw-h-full tw-max-h-[50px] tw-w-full tw-max-w-[50px] tw-items-center tw-justify-center tw-rounded-md">
                <v-icon
                    v-if="entityType?.icon"
                    color="primary"
                    :icon="entityType.icon"
                    class="tw-h-8 tw-w-8 tw-text-3xl md:tw-text-5xl lg:tw-text-4xl xl:tw-text-5xl md:tw-h-12 md:tw-w-12 lg:tw-h-8 lg:tw-w-8 xl:tw-h-12 xl:tw-w-12"></v-icon>
                <v-img
                    v-if="entityType?.img"
                    class="tw-h-8 tw-w-8 md:tw-h-12 md:tw-w-12 lg:tw-h-8 lg:tw-w-8 xl:tw-h-12 xl:tw-w-12"
                    contain
                    :src="entityType?.img"></v-img>
            </div>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { PropType, computed } from 'vue';
    import { EntitiesCount } from '@/types';
    import { useI18n } from 'vue-i18n';
    import { useConfig } from '@/composables/useConfig';

    const { t } = useI18n();
    const $config = useConfig();

    const props = defineProps({
        type: {
            type: String as PropType<keyof EntitiesCount>,
            required: true,
        },
        value: {
            type: Number,
            default: 0,
        },
    });

    const entityType = computed(() => {
        // @ts-ignore
        return $config.entityTypes.value(props.type);
    });
</script>

<style scoped></style>
