import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/device-configurations';
export const API_REF = ContentTypesUID.DeviceConfiguration;

export async function bulkUpdateDeviceConfigurations(
    data: ContentType<'DeviceConfiguration'>[],
    query: APIRequestParams<'DeviceConfiguration'> = {},
): AxiosPromise<APIResponseMany<'DeviceConfiguration'>> {
    const params = qs.stringify(query);
    return axios.put(`${API_URL}/bulk?${params}`, { data });
}
