// Utilities
import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    state: () => ({
        _isSidebarOpen: false,
        locale: '',
        _isPWA: window.matchMedia('(display-mode: standalone)').matches,
        _triggerSidebar: 0,
    }),
    getters: {
        isSidebarOpen(): boolean {
            return this._isSidebarOpen;
        },
        getLocale(): string {
            return this.locale;
        },
        isPWA(): boolean {
            return this._isPWA;
        },
        getSidebarTrigger(): number {
            return this._triggerSidebar;
        },
    },
    actions: {
        setIsSidebarOpen(value: boolean) {
            this._isSidebarOpen = value;
        },
        setLocale(value: string) {
            this.locale = value;
        },
        setPWA(value: boolean) {
            this._isPWA = value;
        },
        triggerSidebar() {
            this._triggerSidebar++;
        },
    },
    persist: {
        paths: ['locale'],
    },
});
