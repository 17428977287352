<template>
    <v-dialog
        v-model="showModal"
        id="searchModal"
        max-width="760">
        <template #default>
            <v-card class="md:tw-opacity-[97%]">
                <v-card-title class="tw-sticky tw-top-0 tw-z-1 tw-bg-white">
                    <v-text-field
                        placeholder="Search"
                        @update:model-value="onSearch"
                        @click:append-inner="clearSearch"
                        outlined
                        autofocus
                        variant="outlined"
                        density="comfortable"
                        clearable
                        prepend-inner-icon="mdi-magnify"></v-text-field>
                </v-card-title>
                <v-card-text v-if="items && Object.keys(items).length > 0">
                    <v-list lines="two">
                        <template v-for="(value, key, groupIndex) in items">
                            <v-list-subheader class="tw-bg-gray-200 tw-font-bold tw-uppercase">{{ $t(`searchResults.${key}`) }}</v-list-subheader>
                            <v-list-item
                                v-for="(v, itemIndex) in value"
                                @click="showModal = false"
                                color="primary"
                                :to="v.route"
                                :key="`${key}_${v.id}`"
                                :title="v.title"
                                class="tw-border-b tw-border-gray-200">
                                <template #prepend>
                                    <div
                                        v-if="typeof v.avatar == 'string' && v.avatar.startsWith('mdi-')"
                                        class="tw-mr-2 tw-flex tw-aspect-square tw-h-[40px] tw-w-[40px] tw-items-center tw-justify-center tw-rounded-md"
                                        :class="getColor(key)">
                                        <v-icon
                                            color="white"
                                            size="32px">
                                            {{ v.avatar }}
                                        </v-icon>
                                    </div>
                                    <v-img
                                        v-else-if="typeof v.avatar == 'string'"
                                        width="40"
                                        height="40"
                                        class="tw-mr-2 tw-rounded-md tw-border tw-border-gray-200"
                                        :src="v.avatar">
                                    </v-img>
                                    <io-img
                                        v-else
                                        :format="'thumbnail'"
                                        :media="v.avatar"
                                        :alt="getTitle(v, key)"
                                        content-type="any"
                                        width="40"
                                        height="40"
                                        class="tw-mr-2 tw-rounded-md tw-border tw-border-gray-200"></io-img>
                                </template>
                                <template #subtitle>
                                    <div class="tw-flex tw-justify-between">
                                        <v-list-item-subtitle opacity="100">{{ v.subtitle }}</v-list-item-subtitle>
                                    </div>
                                </template>
                            </v-list-item>
                            <div :class="groupIndex == Object.keys(items).length - 1 ? 'max-sm:tw-mb-16' : ''"></div>
                        </template>
                    </v-list>
                </v-card-text>
                <v-card-text
                    v-else-if="isLoading"
                    class="tw-text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"></v-progress-circular>
                </v-card-text>
                <v-card-text v-else-if="hasFetched">
                    <p class="tw-text-center">
                        {{ $t('searchResults.noResults') }}
                    </p>
                </v-card-text>
            </v-card>
        </template>
        <template #activator="{ isActive }">
            <v-btn
                @click="showModal = !showModal"
                :icon="isActive ? 'mdi-close' : 'mdi-magnify'"
                color="primary"
                position="fixed"
                location="end bottom"
                class="tw-z-[2401] tw-mb-4 tw-me-4"></v-btn>
        </template>
    </v-dialog>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { search } from '@/api/search';
    import { SearchResponse } from '@/types';
    import { watch } from 'vue';
    import _ from 'lodash';
    import ioImg from '@/components/ioImg.vue';
    import { useConfig } from '@/composables/useConfig';
    import { searchResultsColors, searchResultsIcons } from '../config/config';

    const showModal = ref(false);
    const isLoading = ref(false);
    const hasFetched = ref(false);
    const items = ref<{
        [key in keyof SearchResponse]: {
            id: string;
            title: string;
            subtitle: string;
            avatar: string;
            route: { name: string; params: { id: string } };
        }[];
    }>();
    const timeoutId = ref<NodeJS.Timeout>();
    const $config = useConfig();

    function onSearch(searchString: string) {
        if (!searchString) return;
        if (timeoutId.value) clearTimeout(timeoutId.value);
        timeoutId.value = setTimeout(async () => {
            isLoading.value = true;
            items.value = undefined;
            const list: any = {};
            try {
                const { data } = await search(searchString.trim());
                // @ts-ignore
                const keys = Object.keys(data)
                    // @ts-ignore
                    .sort((a, b) => data[a].length - data[b].length)
                    // @ts-ignore
                    .filter((key) => data[key].length > 0);
                // @ts-ignore
                const sortedData: SearchResponse = {};
                // @ts-ignore
                keys.forEach((key) => (sortedData[key] = data[key]));

                for (const key in sortedData) {
                    if (!list[key]) {
                        list[key] = [];
                    }

                    // @ts-ignore
                    sortedData[key].forEach((item: any) => {
                        list[key].push({
                            id: item.id,
                            title: getTitle(item, key),
                            subtitle: getSubtitle(item, key),
                            avatar: getAvatar(item, key),
                            route: getRoute(item, key),
                        });
                    });
                }

                // sort by title
                for (const key in list) {
                    list[key].sort((a: any, b: any) => {
                        const subtitleComparison = a.subtitle.toLowerCase().localeCompare(b.subtitle.toLowerCase());
                        if (subtitleComparison !== 0) {
                            return subtitleComparison;
                        }
                        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
                    });
                }

                items.value = list;
            } catch (error) {
            } finally {
                isLoading.value = false;
                hasFetched.value = true;
            }
        }, 500);
    }

    function clearSearch() {
        items.value = undefined;
    }

    function getTitle(obj: any, key: string) {
        if (key == 'loops') return _.get(obj, 'loopID', '');
        return _.get(obj, 'name', '');
    }

    function getColor(key: keyof typeof searchResultsColors) {
        if (key in searchResultsColors) {
            return searchResultsColors[key];
        }
        return 'tw-bg-primary';
    }

    function getSubtitle(obj: any, key: string) {
        if (key == 'loops') {
            const buildingName = _.get(obj, 'central.floor.building.name', '');
            const floorName = _.get(obj, 'central.floor.name', '');
            const centralName = _.get(obj, 'central.name', '');

            let str = '';

            if (buildingName) {
                str += buildingName;
            }

            if (floorName) {
                str += `${buildingName ? ' > ' : ''}${floorName}`;
            }

            if (centralName) {
                str += `${buildingName || floorName ? ' > ' : ''}${centralName}`;
            }

            return str;
        }

        if (key == 'buildings') {
            return _.get(obj, 'address', '');
        }

        if (key == 'floors') {
            const buildingName = _.get(obj, 'building.name', '');
            return buildingName;
        }

        if (key == 'centrals') {
            const buildingName = _.get(obj, 'floor.building.name', '');
            const floorName = _.get(obj, 'floor.name', '');
            const model = _.get(obj, 'model', '');
            const serialNumber = _.get(obj, 'serialNumber', '');

            let str = '';

            if (buildingName) {
                str += buildingName;
            }

            if (floorName) {
                str += `${buildingName ? ' > ' : ''}${floorName}`;
            }

            if (model) {
                str += `${buildingName || floorName ? ' | ' : ''}${model}`;
            }

            if (serialNumber) {
                str += `${(buildingName || floorName || model) && !serialNumber ? ' | ' : model ? ' - ' : ''}${serialNumber}`;
            }

            return str;
        }

        if (key == 'zones') {
            const buildingName = _.get(obj, 'central.floor.building.name', '');
            const floorName = _.get(obj, 'central.floor.name', '');
            const centralName = _.get(obj, 'central.name', '');

            let str = '';

            if (buildingName) {
                str += buildingName;
            }

            if (floorName) {
                str += `${buildingName ? ' > ' : ''}${floorName}`;
            }

            if (centralName) {
                str += `${buildingName || floorName ? ' > ' : ''}${centralName}`;
            }

            return str;
        }

        if (key == 'devicesInstalled') {
            const buildingName = _.get(obj, 'loop.central.floor.building.name', '');
            const floorName = _.get(obj, 'loop.central.floor.name', '');
            const centralName = _.get(obj, 'loop.central.name', '');
            const deviceModel = _.get(obj, 'device.model', '');
            const serialNumber = _.get(obj, 'serialNumber', '');
            let str = '';
            if (buildingName) {
                str += buildingName;
            }
            if (floorName) {
                str += `${buildingName ? ' > ' : ''}${floorName}`;
            }
            if (centralName) {
                str += `${buildingName || floorName ? ' > ' : ''}${centralName}`;
            }
            if (deviceModel) {
                str += `${buildingName || floorName || centralName ? ' | ' : ''}${deviceModel}`;
            }

            if (serialNumber) {
                str += `${(buildingName || floorName || centralName) && !deviceModel ? ' | ' : deviceModel ? ' - ' : ''}${serialNumber}`;
            }

            return str;
        }

        if (key == 'users') {
            return _.get(obj, 'email', '');
        }

        return '';
    }

    function getRoute(obj: any, key: string) {
        if (key == 'loops') return { name: 'Loop', params: { id: obj.id } };
        if (key == 'buildings') return { name: 'Building', params: { id: obj.id } };
        if (key == 'floors') return { name: 'Floor', params: { id: obj.id } };
        if (key == 'centrals') return { name: 'Central', params: { id: obj.id } };
        if (key == 'zones') return { name: 'Zone', params: { id: obj.id } };
        if (key == 'devicesInstalled') return { name: 'Device-Events', params: { id: obj.id } };
        if (key == 'users') return { name: 'User', params: { id: obj.id } };
        return undefined;
    }

    function getAvatar(obj: any, key: string) {
        if (key == 'loops') return searchResultsIcons.loops;
        if (key == 'buildings') return searchResultsIcons.buildings;
        if (key == 'floors') return _.get(obj, 'blueprint') || searchResultsIcons.floors;
        if (key == 'centrals') return new URL('../assets/images/devices/NURIA.svg', import.meta.url).href;
        if (key == 'zones') return searchResultsIcons.zones;
        if (key == 'devicesInstalled') return _.get(obj, 'device.picture') || $config.deviceTypes.value.find((dt) => dt.value == obj.device.type)?.img || searchResultsIcons.devicesInstalled;
        if (key == 'users') return _.get(obj, 'picture') || searchResultsIcons.users;
        return '';
    }

    watch(
        () => showModal.value,
        (value) => {
            if (!value) {
                // items.value = undefined;
                // timeoutId.value = undefined;
            }
        },
    );
</script>

<style lang="scss">
    #searchModal {
        @apply tw-items-start;
        .v-overlay__content {
            @apply max-sm:tw-m-0 max-sm:tw-max-h-full max-sm:tw-w-full;

            .v-card-text {
                @apply tw-px-0;
            }
            .v-list-item__spacer {
                @apply tw-hidden;
            }
        }
    }
</style>
