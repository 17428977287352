import slugify from 'slugify';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

export function useError() {
    const { t } = useI18n();
    function getErrorKey(error: unknown, localKey: string = ''): string {
        if (!localKey.endsWith('.')) localKey += '.';
        const slug = slugify(_.get(error, 'response.data.error.message', 'unknown_error'), {
            replacement: '_',
            trim: true,
            lower: true,
        });

        return slug == 'unknown_error' ? slug : `${localKey}${slug}`;
    }

    function getErrorMessage(error: unknown, localKey: string = ''): string {
        const errorKey = getErrorKey(error, localKey);
        const errorMessage = t(errorKey, t('shared.unknown_error'));
        if (errorMessage == t('shared.unknown_error')) {
            console.error('Unknown error key:', errorKey);
            console.error('Error:', error);
            // if its a development environment, we can alert the error key
            if (import.meta.env.DEV) alert('Unknown error key: ' + errorKey);
        }
        return errorMessage;
    }

    return {
        getErrorKey,
        getErrorMessage,
    };
}

export default useError;
