import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/nibble-permissions';
export const API_REF = ContentTypesUID.NibblePermission;

export async function getPermissions(query: APIRequestParams<'NibblePermission'> = {}): AxiosPromise<APIResponseMany<'NibblePermission'>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`);
}
