import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import { EntitiesCount } from '@/types';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/users';
export const API_REF = ContentTypesUID.User;

export async function getUsers(query: APIRequestParams<'User'> = {}): AxiosPromise<APIResponseMany<'User'>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`);
}

export async function getUser(id: string, query: APIRequestParams<'User'> = {}): AxiosPromise<APIResponseSingle<'User'>> {
    return axios.get(`${API_URL}/${id}?${qs.stringify(query)}`);
}

export async function updateUser(id: string | number, data: ContentType<'User', false>, query: APIRequestParams<'User'> = {}) {
    const params = qs.stringify(query);
    return axios.put(`${API_URL}/${id}?${params}`, { data });
}

export async function createUser(data: ContentType<'User', false>, query: APIRequestParams<'User'> = {}) {
    const params = qs.stringify(query);
    return axios.post(`${API_URL}?${params}`, { data });
}

export function unpopulateUser(user: ContentType<'User'>): ContentType<'User', false> {
    const newUser: any = { ...user };
    for (const key in newUser) {
        if (Array.isArray(newUser[key]) && newUser[key].every((item: any) => typeof item === 'object')) {
            newUser[key] = newUser[key].map((item: any) => item.id);
        } else if (typeof newUser[key] === 'object' && !!newUser[key]) {
            newUser[key] = newUser[key].id;
        }
    }
    return newUser;
}

export function changePassword({ currentPassword, password, passwordConfirmation }: { currentPassword: string; password: string; passwordConfirmation: string }) {
    return axios.post(`/auth/change-password`, { currentPassword, password, passwordConfirmation });
}

export function deleteUsers(ids: number[]) {
    return axios.delete(`/delete-many-users?${qs.stringify({ ids })}`);
}

export function getEntitiesCount(): AxiosPromise<EntitiesCount> {
    return axios.get(`/users/entities/count`);
}

export function register(data: ContentType<'User', false> & { companyName: string }) {
    return axios.post(`/auth/local/register`, { ...data });
}