import { ContentTypes, Media } from '@/contentTypes';
import _ from 'lodash';
import _axios from 'axios';

type imageContentType = keyof ContentTypes | 'default';

function createFormats(url: string) {
    return {
        thumbnail: url,
        small: url,
        medium: url,
        large: url,
        original: url,
    };
}

export function useMedia(media: Media<true> | null | undefined, contentType: imageContentType = 'default') {
    if (!media) {
        return createFormats(getPlaceholderImage(contentType));
    }

    function joinUrl(url: string) {
        if (url.startsWith('http') || url.startsWith('data') || url.startsWith('blob')) {
            return url;
        }
        return import.meta.env.VITE_API_URL + url;
    }

    const defaultUrl = _.get(media, 'url', '');
    return {
        thumbnail: joinUrl(_.get(media, 'formats.thumbnail.url', defaultUrl)),
        small: joinUrl(_.get(media, 'formats.small.url', defaultUrl)),
        medium: joinUrl(_.get(media, 'formats.medium.url', defaultUrl)),
        large: joinUrl(_.get(media, 'formats.large.url', defaultUrl)),
        original: joinUrl(defaultUrl),
    };
}

export function getPlaceholderImage(contentType: imageContentType) {
    let placeholder = new URL(`../assets/images/placeholder/${contentType.toLowerCase()}.svg`, import.meta.url).href;

    if (placeholder.includes('undefined')) {
        placeholder = new URL(`../assets/images/placeholder/default.svg`, import.meta.url).href;
    }
    return placeholder;
}

export async function parseMediaToFile(media: Media): Promise<File> {
    const url = media.url.startsWith('http') || media.url.startsWith('data') || media.url.startsWith('blob') ? media.url : import.meta.env.VITE_API_URL + media.url;
    const response = await _axios.get(url, { responseType: 'blob' });
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const lastModified = media.updatedAt ? new Date(media.updatedAt).getTime() : Date.now();
    return new File([blob], media.name, { type: media.mime, lastModified });
}

export function parseFileToMedia(file: File): Media {
    return {
        name: file.name,
        url: URL.createObjectURL(file),
        mime: file.type,
        size: file.size,
        provider: 'local',
        updatedAt: new Date(file.lastModified).toISOString(),
    };
}

export async function rotateImage(media: Media | File, degrees: number = 90) {
    const file = media instanceof File ? media : await parseMediaToFile(media);
    const mime = media instanceof File ? media.type : media.mime;
    return new Promise<File>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d')!;
                canvas.width = img.height;
                canvas.height = img.width;
                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((degrees * Math.PI) / 180);
                ctx.drawImage(img, -img.width / 2, -img.height / 2);
                canvas.toBlob((blob) => {
                    if (blob) {
                        const lastModified = media instanceof File ? media.lastModified : Date.now();
                        const file = new File([blob], media.name, { type: mime, lastModified });
                        resolve(file);
                    } else {
                        reject(new Error('Failed to rotate image'));
                    }
                }, mime);
            };
            img.src = e.target?.result as string;
        };
        reader.readAsDataURL(file);
    });
}
