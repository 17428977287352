<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row v-if="isLoading">
            <v-col cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <template v-if="floor">
            <v-row
                align="start"
                justify="start"
                class="fill-width h-auto justify-start align-content-start">
                <v-col
                    cols="12"
                    class="tw-flex tw-flex-wrap tw-items-center tw-gap-4">
                    <v-btn
                        @click="$router.go(-1)"
                        color="gray-300"
                        class="tw-rounded tw-text-xl"
                        size="small"
                        icon="mdi-chevron-left">
                    </v-btn>
                    <h1 class="tw-text-xl tw-font-bold md:tw-text-2xl">{{ floor.name }}</h1>
                    <v-btn
                        v-if="$auth.can('edit_blueprints') && !isEditing"
                        class="tw-ml-auto tw-rounded max-md:tw-text-xl"
                        color="primary"
                        @click="isEditing = !isEditing"
                        :icon="!mdAndUp ? 'mdi-pencil' : false"
                        :text="mdAndUp ? $t('views.floors.editBlueprint') : undefined">
                    </v-btn>
                    <v-btn
                        v-if="isEditing"
                        class="tw-ml-auto tw-rounded max-md:tw-text-xl"
                        color="primary"
                        @click="showSidebarDevices = !showSidebarDevices"
                        :icon="!mdAndUp ? 'mdi-plus' : false"
                        :text="mdAndUp ? $t('views.floors.addDevices') : undefined"></v-btn>
                </v-col>
                <v-col cols="12">
                    <v-breadcrumbs
                        class="tw-p-0"
                        :items="breadcrumbs">
                    </v-breadcrumbs>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <Blueprint
                        v-if="floor.blueprint && showBlueprint"
                        :floor="floor"
                        :read-only="!isEditing"
                        :show-sidebar="showSidebarDevices"></Blueprint>
                    <template v-if="isEditing">
                        <input
                            type="file"
                            accept="image/*"
                            @change="uploadBlueprint"
                            ref="fileInput"
                            style="display: none" />
                        <v-btn
                            class="tw-mt-4"
                            color="primary"
                            :loading="isLoadingBlueprint"
                            @click="triggerFileInput"
                            >{{ $t('views.floors.uploadBlueprint') }}</v-btn
                        >
                    </template>
                </v-col>
            </v-row>
        </template>
    </v-container>
    <SidebarDevices v-model="showSidebarDevices" />
</template>

<script setup lang="ts">
    import Blueprint from './Blueprint.vue';
    import { ContentType } from '@/contentTypes';
    import { ref } from 'vue';
    import { getFloor, API_REF } from '@/api/floor';
    import { useRoute } from 'vue-router';
    import { useAlert } from '@/composables/useAlert';
    import { useError } from '@/composables/useError';
    import { useDisplay } from 'vuetify/lib/framework.mjs';
    import { computed } from 'vue';
    import { uploadFiles } from '@/api/upload';
    import SidebarDevices from './SidebarDevices.vue';
    import { useBlueprintStore } from '@/store/blueprint';
    import { getDevicesInstalled } from '@/api/devicesInstalled';
    import { useAuthStore } from '@/store/auth';
    import { useAuth } from '../../../composables/useAuth';
    import { watch } from 'vue';
    import { nextTick } from 'vue';

    const { mdAndUp } = useDisplay();
    const $route = useRoute();
    const $alert = useAlert();
    const blueprintStore = useBlueprintStore();
    const authStore = useAuthStore();
    const $error = useError();
    const $auth = useAuth();

    const showSidebarDevices = ref(false);
    const floor = ref<ContentType<'Floor'>>();
    const isLoading = ref(false);
    const isLoadingBlueprint = ref(false);
    const fileInput = ref<HTMLInputElement>();
    const isEditing = ref(false);
    const showBlueprint = ref(true);

    const breadcrumbs = computed(() => {
        return [
            {
                title: floor.value?.building?.name,
                to: {
                    name: 'Building-Floors',
                    params: {
                        id: floor.value?.building?.id,
                    },
                },
            },
            {
                title: floor.value?.name,
                disabled: true,
            },
        ];
    });

    function triggerFileInput() {
        fileInput.value?.click();
    }

    async function uploadBlueprint(a: any) {
        isLoadingBlueprint.value = true;
        try {
            const file = a.target.files[0];
            const { data } = await uploadFiles({
                files: [file],
                refId: floor.value?.id,
                ref: API_REF,
                field: 'blueprint',
            });
            if (floor.value) {
                floor.value.blueprint = data[0];
            }
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: $error.getErrorMessage(error),
            });
        } finally {
            isLoadingBlueprint.value = false;
        }
    }

    async function init() {
        const id = $route.params.id;
        if (!id) return;
        blueprintStore.setDevices([]);
        try {
            isLoading.value = true;
            const { data } = await getFloor(id as string, {
                populate: {
                    building: {
                        fields: ['id', 'name'],
                    },
                    blueprint: true,
                },
            });
            floor.value = data.data;

            const dRes = await getDevicesInstalled({
                fields: ['id', 'blueprintData', 'name'],
                filters: {
                    $or: [
                        {
                            loop: {
                                central: {
                                    users: {
                                        id: authStore.getUser.id,
                                    },
                                    floor: {
                                        id,
                                    },
                                },
                            },
                        },
                        {
                            loop: {
                                central: {
                                    floor: {
                                        building: {
                                            users: {
                                                id: authStore.getUser.id,
                                            },
                                        },
                                        id,
                                    },
                                },
                            },
                        },
                    ],
                },
                populate: {
                    device: {
                        fields: ['id'],
                        populate: {
                            picture: true,
                        },
                    },
                },
                pagination: {
                    pageSize: 100,
                },
            });
            blueprintStore.setDevices(dRes.data.data);
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: $error.getErrorMessage(error),
            });
        } finally {
            isLoading.value = false;
        }
    }

    watch(isEditing, () => {
        showBlueprint.value = !showBlueprint.value;
        nextTick(() => {
            showBlueprint.value = !showBlueprint.value;
        });
    });

    init();
</script>

<style scoped></style>
