import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import { DataWrapper, EventStatusCount } from '@/types';
import axios from '@axios';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import _ from 'lodash';

export const API_URL = '/events';
export const API_REF = ContentTypesUID.Event;

export async function getEvents(query: APIRequestParams<'Event'> = {}, options?: AxiosRequestConfig): AxiosPromise<APIResponseMany<'Event'>> {
    query = _.cloneDeep(query);
    // check if query has a sort key
    if (query.sort) {
        if (Array.isArray(query.sort)) {
            query.sort.push('id:desc');
        } else {
            query.sort = [query.sort, 'id:desc'];
        }
    }

    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`, options);
}

export async function countEventsPerType(): AxiosPromise<DataWrapper<EventStatusCount[]>> {
    return axios.get(`${API_URL}/count-per-type`);
}

export async function getEventsExtensive(query: APIRequestParams<'Event'> = {}): AxiosPromise<APIResponseMany<'Event'>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}/extensive-find?${params}`);
}

export async function updateManyEvents(query: APIRequestParams<'Device'> = {}, data: any): AxiosPromise<APIResponseMany<'Event'>> {
    const params = qs.stringify(query);
    return axios.put(`${API_URL}/many?${params}`, { data });
}

export async function updateEvent(id: number, data: any): AxiosPromise<APIResponseSingle<'Event'>> {
    return axios.put(`${API_URL}/${id}`, { data });
}
