import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/devices-installed';
export const API_REF = ContentTypesUID.DeviceInstalled;

export async function getDevicesInstalled(query: APIRequestParams<'DeviceInstalled'> = {}): AxiosPromise<APIResponseMany<'DeviceInstalled'>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`);
}

export async function getDeviceInstalled(id: number, query: APIRequestParams<'DeviceInstalled'> = {}): AxiosPromise<APIResponseSingle<'DeviceInstalled'>> {
    return axios.get(`${API_URL}/${id}?${qs.stringify(query)}`);
}

export async function updateDeviceInstalled(id: number, data: ContentType<'DeviceInstalled'>, query: APIRequestParams<'DeviceInstalled'> = {}): AxiosPromise<APIResponseSingle<'DeviceInstalled'>> {
    const params = qs.stringify(query);
    return axios.put(`${API_URL}/${id}?${params}`, { data });
}
