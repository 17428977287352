<template>
    <v-card
        @click.stop="
            $router.push({
                name: 'Building-Dashboard',
                params: { id: centralEvents.building.id },
            })
        "
        height="100%"
        class="mx-auto tw-flex tw-flex-col tw-p-8"
        variant="outlined"
        color="gray">
        <div class="tw-flex tw-flex-col tw-justify-center tw-gap-2 tw-text-center">
            <h1
                v-show="buildingName"
                class="tw-font-semibold tw-text-dark">
                {{ buildingName }}
            </h1>
            <div class="tw-mt-8 tw-flex tw-justify-center tw-gap-4">
                <v-badge
                    v-for="(event, i) in eventTypes"
                    tag="a"
                    :content="event.count"
                    :color="event.count ? 'primary' : 'gray'"
                    class="tw-cursor-pointer"
                    :class="`tw-z-${eventTypes.length - i}`"
                    @click.stop="
                        $router.push({
                            name: 'Building-Dashboard',
                            params: {
                                id: centralEvents.building.id,
                            },
                            query: {
                                type: event.type,
                            },
                        })
                    "
                    :key="event.type">
                    <v-icon
                        :color="event.color"
                        :icon="event.icon"
                        :class="`${event.twBgColor} tw-rounded-md tw-p-5`"
                        size="x-large"></v-icon>
                </v-badge>
            </div>
            <!--  -->
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { BuildingEventStatusCount } from '@/types';
    import { computed } from 'vue';
    import { PropType } from 'vue';
    import _ from 'lodash';
    import { eventTypeDetails } from '@/config/config';

    const props = defineProps({
        centralEvents: {
            type: Object as PropType<BuildingEventStatusCount>,
            required: true,
            default: () => ({}),
        },
    });

    const centralName = computed(() => {
        return _.get(props.centralEvents, 'central.name');
    });

    const buildingName = computed(() => {
        return _.get(props.centralEvents, 'building.name');
    });

    const eventTypes = computed(() => {
        const eventTypes = [];
        for (const event in props.centralEvents.events) {
            eventTypes.push({
                type: event,
                count: props.centralEvents.events[event as keyof typeof props.centralEvents.events],
                ...eventTypeDetails[event as keyof typeof eventTypeDetails],
            });
        }

        return eventTypes;
    });
</script>

<style scoped></style>
