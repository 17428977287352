<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row v-if="isLoading">
            <v-col cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <v-form
            ref="form"
            v-if="user"
            @submit.prevent="saveUser">
            <v-row
                align="start"
                justify="start"
                class="fill-width h-auto justify-start align-content-start">
                <v-col
                    cols="12"
                    class="tw-flex tw-items-center tw-justify-between">
                    <div class="tw-flex tw-w-full tw-items-center tw-gap-4">
                        <v-btn
                            v-if="!isProfile"
                            @click="$router.go(-1)"
                            color="gray-300"
                            class="tw-rounded tw-text-xl"
                            size="small"
                            icon="mdi-chevron-left">
                        </v-btn>
                        <div class="tw-w-full tw-max-w-72">
                            <v-text-field
                                :placeholder="$t('views.users.user.fields.name')"
                                :rules="[$rules.required]"
                                :disabled="isLoading"
                                v-model="user.name"
                                id="input-name"></v-text-field>
                        </div>
                        <v-btn
                            :loading="isLoading"
                            class="tw-ml-auto"
                            type="submit"
                            color="primary">
                            {{ $t('shared.save') }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="!isProfile">
                <v-col cols="12">
                    <v-breadcrumbs
                        class="tw-p-0"
                        :items="breadcrumbs">
                    </v-breadcrumbs>
                </v-col>
            </v-row>
            <v-row
                class="tw-items-center"
                justify="center"
                justify-md="start">
                <v-col
                    xl="3"
                    md="4"
                    sm="6"
                    cols="8">
                    <ioImg
                        :media="profilePicture"
                        class="tw-relative tw-rounded-2xl tw-border tw-border-gray-300"
                        aspect-ratio="1"
                        cover
                        content-type="User">
                        <v-btn
                            @click="onRotateImage"
                            size="x-small"
                            class="tw-absolute tw-bottom-0 tw-left-0"
                            color="primary"
                            icon="mdi-rotate-right"></v-btn>
                        <v-btn
                            @click="triggerFileInput"
                            size="x-small"
                            class="tw-absolute tw-bottom-0 tw-right-0"
                            color="primary"
                            icon="mdi-pencil"></v-btn>
                    </ioImg>
                    <!-- input file for images -->
                    <input
                        ref="fileInput"
                        type="file"
                        accept="image/*"
                        @change="onFileChange"
                        class="tw-hidden" />
                </v-col>
                <v-col
                    xl="6"
                    md="8"
                    cols="12">
                    <v-text-field
                        :rules="[$rules.required, $rules.email]"
                        :disabled="isLoading"
                        :label="$t('views.users.user.fields.email')"
                        :placeholder="$t('views.users.user.fields.email')"
                        variant="outlined"
                        v-model="user.email"></v-text-field>
                </v-col>
            </v-row>
            <v-row
                v-if="!isProfile"
                class="tw-mt-6"
                no-gutters>
                <v-col cols="12">
                    <h1 class="tw-text-xl tw-font-bold">{{ $t('views.users.user.header_permissions') }}</h1>
                </v-col>
                <v-col
                    v-for="permission in platformPermissions"
                    cols="12"
                    sm="6"
                    md="4"
                    xl="3">
                    <v-checkbox
                        :disabled="isLoading || permission.value == 'show_events'"
                        color="primary"
                        :value="permission.id"
                        v-model="user.permissions"
                        :label="$t(`views.users.user.permissions.${permission.value}`)"></v-checkbox>
                </v-col>
            </v-row>
            <v-row v-if="!isProfile">
                <v-col cols="12">
                    <h1 class="tw-text-xl tw-font-bold">{{ $t('views.users.user.header_permissions_building') }}</h1>
                </v-col>
                <v-col
                    cols="12"
                    v-for="building in buildings">
                    <RowBuildingPermissions
                        v-model:buildings="user.buildings"
                        v-model:centrals="user.centrals"
                        :building="building" />
                </v-col>
            </v-row>
            <template v-if="isProfile">
                <v-row>
                    <v-col cols="12">
                        <h1 class="tw-text-xl tw-font-bold">{{ $t('auth.changePassword') }}</h1>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- current password -->
                    <v-col
                        cols="12"
                        sm="6"
                        lg="5"
                        xl="4">
                        <v-text-field
                            :rules="isChangingPassword ? [$rules.required, (e) => $rules.min(e, 6)] : []"
                            :disabled="isLoading"
                            :label="$t('views.users.currentPassword')"
                            type="password"
                            v-model="currentPassword"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- new password -->
                    <v-col
                        cols="12"
                        sm="6"
                        lg="5"
                        xl="4">
                        <v-text-field
                            :rules="isChangingPassword ? [$rules.required, (e) => $rules.min(e, 6)] : []"
                            :disabled="isLoading"
                            :label="$t('fields.newPassword')"
                            type="password"
                            v-model="newPassword"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- confirm new password -->
                    <v-col
                        cols="12"
                        sm="6"
                        lg="5"
                        xl="4">
                        <v-text-field
                            :rules="isChangingPassword ? [$rules.required, (e) => $rules.min(e, 6), (e) => $rules.comparePassword(e, newPassword)] : []"
                            :disabled="isLoading"
                            :label="$t('views.users.confirmPassword')"
                            type="password"
                            v-model="confirmNewPassword"></v-text-field>
                    </v-col>
                </v-row>
            </template>
        </v-form>
    </v-container>
</template>

<script setup lang="ts">
    import { Building, Media, NibblePermission, User } from '@/contentTypes';
    import { computed, provide, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useRoute, useRouter } from 'vue-router';
    import { VBreadcrumbs, VForm } from 'vuetify/lib/components/index.mjs';
    import _ from 'lodash';
    import ioImg from '@/components/ioImg.vue';
    import { getBuildings } from '@/api/buildings';
    import { getPermissions } from '@/api/permissions';
    import { getUser, unpopulateUser, updateUser, createUser } from '@/api/users';
    import { useAuthStore } from '@/store/auth';
    import useRules from '@/composables/rules';
    import { uploadFiles } from '@/api/upload';
    import { useAlert } from '@/composables/useAlert';
    import RowBuildingPermissions from './RowBuildingPermissions.vue';
    import { User_Locale } from '../../contentTypes';
    import { changePassword } from '../../api/users';
    import useError from '@/composables/useError';
    import { parseFileToMedia, rotateImage } from '@/composables/useMedia';

    const $rules = useRules();
    const $route = useRoute();
    const $router = useRouter();
    const { t, locale } = useI18n();
    const authStore = useAuthStore();
    const $alert = useAlert();
    const $error = useError();

    const form = ref<VForm>();
    const fileInput = ref<HTMLInputElement>();
    const user = ref<User<false>>({
        email: '',
        name: '',
        username: '',
        profilePicture: null,
        permissions: [],
        buildings: [],
        centrals: [],
    });

    const currentPassword = ref('');
    const newPassword = ref('');
    const confirmNewPassword = ref('');

    const profilePicture = ref<Media | null>(null);
    const profilePictureFile = ref<File | null>(null);
    const buildings = ref<Building[]>([]);
    const isLoading = ref(false);
    provide('isLoading', isLoading);

    const isAdding = computed(() => {
        return $route.params.id == 'new';
    });

    const isChangingPassword = computed(() => {
        return currentPassword.value || newPassword.value || confirmNewPassword.value;
    });

    const isProfile = computed(() => {
        return $route.name == 'Profile';
    });

    const platformPermissions = ref<NibblePermission[]>([]);

    const breadcrumbs = computed(() => {
        return [
            {
                title: t('views.users.title'),
                disabled: false,
                to: { name: 'Users' },
            },
            {
                title: user.value?.name ?? '',
                disabled: true,
            },
        ] as VBreadcrumbs['$props']['items'];
    });

    async function fetchPermissions() {
        if (isProfile.value) {
            return;
        }
        const { data } = await getPermissions({
            sort: ['value:asc'],
        });
        platformPermissions.value = data.data;
    }

    function triggerFileInput() {
        fileInput.value?.click();
    }

    async function onRotateImage() {
        if (profilePicture.value) {
            try {
                const rotatedImgFile = await rotateImage(profilePicture.value);
                profilePictureFile.value = rotatedImgFile;
                profilePicture.value = parseFileToMedia(rotatedImgFile);
            } catch (error) {
                $alert.showAlert({
                    text: $error.getErrorMessage(error, 'views.users.rotate_image'),
                    type: 'error',
                });
            }
        }
    }

    async function onFileChange(e: Event) {
        const target = e.target as HTMLInputElement;
        if (!target.files?.length) {
            return;
        }
        profilePictureFile.value = target.files[0];
        profilePicture.value = parseFileToMedia(target.files[0]);
    }

    async function fetchUser() {
        if (isAdding.value && !isProfile.value) {
            return;
        }

        const id = isProfile.value ? authStore.getUser.id : $route.params.id.toString();

        const { data } = await getUser(id, {
            populate: {
                centrals: {
                    fields: ['id'],
                },
                buildings: {
                    fields: ['id'],
                },
                profilePicture: true,
                permissions: true,
            },
        });
        profilePicture.value = data.data.profilePicture as Media | null;
        user.value = unpopulateUser(data.data);
    }

    async function fetchBuildings() {
        if (isProfile.value) {
            return;
        }
        const { data } = await getBuildings({
            fields: ['id', 'name'],
            filters: {
                $or: [
                    {
                        users: {
                            id: {
                                $in: authStore.getUser.id,
                            },
                        },
                    },
                    {
                        floors: {
                            centrals: {
                                users: {
                                    id: {
                                        $in: authStore.getUser.id,
                                    },
                                },
                            },
                        },
                    },
                ],
            },
            populate: {
                floors: {
                    fields: ['id', 'name'],
                    populate: {
                        centrals: {
                            fields: ['id', 'name'],
                            filters: {
                                $or: [
                                    {
                                        users: {
                                            id: {
                                                $in: authStore.getUser.id,
                                            },
                                        },
                                    },
                                    {
                                        floor: {
                                            building: {
                                                users: {
                                                    id: {
                                                        $in: authStore.getUser.id,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
            },
            pagination: {
                pageSize: 100,
            },
        });
        buildings.value = data.data;
    }

    async function saveUser() {
        if (!form.value) {
            return;
        }
        const { valid } = await form.value.validate();
        if (!valid) {
            return;
        }
        user.value.locale = locale.value.toUpperCase() as User_Locale;
        user.value.username = user.value.email;
        isLoading.value = true;

        try {
            if (profilePictureFile.value) {
                const { data } = await uploadFiles({
                    files: profilePictureFile.value ? [profilePictureFile.value] : [],
                });

                if (user.value) {
                    user.value.profilePicture = data[0].id;
                }
            }
        } catch (error) {
            $alert.showAlert({
                text: $error.getErrorMessage(error, 'views.users.image_upload'),
                type: 'error',
            });
            throw error;
        } finally {
            isLoading.value = false;
        }

        try {
            if (isAdding.value) {
                user.value.company = _.get(authStore.getUser, 'company.id', null);
            }
            const successMessage = isAdding.value ? t('views.users.userCreated') : t('views.users.userUpdated');
            if (!isAdding.value) {
                const id = isProfile.value ? authStore.getUser.id : $route.params.id.toString();

                await updateUser(id as string, user.value as User<false>);
                if (isProfile.value) {
                    if (isChangingPassword.value) {
                        await changePassword({
                            currentPassword: currentPassword.value,
                            password: newPassword.value,
                            passwordConfirmation: confirmNewPassword.value,
                        });
                    }
                }
            } else {
                const { data } = await createUser(user.value as User<false>);
                $router.push({ name: 'User', params: { id: data.data.id } });
            }
            $alert.showAlert({
                text: successMessage,
                type: 'success',
            });
        } catch (error) {
            $alert.showAlert({
                text: $error.getErrorMessage(error, 'views.users.error'),
                type: 'error',
            });
            throw error;
        } finally {
            isLoading.value = false;
        }
    }

    async function init() {
        isLoading.value = true;
        await Promise.all([fetchUser(), fetchBuildings(), fetchPermissions()]);
        const showEventsPermission = platformPermissions.value.find((permission) => permission.value == 'show_events');
        if (showEventsPermission && user.value?.permissions && !user.value?.permissions?.includes(showEventsPermission.id as number)) {
            user.value.permissions.push(showEventsPermission.id as number);
        }
        isLoading.value = false;
    }

    watch(
        () => $route.params.id,
        async (newId, oldId) => {
            if (newId != oldId) {
                init();
            }
        },
    );

    init();
</script>

<style>
    #input-name {
        @apply tw-text-2xl tw-font-bold;
    }
</style>
