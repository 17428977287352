<template>
    <v-dialog
        v-model="isOpen"
        max-width="420">
        <v-card
            prepend-icon="mdi-delete"
            :title="title">
            <v-card-text> {{ text }} </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="gray-200"
                    variant="flat"
                    @click="onAction('no')"
                    >{{ $t('shared.no') }}</v-btn
                >
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="onAction('yes')"
                    >{{ $t('shared.yes') }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { computed, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        modelValue: boolean;
        title?: string;
        text?: string;
    }>();

    const emit = defineEmits<{
        (e: 'update:modelValue', value: boolean): void;
        (e: 'action', value: string): void;
    }>();

    const { t } = useI18n();

    const isOpen = ref(false);

    const title = computed(() => props.title || t('components.confirmDelete.title'));
    const text = computed(() => props.text || t('components.confirmDelete.text'));

    function onAction(value: string) {
        emit('action', value);
        isOpen.value = false;
    }

    watch(
        () => props.modelValue,
        (value) => {
            isOpen.value = value;
        },
        {
            immediate: true,
        },
    );

    watch(
        () => isOpen.value,
        (value) => {
            emit('update:modelValue', value);
        },
    );
</script>

<style scoped></style>
