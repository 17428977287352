<template>
    <v-card
        class="mx-auto pa-5 h-100"
        variant="outlined"
        :class="{ 'tw-bg-green-200': isNew}"
        color="gray">
        <div class="tw-flex tw-flex-col tw-gap-4">
            <div class="tw-flex">
                <p class="tw-mb-1 tw-grow tw-text-base tw-font-medium tw-text-dark sm:tw-text-lg">{{ props.date }}</p>
                <div
                    :class="`${type.twBgColor}`"
                    class="tw-flex tw-aspect-square tw-h-full tw-max-h-[50px] tw-w-full tw-max-w-[50px] tw-items-center tw-justify-center tw-rounded-md">
                    <v-icon
                        :color="type.color"
                        :icon="type.icon"
                        size="large"></v-icon>
                </div>
            </div>

            <p class="tw-text-dark max-sm:tw-text-sm">{{ props.description }}</p>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { eventTypeDetails } from '@/config/config';
    import { PropType } from 'vue';
    import { computed } from 'vue';

    const props = defineProps({
        type: {
            type: String as PropType<keyof typeof eventTypeDetails>,
            required: true,
        },
        date: {
            type: String,
            default: 0,
        },
        description: {
            type: String,
            default: '',
        },
        isNew: {
            type: Boolean,
            default: false,
        },
    });

    const type = computed(() => {
        return eventTypeDetails[props.type];
    });
</script>

<style scoped></style>
