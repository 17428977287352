<template>
    <v-app-bar
        v-if="mdAndDown"
        color="gray-100"
        :elevation="0">
        <template v-slot:prepend>
            <v-app-bar-nav-icon
                color="dark-200"
                @click="openSidebar"></v-app-bar-nav-icon>
        </template>
        <router-link
        class="tw-flex-grow tw-pr-[58px]"
            :to="{
                name: 'Dashboard-Events',
            }">
            <v-img
                width="140"
                class="tw-mx-auto"
                src="/logo.svg"></v-img>
        </router-link>
    </v-app-bar>
</template>

<script setup lang="ts">
    import { useAppStore } from '../store/app';
    import { useDisplay } from 'vuetify';

    const appStore = useAppStore();
    const { mdAndDown } = useDisplay();

    function openSidebar() {
        appStore.setIsSidebarOpen(true);
    }
</script>

<style scoped></style>
