<template>
    <h3 class="tw-text-2xl tw-font-semibold">{{ props.floor.name }}</h3>
    <v-row>
        <v-col
            cols="12"
            lg="6"
            xl="5">
            <div class="tw-relative tw-flex tw-h-full tw-items-center tw-justify-center tw-overflow-auto tw-rounded-xl tw-border tw-border-gray-400">
                <!-- <io-img
                    max-width="750"
                    max-height="400"
                    content-type="Floor"
                    format="large"
                    :media="props.floor.blueprint">
                </io-img> -->
                <Blueprint
                    readOnly
                    class="tw-h-full tw-w-full"
                    :floor="props.floor"
                    :devices-installed="props.floor.devicesInstalled" />
                <v-btn
                    :to="{ name: 'Floor', params: { id: props.floor.id } }"
                    position="absolute"
                    class="tw-bottom-4 tw-right-4"
                    color="primary"
                    rounded="lg">
                    Ver planta
                </v-btn>
            </div>
        </v-col>
        <v-col
            cols="12"
            lg="6"
            xl="7">
            <div class="tw-grid tw-h-full tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-3 lg:tw-grid-cols-2 xl:tw-gap-8 2xl:tw-grid-cols-3">
                <v-card
                    v-for="(value, key) in props.floor.devices"
                    :key="key"
                    color="gray"
                    class="tw-h-full tw-p-6"
                    variant="outlined">
                    <div class="tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between">
                        <div class="tw-flex tw-justify-between">
                            <p class="tw-text-sm md:tw-text-base lg:tw-text-sm xl:tw-text-base">{{ getDeviceType(key)?.text || key }}</p>
                            <div>
                                <v-img
                                    class="tw-h-8 tw-w-8 md:tw-h-12 md:tw-w-12 lg:tw-h-8 lg:tw-w-8 xl:tw-h-12 xl:tw-w-12"
                                    contain
                                    :src="getDeviceType(key)?.img"></v-img>
                            </div>
                        </div>
                        <p class="tw-text-lg tw-font-medium tw-text-dark">{{ value }}</p>
                    </div>
                </v-card>
            </div>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import Blueprint from './Blueprint.vue';
    import { useConfig } from '@/composables/useConfig';
    import { BuildingFloorDevicesCount } from '@/types';

    const props = defineProps<{
        floor: BuildingFloorDevicesCount;
    }>();

    const $config = useConfig();

    function getDeviceType(deviceType: string) {
        return $config.deviceTypes.value.find((type) => type.value == deviceType);
    }
</script>

<style scoped></style>
