import { useI18n } from 'vue-i18n';

export default function useRules() {
    const i18n = useI18n();
    return {
        required(value: string | number | boolean) {
            return (value === 0 || value === '0' || !!value) || i18n.t('rules.required');
        },
        email(value: string) {
            // eslint-disable-next-line
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/i;
            return re.test(value) || i18n.t('rules.email');
        },
        min(value: any, min: number) {
            if(typeof value === 'number')
                return value >= min || i18n.t('rules.minNumber', [min]);

            return value.length >= min || i18n.t('rules.min', [min]);
        },
        max(value: any, max: number) {
            if(typeof value === 'number')
                return value <= max || i18n.t('rules.maxNumber', [max]);

            return value.length <= max || i18n.t('rules.max', [max]);
        },
        between(value: any, min: number, max: number) {
            return (value.length >= min && value.length <= max) || i18n.t('rules.between', [min, max]);
        },
        comparePassword(value: string, other: string) {
            return value === other || i18n.t('rules.comparePassword');
        },
        // check if value is a number integer
        integer(value: any) {
            return !isNaN(parseInt(value)) || i18n.t('rules.integer');
        },
        // check if value is a number decimal with 2 decimal places
        decimal(value: any) {
            return /^\d+(\.\d{1,2})?$/.test(value) || i18n.t('rules.decimal');
        },
        // check if value is a number float
        float(value: any) {
            return !isNaN(value) || i18n.t('rules.float');
        },
        // check if value is a number
        number(value: any) {
            return !isNaN(value) || i18n.t('rules.number');
        },
    };
}
