<template>
    <v-row>
        <v-col
            cols="12"
            v-if="isLoadingCount">
            <v-progress-linear
                :active="isLoadingCount"
                color="primary"
                indeterminate></v-progress-linear>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            xl="3"
            v-for="(count, type) in eventsCounts">
            <CardDeviceEvents
                :key="type"
                :type="type"
                :number="count.toString()" />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <h1 class="tw-text-xl tw-font-bold">{{ $t('views.centrals.device.events.title') }}</h1>
        </v-col>
        <v-col
            cols="12"
            v-if="isLoadingEvents">
            <v-progress-linear
                :active="isLoadingEvents"
                color="primary"
                indeterminate></v-progress-linear>
        </v-col>
        <v-col
            v-if="!isLoadingEvents && events.length"
            cols="12"
            md="6"
            xl="4"
            xxl="3"
            v-for="event in events">
            <CardDeviceHistoric
                @mouseover="event.isNew = false"
                :is-new="event.isNew"
                :type="event.type"
                :date="useDate(event.occurredAt).formattedDate"
                :description="event.description" />
        </v-col>
        <v-col
            v-else-if="!isLoadingEvents"
            class="tw-text-center"
            cols="12">
            <p>
                {{ $t('views.devices.noEvents') }}
            </p>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import CardDeviceEvents from './CardDeviceEvents.vue';
    import CardDeviceHistoric from './CardDeviceHistoric.vue';
    import { getEvents } from '@/api/events';
    import { useRouter, useRoute } from 'vue-router';
    import { ref } from 'vue';
    import { useDate } from '@/composables/useDate';
    import { APIRequestParams, Event } from '@/contentTypes';
    import { countCentralDevicesEvents } from '@/api/centrals';
    import { inject } from 'vue';
    import { Ref } from 'vue';
    import { ContentType } from '@/contentTypes';
    import { computed } from 'vue';
    import _ from 'lodash';
    import { useSocket } from '@/composables/useSocket';

    const $route = useRoute();
    const $socket = useSocket();
    const deviceInstalled = inject<Ref<ContentType<'DeviceInstalled'>>>('deviceInstalled');

    const events = ref<Event[]>([]);
    const isLoadingEvents = ref(false);
    const isLoadingCount = ref(false);

    const eventsCounts = ref({
        alarm: 0,
        failure: 0,
        information: 0,
        system: 0,
    });

    const fetchEvents = async (loading = true) => {
        isLoadingEvents.value = loading;
        // request to get events
        const query = {
            filters: {
                device: {
                    id: $route.params.id,
                },
            },
            sort: ['occurredAt:desc'],
        } as APIRequestParams<'Event'>;
        try {
            const { data } = await getEvents(query);
            if (!loading) {
                const newEvents = [];
                for (const event of data.data) {
                    const found = events.value.find((e) => e.id == event.id);
                    if (!found) {
                        event.isNew = true;
                        newEvents.push(event);
                    }
                }
                events.value = [...newEvents, ...events.value];
            } else {
                events.value = data.data;
            }
        } catch (error) {
            console.error(error);
        } finally {
            isLoadingEvents.value = false;
        }
    };

    const centralId = computed(() => _.get(deviceInstalled, 'value.zone.central.id', null));

    async function fetchDeviceEventsCount(loading = true) {
        if (!centralId.value) return;

        isLoadingCount.value = loading;

        try {
            const { data } = await countCentralDevicesEvents(centralId.value, {
                filters: {
                    id: $route.params.id,
                },
            });

            eventsCounts.value = _.get(data, 'data[0].events', {
                alarm: 0,
                failure: 0,
                information: 0,
                system: 0,
            });
        } catch (error) {
            console.error(error);
        } finally {
            isLoadingCount.value = false;
        }
    }

    fetchEvents();
    fetchDeviceEventsCount();

    $socket.emit('device:events', $route.params.id);

    $socket.on('device:events', () => {
        fetchEvents(false);
        fetchDeviceEventsCount(false);
    });
</script>

<style scoped></style>
