import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useDisplay as useVDisplay } from 'vuetify/lib/framework.mjs';
import { useQuery } from './useQuery';

export const useDisplay = (toolbarId: string, tableId?: string, gridId?: string) => {
    const { mobile: isMobile, height: displayHeight, width: displayWidth } = useVDisplay();
    const $query = useQuery();

    const toolbarHeight = ref('0px');
    const tableStart = ref(0);
    const gridStart = ref(0);

    async function onDisplayChange() {
        await nextTick();
        const toolbar = document.getElementById(toolbarId);
        if (toolbar) {
            toolbarHeight.value = toolbar.clientHeight + 'px';
        }

        if (tableId) {
            const eventsTable = document.getElementById(tableId);
            if (eventsTable) {
                tableStart.value = eventsTable.getBoundingClientRect().top || 0;
            }
        }

        if (gridId) {
            const eventsGrid = document.getElementById(gridId);
            if (eventsGrid) {
                gridStart.value = eventsGrid.getBoundingClientRect().top || 0;
            }
        }
    }
    watch([displayHeight, displayWidth, $query.isGrid], () => {
        onDisplayChange();
    });

    const tableHeight = computed(() => {
        return `${displayHeight.value - tableStart.value - 24}px`;
    });

    const gridHeight = computed(() => {
        return `${displayHeight.value - gridStart.value - 24}px`;
    });

    watch(
        isMobile,
        () => {
            if (isMobile.value && !$query.isGrid.value) {
                $query.toggleView();
            }
        },
        {
            immediate: true,
        },
    );
    onMounted(() => {
        onDisplayChange();
    });

    return {
        toolbarHeight,
        tableHeight,
        gridHeight,
    };
};
