// Utilities
import { defineStore } from 'pinia';

export const useDashboardStore = defineStore('dashboard', {
    state: () => ({
        _isDialogOpen: false,
        selectedEventsLength: 0,
    }),
    getters: {
        isDialogOpen(): boolean {
            return this._isDialogOpen;
        },
        getSelectedEventsLength(): number {
            return this.selectedEventsLength;
        },
    },
    actions: {
        setIsDialogOpen(value: boolean) {
            this._isDialogOpen = value;
        },
        setSelectedEventsLength(value: number) {
            this.selectedEventsLength = value;
        },
    },
});
