<template>
    <v-row v-show="!$query.isGrid.value">
        <v-col cols="12">
            <v-data-table-virtual
                id="dashboardBuildings"
                hover
                color="primary"
                density="comfortable"
                :no-data-text="$t('views.buildings.noBuildings')"
                :headers="headers"
                :items="buildingEvents"
                fixed-header
                @click:row="onRowClick"
                :loading="isLoading"
                :sort-by="sort"
                @update:options="onOptionsUpdated">
                <template #item.type="{ item, index }">
                    <div class="tw-flex tw-gap-x-4 tw-py-3">
                        <v-badge
                            v-for="(central, i) in eventTypes(item)"
                            :content="central.count"
                            class="tw-cursor-pointer"
                            :class="`tw-z-${eventTypes(item).length - i}`"
                            :color="central.count ? 'primary' : 'gray'"
                            @click.stop="
                                $router.push({
                                    name: 'Building-Dashboard',
                                    params: {
                                        id: item.building.id,
                                    },
                                    query: {
                                        type: central.type,
                                    },
                                })
                            "
                            :key="central.type">
                            <v-icon
                                :color="central.color"
                                :icon="central.icon"
                                :class="`${central.twBgColor} tw-rounded-md tw-p-4`"
                                size="large"></v-icon>
                        </v-badge>
                    </div>
                </template>
                <template #item.actions="{ item }">
                    <v-btn
                        :to="{
                            name: 'Building-Dashboard',
                            params: { id: item.building.id },
                        }">
                        <v-icon
                            color="primary"
                            size="x-large"
                            >mdi-chevron-right</v-icon
                        >
                    </v-btn>
                </template>
                <template #body.append>
                    <tr v-if="isLoading">
                        <td
                            :colspan="headers && headers.length ? headers.length : 1"
                            class="tw-text-center">
                            <v-progress-circular
                                indeterminate
                                color="primary"></v-progress-circular>
                        </td>
                    </tr>
                </template>
            </v-data-table-virtual>
        </v-col>
    </v-row>
    <div v-show="$query.isGrid.value">
        <v-row class="tw-my-4">
            <v-col
                cols="12"
                md="6"
                xl="4"
                xxl="3"
                v-for="building in buildingEvents"
                :key="building.building.id">
                <CardBuilding :central-events="building" />
            </v-col>
            <v-col
                v-if="isLoading"
                cols="12"
                class="tw-flex tw-justify-center">
                <v-progress-circular
                    indeterminate
                    color="primary"></v-progress-circular>
            </v-col>
            <v-col
                v-if="!buildingEvents.length && !isLoading"
                cols="12"
                class="tw-text-center">
                <p>{{ $t('views.buildings.noBuildings') }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { getBuildingEventCount } from '@/api/buildings';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import { VDataTableOptions, BuildingEventStatusCount } from '../../types';
    import { useRoute, useRouter } from 'vue-router';
    import CardBuilding from '../Buildings/CardBuilding.vue';
    import { eventTypeDetails } from '../../config/config';
    import _ from 'lodash';
    import { useI18n } from 'vue-i18n';
    import { useQuery } from '@/composables/useQuery';
    import { APIRequestParams } from '@/contentTypes';
    import { useSocket } from '@/composables/useSocket';
    import { useDisplay } from '@/composables/useDisplay';

    const { toolbarHeight: toolbarEventsHeight } = useDisplay('toolbar-dashboard');

    const { t } = useI18n();
    const $route = useRoute();
    const $router = useRouter();
    const $socket = useSocket();
    const $query = useQuery();
    const isLoading = ref(false);
    const firstUpdate = ref(true);
    const total = ref(0);
    const buildingEvents = ref<BuildingEventStatusCount[]>([]);

    const sort = ref<VDataTableOptions['sortBy']>([
        {
            key: 'name',
            order: 'asc',
        },
    ]);

    const headers = computed(
        () =>
            [
                {
                    title: t('views.events.fields.building'),
                    key: 'building.name',
                },
                {
                    title: t('views.events.title'),
                    key: 'type',
                    sortable: false,
                },
                {
                    title: '',
                    key: 'actions',
                    align: 'end',
                    sortable: false,
                },
            ] as VDataTableServer['headers'],
    );

    function eventTypes(central: BuildingEventStatusCount) {
        const eventTypes = [];
        for (const event in central.events) {
            eventTypes.push({
                type: event,
                count: central.events[event as keyof typeof central.events],
                ...eventTypeDetails[event as keyof typeof eventTypeDetails],
            });
        }

        return eventTypes;
    }

    function onOptionsUpdated(options: VDataTableOptions) {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }

        sort.value = options.sortBy;

        setRouterQuery();
    }

    function setRouterQuery() {
        const query = { ..._.cloneDeep($route.query), sort: sort.value.map((s) => `${s.key}:${s.order}`) };

        $router.replace({ query });
        // init();
    }

    function onRowClick(e: any, row: any) {
        $router.push({
            name: 'Building-Dashboard',
            params: { id: row.item.building.id },
        });
    }

    const query = computed(() => {
        return {
            sort: sort.value.map((s) => `${s.key}:${s.order}`),
        } as APIRequestParams<'Building'>;
    });

    watch(
        query,
        () => {
            init();
        },
        {
            deep: true,
        },
    );

    async function init(loading = true) {
        isLoading.value = loading;
        $socket.emit('dashboard:buildings');
        try {
            const { data } = await getBuildingEventCount(query.value);
            buildingEvents.value = data.data;
            total.value = data.data.length;
        } catch (error) {
            console.error(error);
        } finally {
            isLoading.value = false;
        }
    }

    $socket.on('dashboard:buildings', (data) => {
        init(false);
    });

    init();
</script>

<style lang="scss">
    @screen sm {
        #dashboardBuildings .v-table__wrapper {
            overflow: unset !important;
        }
        #dashboardBuildings .v-table__wrapper thead {
            top: calc(var(--v-layout-top) + v-bind(toolbarEventsHeight));
            z-index: 5;
        }
    }
</style>
