import { eventTypeDetails, eventTypeUnknown } from '@/config/config';
import { ContentType, Event_Type } from '@/contentTypes';
import _ from 'lodash';
import { RouteLocationRaw } from 'vue-router';

export function useEvent(event: ContentType<'Event'>) {
    const typeDetails = event.type && event.type in eventTypeDetails ? eventTypeDetails[event.type as Event_Type] : eventTypeUnknown;

    const originName = (function () {
        let originName = '';
        switch (event.origin) {
            case 'loop':
                originName = (event.loop as ContentType<'Loop'>)?.loopID ?? '';
                break;
            default:
                originName = (event[event.origin as 'device' | 'zone' | 'central'] as { name: string })?.name;
                break;
        }
        return originName;
    })();

    const originTo = (function () {
        let to: RouteLocationRaw = { name: 'Event' };
        switch (event.origin) {
            case 'loop':
                to = { name: 'Loop', params: { id: (event.loop as ContentType<'Loop'>)?.id } };
                break;
            case 'device':
                to = { name: 'Device', params: { id: (event.device as ContentType<'Device'>)?.id } };
                break;
            case 'zone':
                to = { name: 'Zone', params: { id: (event.zone as ContentType<'Zone'>)?.id } };
                break;
            case 'central':
                to = { name: 'Central', params: { id: (event.central as ContentType<'Central'>)?.id } };
                break;
        }
        return to;
    } as () => RouteLocationRaw)();

    const steps = (function () {
        let steps: {
            to: RouteLocationRaw;
            name: string;
        }[] = [];

        if (event.building?.name)
            steps.push({
                name: event.building?.name,
                to: {
                    name: 'Building-Dashboard',
                    params: { id: event.building?.id },
                },
            });
        if (event.floor?.name)
            steps.push({
                name: event.floor?.name,
                to: {
                    name: 'Floor',
                    params: { id: event.floor?.id },
                },
            });
        if (['loop', 'device', 'zone'].includes(event.origin as string) && event.central?.name)
            steps.push({
                name: event.central?.name,
                to: {
                    name: 'Central',
                    params: { id: event.central?.id },
                },
            });

        if (['device'].includes(event.origin as string) && event.zone?.name)
            steps.push({
                name: event.zone?.name,
                to: {
                    name: 'Zone',
                    params: { id: event.zone?.id },
                },
            });

        return steps;
    })();

    return {
        typeDetails,
        originName,
        building: event.building,
        floor: event.floor,
        central: event.central,
        zone: event.zone,
        steps,
        originTo,
    };
}
