import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { deviceTypes, yesNoOptions, languages, loopStatusDetails, centralStatus, entityTypes } from '@/config/config';
import _ from 'lodash';

export function useConfig() {
    const { t } = useI18n();
    const _yesNoOptions = computed(() => yesNoOptions.map((option) => ({ ...option, text: t(option.text) })));

    const _deviceTypes = computed(() => deviceTypes.map((option) => ({ ...option, text: t(option.text, option.text), img: new URL(`../assets/images/devices/${option.img}`, import.meta.url).href })));

    const _languages = computed(() => languages.map((option) => ({ ...option, img: new URL(`../assets/images/flags/${option.img}`, import.meta.url).href })));

    const _loopStatusDetails = computed(() => {
        const copy = _.cloneDeep(loopStatusDetails);
        for (const type in copy) {
            // @ts-ignore
            copy[type].text = t(copy[type].text);
        }
        return copy;
    });

    const getStatus = computed(() => (status: keyof typeof centralStatus) => {
        if (!centralStatus[status]) {
            console.error(`Status ${status} not found in centralStatus`);
            return null;
        }
        return {
            ...centralStatus[status],
            text: t(centralStatus[status].text),
        };
    });

    const _entityTypes = computed(() => (type: keyof typeof entityTypes) => {
        if (!entityTypes[type]) {
            console.error(`Type ${type} not found in entityTypes`);
            return null;
        }

        return {
            ...entityTypes[type],
            text: t(`${entityTypes[type].text}`),
        };
    });

    return {
        yesNoOptions: _yesNoOptions,
        deviceTypes: _deviceTypes,
        languages: _languages,
        loopStatusDetails: _loopStatusDetails,
        getStatus,
        entityTypes: _entityTypes,
    };
}
