import { VDataTableOptions } from '@/types';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { cloneDeep } from 'lodash';

export function useQuery() {
    const $route = useRoute();
    const $router = useRouter();

    const routeQuerySort = computed(() => {
        let query = [
            {
                key: 'occurredAt',
                order: 'desc',
            },
        ];
        if ($route.query.sort) {
            if (Array.isArray($route.query.sort)) {
                query = ($route.query.sort as string[]).map((s: string) => {
                    const [key, order] = s.split(':');
                    return {
                        key,
                        order,
                    };
                });
            } else {
                const [key, order] = ($route.query.sort as string).split(':');
                query = [
                    {
                        key,
                        order,
                    },
                ];
            }
        }

        return query as VDataTableOptions['sortBy'];
    });

    const isGrid = computed(() => {
        return $route.query.view == 'grid';
    });

    function toggleView() {
        const query = {
            ...cloneDeep($route.query),
            view: isGrid.value ? 'list' : 'grid',
        };
        $router.replace({ query });
    }

    return {
        routeQuerySort,
        isGrid,
        toggleView,
    };
}
