<template>
    <v-row class="tw-mt-3">
        <v-col cols="12">
            <h1 class="tw-text-xl tw-font-bold">{{ $t('views.centrals.configurations.title') }}</h1>
        </v-col>
    </v-row>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.devices.name') }} </v-label>
                <div>
                    <!-- @vue-ignore -->
                    <v-text-field
                        :disabled="isLoading || !$auth.can('edit_devices')"
                        v-model="device.name"
                        :rules="[(e) => rules.required(e)]"
                        clearable
                        type="text" />
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between"
                v-for="config in device?.configurations">
                <template v-if="config.configuration?.fieldType == 'string'">
                    <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                        {{ $t(`deviceConfigurations.${config.configuration.field}`) }}
                    </v-label>
                    <div>
                        <v-text-field
                            :disabled="isLoading || !$auth.can('edit_devices')"
                            v-model="config.value"
                            :rules="parseRules(config)"
                            clearable
                            type="text" />
                    </div>
                </template>
                <template v-else-if="config.configuration?.fieldType == 'integer'">
                    <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                        {{ $t(`deviceConfigurations.${config.configuration.field}`) }}
                    </v-label>
                    <div>
                        <v-text-field
                            :disabled="isLoading || !$auth.can('edit_devices')"
                            v-model="config.value"
                            :rules="parseRules(config)"
                            clearable
                            hide-spin-buttons
                            type="number"
                            step="1"
                            pattern="\d*" />
                    </div>
                </template>
                <template v-else-if="config.configuration?.fieldType == 'float'">
                    <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                        {{ $t(`deviceConfigurations.${config.configuration.field}`) }}
                    </v-label>
                    <div>
                        <v-text-field
                            :disabled="isLoading || !$auth.can('edit_devices')"
                            v-model="config.value"
                            :rules="parseRules(config)"
                            clearable
                            hide-spin-buttons
                            type="number" />
                    </div>
                </template>
                <template v-else-if="config.configuration?.fieldType == 'decimal'">
                    <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                        {{ $t(`deviceConfigurations.${config.configuration.field}`) }}
                    </v-label>
                    <div>
                        <v-text-field
                            :disabled="isLoading || !$auth.can('edit_devices')"
                            v-model="config.value"
                            :rules="parseRules(config)"
                            clearable
                            hide-spin-buttons
                            type="number"
                            step="any"
                            pattern="[0-9]*[.,]?[0-9]+" />
                    </div>
                </template>
                <template v-else-if="config.configuration?.fieldType == 'boolean'">
                    <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                        {{ $t(`deviceConfigurations.${config.configuration.field}`) }}
                    </v-label>
                    <div>
                        <v-btn-toggle
                            :disabled="isLoading || !$auth.can('edit_devices')"
                            mandatory
                            color="primary"
                            :rules="parseRules(config)"
                            v-model="config.value"
                            border>
                            <v-btn value="true"> {{ $t('shared.yes') }} </v-btn>
                            <v-btn value="false"> {{ $t('shared.no') }} </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-else-if="config.configuration?.fieldType == 'enumeration'">
                    <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg">
                        {{ $t(`deviceConfigurations.${config.configuration.field}`) }}
                    </v-label>
                    <div>
                        <v-select
                            :disabled="isLoading || !$auth.can('edit_devices')"
                            v-model="config.value"
                            :rules="parseRules(config)"
                            :items="parseEnums(config.configuration.fieldEnumeration)"
                            clearable
                            hide-details
                            return-object />
                    </div>
                </template>
            </v-col>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    v-if="$auth.can('edit_devices')"
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { ContentType } from '@/contentTypes';
    import { Ref } from 'vue';
    import { inject } from 'vue';
    import { useRoute } from 'vue-router';
    import useRules from '../../composables/rules';
    import { useAuth } from '@/composables/useAuth';
    import { ref } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { bulkUpdateDeviceConfigurations } from '@/api/deviceConfigurations';
    import { updateDeviceInstalled } from '@/api/devicesInstalled';
    import { useAlert } from '@/composables/useAlert';
    import useError from '@/composables/useError';
    import { useI18n } from 'vue-i18n';

    const isLoading = ref(false);
    const $route = useRoute();
    const $auth = useAuth();
    const $alert = useAlert();
    const $error = useError();
    const { t } = useI18n();
    const rules = useRules();
    const form = ref<VForm>();

    const device = inject<Ref<ContentType<'DeviceInstalled'>>>('deviceInstalled');

    function parseEnums(enums: string | undefined) {
        if (!enums) return [];
        return enums.split('\n').map((e) => e.trim());
    }

    function parseRules(config: ContentType<'DeviceConfiguration'>) {
        const _rules = [];

        if (config.configuration?.required) {
            _rules.push((value: any) => rules.required(value));
        }

        if (config.configuration?.min != null) {
            _rules.push((value: any) => rules.min(value, config.configuration?.min as number));
        }

        if (config.configuration?.max != null) {
            _rules.push((value: any) => rules.max(value, config.configuration?.max as number));
        }

        if (config.configuration?.fieldType == 'integer') {
            _rules.push((value: any) => rules.integer(value));
        }

        if (config.configuration?.fieldType == 'float') {
            _rules.push((value: any) => rules.float(value));
        }

        if (config.configuration?.fieldType == 'decimal') {
            _rules.push((value: any) => rules.decimal(value));
        }

        return _rules;
    }

    async function onSubmit() {
        if (!form.value) return;

        const { valid } = await form.value.validate();

        if (!valid) return;

        isLoading.value = true;

        try {
            if (device?.value?.configurations?.length) {
                const sanitizedConfigurations = device?.value?.configurations?.map((config) => {
                    return {
                        id: config.id,
                        value: config.value,
                    };
                });
                await bulkUpdateDeviceConfigurations(sanitizedConfigurations || []);
            }
            await updateDeviceInstalled(device?.value?.id as number, {
                name: device?.value?.name,
            });

            $alert.showAlert({
                text: t('views.centrals.updateSuccess'),
                type: 'success',
            });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                text: $error.getErrorMessage(error, 'views.centrals'),
                type: 'error',
            });
        } finally {
            isLoading.value = false;
        }
    }
</script>

<style scoped></style>
