<template>
    <v-autocomplete
        v-bind="VAutocompleteProps"
        :loading="isLoading"
        :items="items"
        no-filter
        v-model="modelValue"
        clearable
        @click:clear="onClear"
        @update:search="onSearch"
        :item-title="itemTitle"
        :item-value="itemValue"
        :multiple="isMultiple">
        <template v-slot:append-item>
            <div v-intersect.quiet="onScrollEnd" />
        </template>
    </v-autocomplete>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { has, isArray, isEqual, omit } from 'lodash';
    import { nextTick } from 'vue';
    import { ioAutocomplete } from './components';

    const props = defineProps<ioAutocomplete>();

    const isMultiple = computed(() => isArray(modelValue.value));

    const modelValue = defineModel<any>();

    const $emit = defineEmits<{
        (e: 'change', value: number | number[] | null): void;
    }>();

    const VAutocompleteProps = computed(() => omit(props, ['modelValue', 'fetchItems', 'query', 'extraData', 'selectFirst']));

    const itemTitle = computed(() => (props.itemTitle as string) || 'name');
    const itemValue = computed(() => (props.itemValue as string) || 'id');

    const items = ref<any[]>([]);
    const isLoading = ref(false);
    const pageCount = ref(0);
    const page = ref(1);
    const search = ref('');
    const timerId = ref<NodeJS.Timeout>();
    const hasData = ref(false);

    async function _fetchItems() {
        isLoading.value = true;
        try {
            const { data } = await props.fetchItems({ page: page.value, search: search.value, ids: isMultiple.value ? modelValue.value : [modelValue.value] });
            if (page.value == 1) {
                items.value = [];
                if (props.extraData) {
                    items.value.push(...props.extraData);
                }
            }
            if (has(data, 'data')) {
                items.value.push(...data.data);
                if(data.data.length > 0) {
                    hasData.value = true;
                } else {
                    hasData.value = false;
                }
            }
        } catch (error: any) {
            console.error(error);
        } finally {
            isLoading.value = false;
        }
    }

    function onScrollEnd(isIntersecting: boolean) {
        if (isIntersecting) {
            if (page.value >= pageCount.value) return;
            page.value++;
            _fetchItems();
        }
    }

    function onClear() {
        page.value = 1;
        modelValue.value = isMultiple.value ? [] : undefined;
        items.value = [];
    }

    async function onSearch(_search: string) {
        await nextTick();
        if ((isMultiple.value && modelValue.value.length > 0) || (!isMultiple.value && modelValue.value)) {
            const a = isMultiple.value ? modelValue.value : [modelValue.value];
            if ((!isMultiple.value && !_search) || items.value.some((item) => a.includes(item[itemValue.value]) && item[itemTitle.value] == _search)) return;
        }

        if (timerId.value) clearTimeout(timerId.value);
        timerId.value = setTimeout(() => {
            page.value = 1;
            search.value = _search;
            _fetchItems();
        }, 500);
    }

    async function init() {
        if ((!isMultiple.value && modelValue.value) || (isMultiple.value && modelValue.value.length > 0)) {
            const { data } = await props.fetchItems({ ids: isMultiple.value ? modelValue.value : [modelValue.value] });
            items.value = data.data;
        } else await _fetchItems();

        if (props.selectFirst && props.extraData.length > 0 && !hasData.value) {
            modelValue.value = isMultiple.value ? [items.value[0][itemValue.value]] : items.value[0][itemValue.value];
        }
    }

    watch(
        () => props.query,
        async (a, b) => {
            if (isEqual(a, b)) return;
            onClear();
            await nextTick();
            _fetchItems();
        },
    );

    watch(
        () => modelValue.value,
        (value) => {
            $emit('change', value);
        },
    );

    init();
</script>

<style scoped></style>
