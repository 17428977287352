import { getApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

export default function useNotifications() {
    async function requestPermission() {
        const token = await Notification.requestPermission()
            .then(async (permission: any) => {
                if (permission == 'granted') {
                    const token = await getTokenWeb();
                    return token;
                }
            })
            .catch((err) => {
                throw err;
            });
        return token;
    }
    async function getToken() {
        let token = null;
        try {
            token = await getTokenWeb();
        } catch (error) {
            console.error(error);
        }

        return token;
    }

    return {
        requestPermission,
        getToken,
    };
}

async function getTokenWeb() {
    const app = getApp();
    const messaging = getMessaging(app);
    const token = await getToken(messaging, { vapidKey: import.meta.env.VITE_APP_FIREBASE_VAPID_KEY });
    return token;
}
