<template>
    <div>
        <ioImg
            :draggable="true"
            @dragstart="onDragStart"
            class="tw-cursor-grab tw-rounded-2xl tw-border tw-border-gray-300 tw-select-all"
            :media="device.device?.picture"></ioImg>
        <p class="tw-mt-2 tw-break-words tw-text-center tw-text-lg tw-font-medium">{{ device.name }}</p>
    </div>
</template>

<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import { ContentType } from '@/contentTypes';

    const props = defineProps<{
        device: ContentType<'DeviceInstalled'>;
    }>();

    function onDragStart(event: DragEvent) {
        event.dataTransfer?.setData('device', JSON.stringify(props.device));
    }
</script>

<style scoped></style>
