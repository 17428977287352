<template>
    <v-card
        height="100%"
        @click="onClick"
        class="mx-auto pa-5 tw-flex tw-flex-col"
        variant="outlined"
        color="gray">
        <div class="tw-flex tw-justify-between tw-gap-1 tw-text-gray">
            <div class="tw-flex tw-flex-col tw-gap-y-2">
                <h1 class="tw-font-medium tw-text-dark">{{ originName }}</h1>
                <p
                    v-show="buildingName"
                    class="tw-text-xs tw-font-medium">
                    {{ buildingName }}
                </p>
                <p
                    v-show="description"
                    class="tw-text-xs tw-font-medium">
                    {{ description }}
                </p>
            </div>
            <v-icon
                :color="typeDetails.color"
                :icon="typeDetails.icon"
                :class="`tw-bg-${typeDetails.bgColor} tw-rounded-md tw-p-5`"
                size="x-large"></v-icon>
        </div>
        <div class="tw-mt-auto tw-flex tw-items-center tw-justify-end tw-pt-6">
            <p class="tw-text-right tw-text-sm tw-text-gray">{{ date }}</p>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { Event } from '../../contentTypes';
    import { computed } from 'vue';
    import { useDate } from '@/composables/useDate';
    import _ from 'lodash';
    import { useEvent } from '@/composables/useEvent';

    const props = defineProps<{
        event: Event;
    }>();

    const $emit = defineEmits<{
        (e: 'click', event: Event): void;
    }>();

    const date = computed(() => {
        return useDate(props.event.occurredAt as Date).formattedDate;
    });

    const originName = computed(() => {
        return useEvent(props.event).originName || '';
    });

    const zoneName = computed(() => {
        return _.get(props.event, 'zone.name', '');
    });

    const buildingName = computed(() => {
        return _.get(props.event, 'building.name', '');
    });

    const description = computed(() => {
        return _.get(props.event, 'description', '');
    });

    const typeDetails = computed(() => {
        return useEvent(props.event).typeDetails;
    });

    function onClick() {
        $emit('click', props.event);
    }
</script>

<style scoped></style>
