<template>
    <v-navigation-drawer
        width="400"
        location="right"
        class="tw-bg-gray-100 tw-p-10 tw-pt-12 tw-select-none"
        floating
        v-model="modelValue">
        <div class="tw-flex tw-flex-col tw-gap-4">
            <div class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-xl tw-font-semibold">{{ $t('fields.devices') }}</h1>
                <v-btn
                    @click="modelValue = false"
                    color="gray-300"
                    density="comfortable"
                    class="rounded-lg tw-text-dark tw-shadow-none"
                    icon="mdi-close"></v-btn>
            </div>
        </div>
        <!-- grid of 2 columns -->
        <div class="tw-mt-4 tw-grid tw-grid-cols-2 tw-gap-4 ">
            <CardDevice
                v-for="device in blueprintStore.getDevicesForSidebar"
                :device="device"
                :key="device.id"></CardDevice>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import CardDevice from './CardDevice.vue';
    import { useBlueprintStore } from '@/store/blueprint';

    const modelValue = defineModel({
        default: false,
    });
    const blueprintStore = useBlueprintStore();
</script>

<style scoped></style>
