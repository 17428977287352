<template>
    <v-row v-show="!$query.isGrid.value">
        <v-col cols="12">
            <v-data-table-virtual
                id="dashboardEventsTable"
                hover
                color="primary"
                density="comfortable"
                :no-data-text="$t('views.events.noEvents')"
                :headers="headers"
                :items="items"
                fixed-header
                @click:row="onRowClick"
                :row-props="rowProps"
                :loading="isLoading"
                @update:options="onOptionsUpdated">
                <template #item.name="{ item }">
                    {{ useEvent(item).originName }}
                </template>
                <template #item.building.name="{ item, index }">
                    {{ useEvent(item).building?.name }}
                    <span
                        v-if="index + 1 == items.length - Math.round(pageSize / 2)"
                        v-intersect="onIntersect"></span>
                </template>
                <template #item.occurredAt="{ item }">
                    {{ useDate(item.occurredAt as Date).formattedDate }}
                </template>
                <template #item.type="{ item }">
                    <v-icon
                        :color="useEvent(item).typeDetails.color"
                        size="x-large"
                        :class="`tw-bg-${useEvent(item).typeDetails.bgColor} tw-my-1 tw-rounded-md tw-p-5`">
                        {{ useEvent(item).typeDetails.icon }}
                    </v-icon>
                </template>
                <template #body.append>
                    <tr v-if="isLoading || (page != pageCount && pageCount != 0)">
                        <td
                            :colspan="headers && headers.length ? headers.length : 1"
                            class="tw-text-center">
                            <v-progress-circular
                                indeterminate
                                color="primary"></v-progress-circular>
                        </td>
                    </tr>
                </template>
            </v-data-table-virtual>
        </v-col>
    </v-row>
    <div v-show="$query.isGrid.value">
        <v-row
            id="dashboardEventsGrid"
            class="tw-my-4">
            <v-col
                cols="12"
                md="6"
                xl="4"
                xxl="3"
                v-for="(event, index) in events"
                :key="event.id">
                <span
                    v-if="index + 1 == items.length - Math.round(pageSize / 2)"
                    v-intersect="onIntersect"></span>
                <CardEvent
                    @click="onEdit(event)"
                    :event="event" />
            </v-col>
            <v-col
                v-if="isLoading || (page != pageCount && pageCount != 0)"
                cols="12"
                class="tw-flex tw-justify-center">
                <v-progress-circular
                    indeterminate
                    color="primary"></v-progress-circular>
            </v-col>
            <v-col
                cols="12"
                v-if="!events.length && !isLoading">
                <p class="tw-text-center">{{ $t('views.events.noEvents') }}</p>
            </v-col>
        </v-row>
    </div>
    <AcknowledgeEvent
        @success="init"
        v-model="isDialogOpen"
        :events="selectedEvents" />
</template>

<script setup lang="ts">
    import { APIRequestParams, ContentType } from '@/contentTypes';
    import { ref, computed } from 'vue';
    import { getEvents } from '@/api/events';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import AcknowledgeEvent from '@/views/Events/AcknowledgeEvent.vue';
    import { useEvent } from '@/composables/useEvent';
    import { useDate } from '@/composables/useDate';
    import { VDataTableOptions } from '@/types';
    import { useRoute, useRouter } from 'vue-router';
    import CardEvent from '@/views/Events/CardEvent.vue';
    import { pageSizes } from '@/config/config';
    import _ from 'lodash';
    import { watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useDashboardStore } from '@/store/dashboard';
    import { useQuery } from '@/composables/useQuery';
    import { useSocket } from '@/composables/useSocket';
    import { useDisplay } from '@/composables/useDisplay';
    import { useTable } from '@/composables/useTable';

    useTable('dashboardEventsTable');
    const { t } = useI18n();
    const $route = useRoute();
    const $router = useRouter();
    const $query = useQuery();
    const $socket = useSocket();
    const dashboardStore = useDashboardStore();
    const {
        toolbarHeight: toolbarDashboardHeight,
        tableHeight: dashboardEventsTableHeight,
        gridHeight: dashboardEventsGridHeight,
    } = useDisplay('toolbar-dashboard', 'dashboardEventsTable', 'dashboardEventsGrid');

    const isLoading = ref(false);
    const firstUpdate = ref(true);
    const page = ref($route.query?.page ? Number($route.query.page) : 1);
    const pageSize = ref($route.query?.pageSize ? Number($route.query.pageSize) : pageSizes[2]);
    const pageCount = ref(0);
    const events = ref<
        (ContentType<'Event'> & {
            isNew?: boolean;
        })[]
    >([]);
    const isDialogOpen = ref(false);
    const selectedEvents = ref<ContentType<'Event'>[]>([]);

    const items = computed(() => {
        return events.value;
    });

    const sort = ref<VDataTableOptions['sortBy']>($query.routeQuerySort.value);

    const headers = computed(
        () =>
            [
                // {
                //     title: 'ID',
                //     key: 'id',
                // },
                {
                    title: t('views.events.fields.date'),
                    key: 'occurredAt',
                },
                {
                    title: t('views.events.fields.type'),
                    key: 'type',
                    align: 'center',
                    sortable: false,
                },
                {
                    title: t('views.events.fields.building'),
                    key: 'building.name',
                },
                {
                    title: t('views.events.fields.name'),
                    key: 'name',
                    sortable: false,
                },
                {
                    title: t('views.events.fields.description'),
                    key: 'description',
                },
            ] as VDataTableServer['headers'],
    );

    const typeFilters = computed(() => {
        if ($route.query.type) {
            if (Array.isArray($route.query.type)) {
                return $route.query.type as string[];
            } else {
                return [$route.query.type as string];
            }
        }
        return [];
    });

    const query = computed(
        () =>
            ({
                filters: {
                    inProgress: true,
                    type: {
                        $in: typeFilters.value,
                    },
                },
                populate: {
                    zone: {
                        fields: ['name'],
                    },
                    central: {
                        fields: ['name'],
                    },
                    device: {
                        fields: ['name'],
                    },
                    loop: {
                        fields: ['loopID'],
                    },
                    building: {
                        fields: ['name'],
                    },
                    floor: {
                        fields: ['name'],
                    },
                },
                fields: ['type', 'origin', 'occurredAt', 'message', 'description'],
                pagination: {
                    page: page.value,
                    pageSize: pageSize.value,
                },
                sort: sort.value.map((s) => `${s.key}:${s.order}`),
            }) as APIRequestParams<'Event'>,
    );

    function onEdit(item: ContentType<'Event'>) {
        selectedEvents.value = [item];
        isDialogOpen.value = true;
    }

    function onOptionsUpdated(options: VDataTableOptions) {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }

        page.value = options.page;
        sort.value = options.sortBy;

        setRouterQuery();
    }

    function setRouterQuery() {
        const query = { ..._.cloneDeep($route.query), page: page.value, pageSize: pageSize.value, sort: sort.value.map((s) => `${s.key}:${s.order}`) };

        $router.replace({ query });
    }

    function onRowClick(event: Event, row: any) {
        onEdit(row.item);
    }

    function onIntersect(isIntersecting: boolean) {
        if (!isIntersecting) return;
        console.log('Intersecting');

        if (page.value < pageCount.value) {
            page.value++;
        }
    }

    watch(
        query,
        (newQuery, oldQuery) => {
            if (!_.isEqual(newQuery, oldQuery)) {
                let showLoading = false;
                if (!_.isEqual(_.omit(newQuery, 'pagination.page'), _.omit(oldQuery, 'pagination.page'))) {
                    showLoading = true;
                    page.value = 1;
                    events.value = [];
                }
                init(showLoading);
            }
        },
        {
            deep: true,
            flush: 'post',
        },
    );

    watch(isDialogOpen, (newVal) => {
        if (newVal == dashboardStore.isDialogOpen) return;
        dashboardStore.setIsDialogOpen(newVal);
    });

    watch(
        () => selectedEvents.value.length,
        (newVal) => {
            dashboardStore.setSelectedEventsLength(newVal);
        },
    );

    watch(
        () => dashboardStore.isDialogOpen,
        (newVal) => {
            if (newVal == isDialogOpen.value) return;
            isDialogOpen.value = newVal;
        },
    );

    async function init(loading = true, fromSocket = false) {
        isLoading.value = loading;
        $socket.emit('dashboard:events', {
            type: typeFilters.value,
        });
        try {
            const { data } = await getEvents(query.value);

            if (fromSocket) {
                const newEvents = [];
                for (const event of data.data) {
                    const found = events.value.find((e) => e.id == event.id);
                    if (!found) {
                        event.isNew = true;
                        newEvents.push(event);
                    }
                }

                events.value.unshift(...newEvents);
            } else {
                events.value.push(...data.data);
            }

            pageCount.value = data.meta.pagination.pageCount;
        } catch (error) {
            console.error(error);
        } finally {
            isLoading.value = false;
        }
    }

    function rowProps({
        item,
    }: {
        item: ContentType<'Event'> & {
            isNew?: boolean;
        };
    }) {
        return {
            attr: item.isNew ? 'isNew' : undefined,
        } as VDataTableServer['$props']['rowProps'];
    }

    init();

    $socket.on('dashboard:events', (data) => {
        init(false, true);
    });
</script>

<style lang="scss">
    .v-data-table__tr[attr='isNew'] {
        background-color: rgb(var(--v-theme-green-200));
    }

    @screen sm {
        #dashboardEventsTable .v-table__wrapper {
            overflow: unset !important;
        }
        #dashboardEventsTable .v-table__wrapper thead {
            top: calc(var(--v-layout-top) + v-bind(toolbarDashboardHeight));
            z-index: 1;
        }
    }
</style>
