/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue';
import '@vuepic/vue-datepicker/dist/main.css';
import '@/assets/css/main.scss';
import "flag-icons/css/flag-icons.min.css";

// Composables
import { createApp } from 'vue';

// Plugins
import { registerPlugins } from '@/plugins';

// FIREBASE
import { initializeApp } from 'firebase/app';
if (import.meta.env.VITE_APP_FIREBASE_API_KEY) {
    initializeApp({
        apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGE_SENDER_ID,
        appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
    });
}

const app = createApp(App);

registerPlugins(app);

app.mount('#app');
