<template>
    <div
        v-if="device"
        class="tw-mt-8 tw-flex tw-flex-col tw-items-center tw-gap-8 xl:tw-flex-row xl:tw-items-start">
        <div class="tw-w-full tw-max-w-80">
            <ioImg
                class="tw-rounded-2xl tw-border tw-border-gray-300"
                :media="device.device?.picture"
                :aspect-ratio="1" />
        </div>

        <v-row
            align-content="start"
            class="tw-w-full">
            <v-col
                cols="12"
                sm="6">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.device.info.fields.type') }} </v-label>
                <div>
                    <v-text-field
                        hide-details="auto"
                        background-color="primary"
                        readonly
                        v-model="type"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.device.info.fields.model') }} </v-label>
                <div>
                    <v-text-field
                        hide-details="auto"
                        background-color="primary"
                        v-model="model"
                        readonly></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.device.info.fields.address') }} </v-label>
                <div>
                    <v-text-field
                        hide-details="auto"
                        background-color="primary"
                        v-model="physicalAddress"
                        readonly></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6">
                <v-label class="tw-mb-1 tw-whitespace-normal tw-text-dark tw-opacity-100 md:tw-text-lg"> {{ $t('views.centrals.device.info.fields.zone') }} </v-label>
                <div>
                    <v-text-field
                        hide-details="auto"
                        background-color="primary"
                        readonly
                        v-model="zone"></v-text-field>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import { ContentType } from '@/contentTypes';
    import { computed } from 'vue';
    import { ref } from 'vue';
    import { inject } from 'vue';
    import { Ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import _ from 'lodash';

    const { t } = useI18n();

    const device = inject<Ref<ContentType<'DeviceInstalled'>>>('deviceInstalled');

    const type = computed(() => {
        return t(
            `deviceTypes.${device?.value?.device?.type}`,
            { count: 1 },
            {
                default: _.get(device, 'value.device.type'),
            },
        );
    });
    const model = ref(device?.value?.device?.model || '');
    const physicalAddress = ref(device?.value?.physicalAddress || '');
    const zone = ref(device?.value?.zone?.name || '');
</script>

<style scoped></style>
