<template>
    <h1 class="auth-title">{{ $t('auth.accountConfirmation') }}</h1>
    <v-form
        ref="form"
        @submit.prevent="onSubmit"
        class="auth-form">
        <template v-if="alertType != 'success' && showPassword">
            <p class="tw-text-center">
                {{ $t('auth.createPassword') }}
            </p>

            <v-text-field
                v-model="password"
                :readonly="isLoading"
                class="auth-input"
                name="new-password"
                autocomplete="new-password"
                :rules="[$rules.required, (e) => $rules.min(e, 6)]"
                :type="isPasswordVisible ? 'text' : 'password'"
                :placeholder="$t('fields.password')"
                :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append-inner="isPasswordVisible = !isPasswordVisible"></v-text-field>
            <v-text-field
                v-model="passwordConfirmation"
                class="auth-input"
                name="new-password"
                autocomplete="new-password"
                :readonly="isLoading"
                :rules="[$rules.required, (e) => $rules.comparePassword(e, password), (e) => $rules.min(e, 6)]"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append-inner="isPasswordVisible = !isPasswordVisible"
                :placeholder="$t('fields.confirmPassword')"></v-text-field>
        </template>

        <div v-if="alertMessage">
            <v-alert
                :type="alertType"
                variant="tonal"
                density="compact"
                >{{ alertMessage }}</v-alert
            >
        </div>
        <div v-if="isLoading && !showPassword" class="tw-flex tw-justify-center">
            <v-progress-circular
                indeterminate
                color="primary"></v-progress-circular>
        </div>

        <v-btn
            v-if="alertType != 'success' && showPassword"
            :loading="isLoading"
            type="submit"
            color="primary"
            class="auth-btn">
            {{ $t('auth.confirm') }}
        </v-btn>

        <router-link
            v-if="alertMessage && alertType == 'error'"
            class="tw-text-center tw-underline"
            :to="{ name: 'Login' }"
            >{{ $t('auth.backToLogin') }}</router-link
        >
    </v-form>
</template>

<script setup lang="ts">
    import axios from '@axios';
    import { computed, onMounted, ref } from 'vue';
    import _ from 'lodash';
    import { useI18n } from 'vue-i18n';
    import { useRoute, useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/auth';
    import qs from 'qs';
    import { useError } from '@/composables/useError';
    import useRules from '../../composables/rules';
    import { VForm } from 'vuetify/lib/components/index.mjs';

    const $t = useI18n().t;
    const $route = useRoute();
    const $router = useRouter();
    const authStore = useAuthStore();
    const $error = useError();
    const $rules = useRules();

    const isLoading = ref(false);
    const form = ref<VForm>();

    const alertMessage = ref('');
    const alertType = ref<'success' | 'error'>('error');

    const isPasswordVisible = ref(false);
    const password = ref('');
    const passwordConfirmation = ref('');

    const showPassword = computed(() => {
        const token = _.get($route.query, 'confirmation_token', '') as string;
        if (!token) return false;
        return !token.startsWith('nop');
    });

    async function onSubmit() {
        if (!form.value) return;

        const { valid } = await form.value.validate();

        if (!valid) return;

        alertMessage.value = '';
        isLoading.value = true;

        try {
            let token = $route.query.confirmation_token as string;
            if (token.startsWith('nop')) {
                token = token.replace('nop', '');
            }
            const query = {
                confirmation: token,
            };
            const { data } = await axios.post(`/auth/email-confirmation?${qs.stringify(query)}`, {
                ...(showPassword.value && { password: password.value }),
            });
            authStore.setToken(data.jwt);
            authStore.setUser(data.user);
            alertType.value = 'success';
            alertMessage.value = $t('auth.accountConfirmationSuccess');

            setTimeout(() => {
                $router.push('/');
            }, 2000);
        } catch (error) {
            alertType.value = 'error';
            alertMessage.value = $error.getErrorMessage(error, 'auth.errors');
        } finally {
            isLoading.value = false;
        }
    }

    onMounted(() => {
        if (!showPassword.value) {
            onSubmit();
        }
    });
</script>

<style scoped></style>
