<template>
    <v-card
        color="gray"
        variant="outlined"
        :to="{
            name: 'Device-Events',
            params: { id: device.id },
        }"
        class="mx-auto tw-p-8 tw-h-full">
        <div class="tw-flex tw-flex-col tw-justify-between tw-gap-2 tw-text-center tw-h-full">
            <p class="tw-text-xl tw-font-semibold tw-text-dark">{{ device.name }}</p>
            <p class="tw-text-dark-200">
                {{ $t(`deviceTypes.${device.device?.type}`) }}
            </p>
            <div class="tw-mt-8 tw-flex tw-justify-center tw-gap-3">
                <v-badge
                    v-for="(event, i) in eventTypes"
                    :content="event.count"
                    :color="event.count ? 'primary' : 'gray'"
                    :class="`tw-z-${eventTypes.length - i}`"
                    :key="event.type">
                    <v-icon
                        :color="event.color"
                        :icon="event.icon"
                        :class="`${event.twBgColor} tw-rounded-md tw-p-5`"
                        size="large"></v-icon>
                </v-badge>
            </div>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { CentralDevicesEventsCount } from '@/types';
    import { computed } from 'vue';
    import { eventTypeDetails } from '@/config/config';

    const props = defineProps<{
        device: CentralDevicesEventsCount;
    }>();

    const eventTypes = computed(() => {
        const eventTypes = [];
        for (const event in props.device.events) {
            eventTypes.push({
                type: event,
                count: props.device.events[event as keyof typeof props.device.events] as number,
                ...eventTypeDetails[event as keyof typeof eventTypeDetails],
            });
        }

        return eventTypes;
    });
</script>

<style scoped></style>
