<template>
    <v-navigation-drawer
        mobile-breakpoint="lg"
        class="tw-bg-gray-100 tw-p-4"
        floating
        v-model="isOpen">
        <v-btn-group
            v-if="appStore.isPWA"
            class="tw-w-full max-lg:tw-hidden">
            <v-btn
                @click="$router.back()"
                :color="$route.meta.canGoBack ? 'primary' : 'gray-300'"
                :readonly="!$route.meta.canGoBack"
                class="tw-grow tw-text-xl"
                size="small"
                icon="mdi-chevron-left">
            </v-btn>
            <v-btn
                @click="$router.forward()"
                :color="$route.meta.canGoForward ? 'primary' : 'gray-300'"
                :readonly="!$route.meta.canGoForward"
                class="tw-grow tw-text-xl"
                size="small"
                icon="mdi-chevron-right">
            </v-btn>
        </v-btn-group>
        <v-list-item>
            <router-link
                :to="{
                    name: 'Dashboard-Events',
                }">
                <v-img
                    class="logo tw-m-auto"
                    width="120"
                    aspect-ratio="1"
                    src="/logo.svg"></v-img>
            </router-link>
        </v-list-item>

        <v-divider></v-divider>
        <v-list
            density="compact"
            nav>
            <v-list-item
                :title="$t('views.dashboard.title')"
                color="primary"
                :to="{ name: 'Dashboard' }"></v-list-item>
            <v-list-item
                :title="$t('views.events.title')"
                color="primary"
                :to="{ name: 'Events' }"></v-list-item>
            <v-list-group
                v-for="building in buildings"
                class="building-group"
                :value="building.id"
                :key="building.id">
                <template v-slot:activator="{ props, isOpen }">
                    <v-list-item
                        color="primary"
                        :active="isBuildingActive(building)">
                        <template #title>
                            <router-link :to="{ name: 'Building', params: { id: building.id } }">{{ building.name }}</router-link>
                        </template>
                        <template #append>
                            <v-icon
                                :data-attr="building.id"
                                ref="buildingListActivator"
                                @click="props.onClick"
                                class="tw-text-primary"
                                >{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
                            >
                        </template>
                    </v-list-item>
                </template>
                <v-list-item
                    v-for="central in building.centrals"
                    color="primary"
                    :key="central.id"
                    :to="{ name: 'Central', params: { id: central.id } }"
                    :active="isCentralActive(central)"
                    :title="central.name"></v-list-item>
            </v-list-group>
            <v-list-item
                v-if="$auth.can('add_users') || $auth.can('remove_users')"
                :to="{ name: 'Users' }"
                color="primary"
                :title="$t('views.users.title')"></v-list-item>
        </v-list>
        <template v-slot:append>
            <div class="tw-flex tw-items-center tw-justify-between">
                <div
                    id="locale-select"
                    class="tw-flex tw-w-20 tw-items-center tw-justify-center tw-pl-2">
                    <v-select
                        @update:model-value="setLocale"
                        :model-value="locale"
                        density="compact"
                        :items="$i18n.availableLocales">
                        <template v-slot:selection="{ item }">
                            <span
                                class="fi tw-w-8"
                                :class="`fi-${getFlag(item.value)}`"></span>
                        </template>
                        <template v-slot:item="{ item, props }">
                            <v-list-item
                                v-bind="props"
                                title="">
                                <span
                                    class="fi tw-w-8"
                                    :class="`fi-${getFlag(item.value)}`"></span>
                            </v-list-item>
                        </template>
                    </v-select>
                </div>
                <v-btn
                    :to="{ name: 'AddCentral' }"
                    color="primary"
                    prepend-icon="mdi-plus"
                    >Central</v-btn
                >
            </div>

            <div class="tw-flex tw-items-center tw-gap-2 tw-p-2">
                <v-avatar size="36">
                    <ioImg
                        :media="profilePicture"
                        class="tw-rounded-full tw-border tw-border-gray-300"
                        format="thumbnail"
                        aspect-ratio="1" />
                </v-avatar>
                <router-link
                    :to="{ name: 'Profile' }"
                    class="tw-flex-grow tw-text-sm tw-font-medium tw-text-dark">
                    {{ userName }}
                </router-link>
                <v-btn
                    @click="logout"
                    variant="text"
                    icon="mdi-logout"
                    color="dark">
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { ref, watch, onBeforeUnmount, computed, nextTick, onMounted, watchEffect } from 'vue';
    import { useAppStore } from '../store/app';
    import { useAuthStore } from '@/store/auth';
    import { useRoute, useRouter } from 'vue-router';
    import { useDisplay } from 'vuetify';
    import { getBuildings } from '@/api/buildings';
    import { ContentType } from '../contentTypes';
    import { useConfig } from '../composables/useConfig';
    import { useLocale } from '../composables/useLocale';
    import { useAuth } from '../composables/useAuth';
    import _ from 'lodash';
    import ioImg from '../components/ioImg.vue';
    import { VIcon, VListGroup, VListItem } from 'vuetify/lib/components/index.mjs';
    import { useI18n } from 'vue-i18n';

    const { getLocaleMessage, locale } = useI18n();
    const $locale = useLocale();
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const $router = useRouter();
    const $route = useRoute();
    const $config = useConfig();
    const $auth = useAuth();

    type BuildingCentrals = {
        id: number;
        name: string;
        centrals: {
            id: number;
            name: string;
            devices: {
                id: number;
            }[];
            zones: {
                id: number;
            }[];
            loops: {
                id: number;
            }[];
        }[];
        floors: {
            id: number;
        }[];
    };

    const buildingListActivator = ref<VIcon[]>([]);

    const buildings = ref<BuildingCentrals[]>([]);
    const { mdAndDown } = useDisplay();
    const wasMounted = ref(false);
    const wasFetched = ref(false);

    const isOpen = ref(mdAndDown.value ? false : true);

    async function logout() {
        authStore.$reset();
        $router.push({ name: 'Login' });
    }

    function getFlag(locale: string) {
        const messages = getLocaleMessage(locale);

        return messages.flag ?? locale;
    }

    function setLocale(loc: string | null) {
        if (loc) locale.value = loc;
    }

    function isBuildingActive(building: BuildingCentrals) {
        return $route.matched.some((route) => {
            return (
                (route.name === 'Building' && $route.params.id === building.id?.toString()) ||
                (route.name === 'Central' && building.centrals.some((central) => central.id?.toString() === $route.params.id)) ||
                (route.name === 'Floor' && building.floors.some((floor) => floor.id?.toString() === $route.params.id)) ||
                (route.name === 'Zone' && building.centrals.some((central) => central.zones.some((zone) => zone.id?.toString() === $route.params.id))) ||
                (route.name === 'Loop' && building.centrals.some((central) => central.loops.some((loop) => loop.id?.toString() === $route.params.id))) ||
                (route.name === 'Device' && building.centrals.some((central) => central.devices.some((device) => device.id?.toString() === $route.params.id)))
            );
        });
    }

    function isCentralActive(central: BuildingCentrals['centrals'][0]) {
        return $route.matched.some((route) => {
            return (
                (route.name === 'Central' && $route.params.id === central.id?.toString()) ||
                (route.name === 'Zone' && central.zones.some((zone) => zone.id?.toString() === $route.params.id)) ||
                (route.name === 'Loop' && central.loops.some((loop) => loop.id?.toString() === $route.params.id)) ||
                (route.name === 'Device' && central.devices.some((device) => device.id?.toString() === $route.params.id))
            );
        });
    }

    async function init() {
        const { data } = await getBuildings({
            fields: ['id', 'name'],
            sort: ['name:asc'],
            filters: {
                $or: [
                    {
                        users: {
                            id: {
                                $in: authStore.getUser.id,
                            },
                        },
                    },
                    {
                        floors: {
                            centrals: {
                                users: {
                                    id: {
                                        $in: authStore.getUser.id,
                                    },
                                },
                            },
                        },
                    },
                ],
            },
            populate: {
                floors: {
                    fields: ['id'],
                    populate: {
                        centrals: {
                            fields: ['id', 'name'],
                            filters: {
                                $or: [
                                    {
                                        users: {
                                            id: {
                                                $in: authStore.getUser.id,
                                            },
                                        },
                                    },
                                    {
                                        floor: {
                                            building: {
                                                users: {
                                                    id: {
                                                        $in: authStore.getUser.id,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                ],
                            },
                            populate: {
                                zones: {
                                    fields: ['id'],
                                },
                                loops: {
                                    fields: ['id'],
                                    populate: {
                                        devices: {
                                            fields: ['id'],
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
            pagination: {
                pageSize: 100,
            },
        });

        // @ts-ignore
        buildings.value = data.data.map((building: ContentType<'Building'>) => {
            return {
                id: building.id,
                name: building.name,
                floors: building.floors?.map((floor: ContentType<'Floor'>) => {
                    return {
                        id: floor.id as number,
                    };
                }),
                centrals: building.floors?.flatMap((floor: ContentType<'Floor'>) => {
                    return floor.centrals?.map((central: ContentType<'Central'>) => {
                        return {
                            id: central.id,
                            name: central.name,
                            devices: central.loops?.flatMap((loop: ContentType<'Loop'>) => {
                                // @ts-ignore
                                return loop.devices?.map((device: ContentType<'Device'>) => {
                                    return {
                                        id: device.id as number,
                                    };
                                });
                            }),
                            zones: central.zones?.map((zone: ContentType<'Zone'>) => {
                                return {
                                    id: zone.id,
                                };
                            }),
                            loops: central.loops?.map((loop: ContentType<'Loop'>) => {
                                return {
                                    id: loop.id,
                                };
                            }),
                        };
                    });
                }),
            };
        });

        wasFetched.value = true;
        openBuilding();
    }

    const profilePicture = computed(() => {
        return authStore.getUser?.profilePicture;
    });

    const userName = computed(() => {
        return authStore.getUser?.name;
    });

    const activeBuilding = computed(() => {
        return buildings.value.find((building) => isBuildingActive(building));
    });

    const activeCentral = computed(() => {
        return activeBuilding.value?.centrals.find((central) => isCentralActive(central));
    });

    function openBuilding() {
        if (!wasMounted.value || !wasFetched.value) return;
        buildingListActivator.value.forEach((group) => {
            if (group.$attrs['data-attr'] == activeBuilding.value?.id) {
                group.$el.click();
            }
        });
    }

    init();

    watch(
        () => appStore.getSidebarTrigger,
        () => {
            init();
        },
    )

    watch(
        () => appStore.isSidebarOpen,
        (value) => {
            isOpen.value = value;
        },
    );

    watch(
        () => isOpen.value,
        (value) => {
            appStore.setIsSidebarOpen(value);
        },
    );

    onMounted(() => {
        wasMounted.value = true;
        openBuilding();
    });

    onBeforeUnmount(() => {
        appStore.setIsSidebarOpen(false);
    });
</script>

<style lang="scss">
    .logo img,
    .v-navigation-drawer__append img {
        display: block !important;
    }

    .building-group .v-list-item-title {
        display: flex;
    }

    .building-group a {
        width: 100%;
        line-height: 2rem;
    }

    #locale-select .v-input__control > .v-field {
        @apply tw-flex-none tw-bg-transparent tw-p-0 #{!important};
    }
    #locale-select .v-input__control .v-field__input {
        @apply tw-p-0 #{!important};
    }
    #locale-select .v-input__control .v-field__outline {
        @apply tw-hidden #{!important};
    }

    #locale-select .v-input__control i.mdi {
        @apply tw-m-0 #{!important};
    }
</style>
