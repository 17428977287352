<template>
    <v-card
        color="gray"
        variant="outlined"
        class="tw-p-8">
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4">
            <ioImg
                :media="user.profilePicture"
                aspect-ratio="1"
                format="medium"
                width="100%"
                class="tw-max-w-28" />
            <p class="tw-text-lg tw-font-semibold tw-text-dark">{{ props.user.name }}</p>
            <p class="tw-font-medium tw-text-dark-200">{{ props.user.email }}</p>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { User } from '@/contentTypes';
    import ioImg from '@/components/ioImg.vue';

    const props = defineProps<{
        user: User;
    }>();
</script>

<style scoped></style>
