/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
// import 'vuetify/styles';
import { pt, en } from 'vuetify/locale';
// Composables
import { createVuetify } from 'vuetify';
import { flatColors } from '../../theme';
import type { VAlert, VBreadcrumbs, VTextarea, VAutocomplete, VTextField, VBanner, VBtn, VDataTable, VDataTableServer, VSelect, VCheckbox } from 'vuetify/lib/components/index.mjs';

import 'vuetify/styles';
import { pageSizes } from '@/config/config';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    defaults: {
        VTextField: {
            density: 'compact',
            hideDetails: 'auto',
            variant: 'outlined',
            bgColor: 'gray-50',
        } as VTextField['$props'],
        VBtn: {
            elevation: 0,
            color: 'gray-50',
        } as VBtn['$props'],
        VDataTable: {
            itemsPerPageOptions: pageSizes,
            itemsPerPage: pageSizes[0],
        } as VDataTable['$props'],
        VDataTableServer: {
            itemsPerPageOptions: pageSizes,
            itemsPerPage: pageSizes[0],
            itemsLength: 0,
        } as VDataTableServer['$props'],
        VAutocomplete: {
            hideDetails: 'auto',
            density: 'compact',
            variant: 'outlined',
            bgColor: 'gray-50',
        } as VAutocomplete['$props'],
        VSelect: {
            hideDetails: 'auto',
            density: 'compact',
            variant: 'outlined',
            bgColor: 'gray-50',
        } as VSelect['$props'],
        VBreadcrumbs: {
            divider: '>',
        } as VBreadcrumbs['$props'],
        VCheckbox: {
            hideDetails: 'auto',
            density: 'compact',
        } as VCheckbox['$props'],
    },
    locale: {
        locale: 'pt',
        fallback: 'en',
        messages: { pt, en },
    },
    display: {
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            xxl: 1536,
        },
    },
    theme: {
        themes: {
            light: {
                colors: flatColors(),
            },
        },
    },
});
