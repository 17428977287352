<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">{{ central?.name }}</h1>

                <p
                    :class="[statusDetails?.twBgColor, statusDetails?.twColor]"
                    class="tw-rounded-lg tw-px-4 tw-py-2 tw-font-semibold">
                    {{ statusDetails?.text }}
                </p>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-lg:tw-grid max-lg:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-flex">
                <v-btn
                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="large"
                    :color="item.to.name == $route.name ? 'primary' : 'gray-200'"
                    >{{ item.title }}</v-btn
                >
            </v-col>
            <v-col
                v-if="isLoading"
                cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <router-view v-if="central" />
    </v-container>
</template>

<script setup lang="ts">
    import { ref, watch, computed, provide } from 'vue';
    import { useRoute } from 'vue-router';
    import { ContentType } from '@/contentTypes';
    import { useI18n } from 'vue-i18n';
    import { getCentral } from '@/api/centrals';
    import { useAlert } from '../../composables/useAlert';
    import useError from '@/composables/useError';
    import { useConfig } from '@/composables/useConfig';
    import { centralStatus } from '../../config/config';
    import { useSocket } from '@/composables/useSocket';

    const $route = useRoute();
    const $alert = useAlert();
    const $error = useError();
    const $config = useConfig();
    const $socket = useSocket();
    const { t } = useI18n();

    const central = ref<ContentType<'Central'>>();
    const isLoading = ref(false);

    const navItems = computed(() => {
        return [
            {
                title: t('views.centrals.shared.buttons.zones'),
                to: { name: 'Central-Zones', params: { id: $route.params.id } },
            },
            {
                title: t('views.centrals.shared.buttons.loops'),
                to: { name: 'Central-Loops', params: { id: $route.params.id } },
            },
            {
                title: t('views.centrals.shared.buttons.devices'),
                to: { name: 'Central-Devices', params: { id: $route.params.id } },
            },
            {
                title: t('views.centrals.shared.buttons.configurations'),
                to: { name: 'Central-Config', params: { id: $route.params.id } },
            },
        ];
    });

    async function init() {
        isLoading.value = true;
        try {
            $socket.emit('central:zones', $route.params.id);
            const { data } = await getCentral($route.params.id as string);
            central.value = data.data;
        } catch (error) {
            $alert.showAlert({
                text: $error.getErrorMessage(error),
                type: 'error',
            });
        } finally {
            isLoading.value = false;
        }
    }

    const statusDetails = computed(() => {
        return $config.getStatus.value(central.value?.status as keyof typeof centralStatus);
    });

    $socket.on('central:status', (data: { centralId: number; status: string }) => {
        if (data.centralId == central.value?.id) {
            central.value!.status = data.status;
        }
    });

    watch(
        () => $route.params.id,
        () => {
            init();
        },
        {
            immediate: true,
        },
    );

    provide('central', central);
</script>

<style scoped></style>
