<template>
    <!-- Título e botões -->
    <v-row
        id="devices-search"
        align="start"
        justify="start"
        class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-bg-white">
        <v-col
            cols="12"
            class="tw-flex tw-flex-wrap tw-gap-2">
            <v-text-field
                class="tw-grow max-sm:tw-w-full"
                :model-value="search"
                @update:model-value="onSearch"
                clearable
                :placeholder="$t('shared.search')"></v-text-field>
            <v-btn
                size="large"
                @click="filtersOpen = !filtersOpen"
                density="comfortable"
                class="text-gray rounded-lg tw-border tw-border-gray"
                icon="mdi-tune"></v-btn>
            <v-btn
                size="large"
                @click="$query.toggleView"
                density="comfortable"
                class="text-gray rounded-lg tw-mr-2 tw-border tw-border-gray"
                :icon="$query.isGrid.value ? 'mdi-format-list-bulleted' : 'mdi-view-grid'"></v-btn>
            <v-btn-toggle
                multiple
                :model-value="typeFilters"
                @update:model-value="setFilters"
                class="tw-ml-auto">
                <v-btn
                    v-for="(details, type) in eventTypeDetails"
                    :key="type"
                    :color="details.bgColor"
                    :value="type"
                    :class="`text-${typeFilters.includes(type) ? details.color : details.bgColor} tw-border tw-border-gray`"
                    :icon="details.icon"></v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>
    <!-- CARDS -->
    <v-row
        v-show="$query.isGrid.value"
        align="start"
        justify="start"
        class="fill-width h-auto justify-start align-content-start">
        <v-col
            cols="12"
            :md="filtersOpen ? 12 : 6"
            :lg="filtersOpen ? 12 : 4"
            :xl="filtersOpen ? 6 : 4"
            :xxl="filtersOpen ? 4 : 3"
            v-for="(device, i) in devices"
            :key="i">
            <span
                v-if="i + 1 == devices.length - Math.round(pageSize / 2)"
                v-intersect="onIntersect"></span>
            <CardDeviceEvents
                :device="device"
                type="device" />
        </v-col>
        <v-col
            v-if="isLoading || (page != pageCount && pageCount != 0)"
            cols="12"
            class="tw-flex tw-justify-center">
            <v-progress-circular
                indeterminate
                color="primary"></v-progress-circular>
        </v-col>
        <v-col
            v-if="!devices.length && !isLoading"
            class="tw-text-center"
            cols="12">
            <p v-show="hasFilters">{{ $t('views.devices.noDevicesWithFilters') }}</p>
            <p v-show="!hasFilters">{{ $t('views.devices.noDevices') }}</p>
        </v-col>
    </v-row>
    <!-- TABLE -->
    <v-row
        v-show="!$query.isGrid.value"
        align="start"
        justify="start"
        class="fill-width h-auto justify-start align-content-start">
        <v-col cols="12">
            <v-data-table-virtual
                id="devicesTable"
                hover
                :no-data-text="hasFilters ? $t('views.devices.noDevicesWithFilters') : $t('views.devices.noDevices')"
                :sort-by="sort"
                color="primary"
                density="comfortable"
                @click:row="onRowClick"
                :headers="headers"
                :items="devices"
                fixed-header
                :loading="isLoading"
                @update:options="onOptionsUpdated">
                <template #item.events="{ item }">
                    <div class="tw-flex tw-gap-x-4 tw-py-3">
                        <v-badge
                            v-for="(device, i) in getDeviceEvents(item)"
                            :content="device.count"
                            :color="device.count ? 'primary' : 'gray'"
                            :class="`tw-z-${getDeviceEvents(item).length - i}`"
                            :key="device.type">
                            <v-icon
                                :color="device.color"
                                :icon="device.icon"
                                :class="`${device.twBgColor} tw-rounded-md tw-p-4`"
                                size="large"></v-icon>
                        </v-badge>
                    </div>
                </template>

                <template #item.actions="{ item, index }">
                    <span
                        v-if="index + 1 == devices.length - Math.round(pageSize / 2)"
                        v-intersect="onIntersect"></span>
                    <v-btn
                        variant="text"
                        :to="{
                            name: 'Device-Events',
                            params: {
                                id: item?.id,
                            },
                        }">
                        <v-icon color="primary">mdi-chevron-right</v-icon>
                    </v-btn>
                </template>
                <template #body.append>
                    <tr v-if="isLoading || (page != pageCount && pageCount != 0)">
                        <td
                            :colspan="headers && headers.length ? headers.length : 1"
                            class="tw-text-center">
                            <v-progress-circular
                                indeterminate
                                color="primary"></v-progress-circular>
                        </td>
                    </tr>
                </template>
            </v-data-table-virtual>
        </v-col>
    </v-row>
    <FilterDevices v-model="filtersOpen" />
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import CardDeviceEvents from '@/views/General/CardDeviceEvents.vue';
    import FilterDevices from '@/components/FilterDevices.vue';
    import { eventTypeDetails, pageSizes } from '@/config/config';
    import _, { get, has, isEqual, set } from 'lodash';
    import { countCentralDevicesEvents } from '@/api/centrals';
    import { CentralDevicesEventsCount, VDataTableOptions } from '@/types';
    import { useI18n } from 'vue-i18n';
    import { VDataTableServer } from 'vuetify/lib/components/index.mjs';
    import { useConfig } from '@/composables/useConfig';
    import { watch } from 'vue';
    import { useScroll } from '@/composables/useScroll';
    import { useQuery } from '@/composables/useQuery';
    import { unflatten } from 'flat';
    import { useAlert } from '../../composables/useAlert';
    import useError from '../../composables/useError';
    import { useDisplay } from '@/composables/useDisplay';

    const { toolbarHeight: toolbarDevicesHeight } = useDisplay('devices-search');

    const $route = useRoute();
    const $router = useRouter();
    const $query = useQuery();
    const tableScroll = useScroll('.v-data-table__thead');
    const { t } = useI18n();
    const $config = useConfig();
    const $alert = useAlert();
    const $error = useError();

    const firstUpdate = ref(true);
    const isLoading = ref(false);
    const page = ref($route.query?.page ? Number($route.query.page) : 1);
    const pageSize = ref(pageSizes[2]);
    const pageCount = ref(0);
    const devices = ref<CentralDevicesEventsCount['data']>([]);
    const search = ref('');
    const searchTimeoutId = ref<NodeJS.Timeout>();

    const filtersOpen = ref(false);

    const sort = ref<VDataTableOptions['sortBy']>([
        {
            key: 'name',
            order: 'asc',
        },
    ]);

    const headers = computed(
        () =>
            [
                {
                    title: t('views.centrals.devices.table.device'),
                    key: 'name',
                },
                {
                    title: t('views.centrals.devices.table.type'),
                    key: 'device.type',
                    value(item) {
                        return getDeviceType(item.device?.type)?.text || 'N/A';
                    },
                },
                {
                    title: t('views.centrals.devices.table.occurrences'),
                    key: 'events',
                    sortable: false,
                },
                {
                    title: '',
                    key: 'actions',
                    align: 'end',
                    sortable: false,
                },
            ] as VDataTableServer['headers'],
    );

    const typeFilters = computed(() => {
        return $route.query.type ? _.castArray($route.query.type) : [];
    });

    const hasFilters = computed(() => {
        return _.has(query.value, 'filters');
    });

    const query = computed(() => {
        const _query = {
            populate: {
                device: {
                    fields: ['type'],
                },
            },
            sort: sort.value.map((s) => `${s.key}:${s.order}`),
            pagination: {
                page: page.value,
                pageSize: pageSize.value,
            },
        };

        const routeQuery: any = unflatten($route.query);

        if (has(routeQuery, 'filters.zone')) {
            set(_query, 'filters.zone.id', get(routeQuery, 'filters.zone'));
        }

        if (has(routeQuery, 'filters.type')) {
            set(_query, 'filters.device.type', get(routeQuery, 'filters.type'));
        }

        if (search.value) {
            set(_query, 'filters.name.$containsi', search.value);
        }

        if (typeFilters.value.length > 0) {
            set(_query, 'filters.events.type', { $in: typeFilters.value });
            set(_query, 'filters.events.inProgress', true);
        }

        return _query;
    });

    function setFilters(typesSelected: (string | null)[]) {
        const query = {
            ..._.cloneDeep($route.query),
            type: typesSelected,
        };
        $router.replace({ query });
    }

    function onOptionsUpdated(options: VDataTableOptions) {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }
        if (page.value != options.page) tableScroll.scrollIntoView();

        page.value = options.page;
        pageSize.value = options.itemsPerPage;
        sort.value = options.sortBy;

        setRouterQuery();
    }

    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str;
            setRouterQuery();
        }, 250);
    }

    function getDeviceType(type: string | undefined) {
        if (!type)
            return {
                text: 'N/A',
            };
        return $config.deviceTypes.value.find((t) => t.value == type);
    }

    function getDeviceEvents(device: CentralDevicesEventsCount['data'][0]) {
        const events = [];
        for (const event in device.events) {
            events.push({
                type: event,
                count: device.events[event as keyof typeof device.events] as number,
                ...eventTypeDetails[event as keyof typeof eventTypeDetails],
            });
        }

        return events;
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            page: page.value,
            pageSize: pageSize.value,
            sort: sort.value.map((s) => `${s.key}:${s.order}`),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.replace({ query });
    }

    function onRowClick(e: Event, o: any) {
        $router.push({
            name: 'Device-Events',
            params: {
                id: o.item.id,
            },
        });
    }

    function onIntersect(isIntersecting: boolean) {
        if (!isIntersecting) return;
        console.log('Intersecting');

        if (page.value < pageCount.value) {
            page.value++;
        }
    }

    async function init(showLoading = true) {
        isLoading.value = showLoading;
        try {
            const { data } = await countCentralDevicesEvents($route.params.id as string, query.value);

            devices.value.push(...data.data);
            pageCount.value = data.meta.pagination.pageCount;
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: $error.getErrorMessage(error, 'views.devices.errors'),
            });
        } finally {
            isLoading.value = false;
        }
    }

    watch(
        query,
        (newQuery, oldQuery) => {
            if (!_.isEqual(newQuery, oldQuery)) {
                let showLoading = false;
                // if there is something different in the query other than the page, reset the page to 1
                if (!_.isEqual(_.omit(newQuery, 'pagination.page'), _.omit(oldQuery, 'pagination.page'))) {
                    showLoading = true;
                    page.value = 1;
                    pageCount.value = 0;
                    devices.value = [];
                }

                init(showLoading);
            }
        },
        {
            deep: true,
            immediate: true,
        },
    );

    watch(
        () => $route.params.id,
        () => {
            init();
        },
    );
</script>

<style lang="scss">
    @screen sm {
        #devicesTable .v-table__wrapper {
            overflow: unset !important;
        }
        #devicesTable .v-table__wrapper thead {
            top: calc(var(--v-layout-top) + v-bind(toolbarDevicesHeight));
            z-index: 5;
        }
    }
</style>
