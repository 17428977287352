<template>
    <v-card
        @click.stop="
            $router.push({
                name: 'Zone-Devices',
                params: { id: props.zone.id },
            })
        "
        color="gray"
        variant="outlined"
        class="pa-8">
        <div class="tw-flex tw-flex-col tw-gap-4">
            <p class="tw-text-center tw-text-2xl tw-font-semibold tw-text-dark">{{ props.zone.name }}</p>
            <p class="tw-text-center tw-text-gray">{{ $t('fields.devices') }} ({{ props.zone?.devices?.count }})</p>
            <div class="tw-mt-2 tw-flex tw-justify-center tw-gap-4">
                <v-badge
                    v-for="(event, i) in eventTypes"
                    tag="a"
                    :content="event.count"
                    class="tw-cursor-pointer"
                    :color="event.count ? 'primary' : 'gray'"
                    :class="`tw-z-${eventTypes.length - i}`"
                    @click.stop="
                        $router.push({
                            name: 'Zone-Devices',
                            params: {
                                id: props.zone.id,
                            },
                            query: {
                                type: event.type,
                            },
                        })
                    "
                    :key="event.type">
                    <v-icon
                        :color="event.color"
                        :icon="event.icon"
                        :class="`${event.twBgColor} tw-rounded-md tw-p-5`"
                        size="x-large"></v-icon>
                </v-badge>
            </div>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { CentralZonesEventsCount } from '@/types';
    import { eventTypeDetails } from '../../config/config';

    const props = defineProps<{
        zone: CentralZonesEventsCount['zones'][0];
    }>();

    const eventTypes = computed(() => {
        const eventTypes = [];
        for (const event in props.zone.events) {
            eventTypes.push({
                type: event,
                count: props.zone.events[event as keyof typeof props.zone.events],
                ...eventTypeDetails[event as keyof typeof eventTypeDetails],
            });
        }

        return eventTypes;
    });
</script>

<style scoped></style>
