<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            v-if="building"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-mb-4">
            <v-col
                cols="12"
                md="6">
                <h1 class="tw-text-2xl tw-font-bold">{{ building.name }}</h1>
            </v-col>
            <v-col
                class="tw-flex tw-gap-2 md:tw-justify-end md:tw-gap-6"
                cols="12"
                md="6">
                <v-btn
                    size="large"
                    width="128"
                    :color="$route.name == 'Building-Dashboard' ? 'primary' : 'gray-200'"
                    :to="{ name: 'Building-Dashboard', params: { id: building.id } }"
                    >{{ $t('views.buildings.dashboard') }}</v-btn
                >
                <v-btn
                    size="large"
                    width="128"
                    :color="$route.name == 'Building-Floors' ? 'primary' : 'gray-200'"
                    :to="{ name: 'Building-Floors', params: { id: building.id } }"
                    >{{ $t('views.buildings.blueprint') }}</v-btn
                >
                <v-btn
                    density="comfortable"
                    size="large"
                    class="tw-rounded-xl"
                    :color="$route.name == 'Building-Info' ? 'primary' : 'gray-200'"
                    :to="{ name: 'Building-Info', params: { id: building.id } }"
                    icon="mdi-information-variant"></v-btn>
            </v-col>
        </v-row>
        <router-view />
    </v-container>
</template>

<script setup lang="ts">
    import { ref, watch, provide } from 'vue';
    import { useRoute } from 'vue-router';
    import { ContentType } from '@/contentTypes';
    import { getBuilding } from '@/api/buildings';
    import { useError } from '@/composables/useError';
    import { useAlert } from '@/composables/useAlert';

    const route = useRoute();
    const $alert = useAlert();

    const isLoading = ref(false);
    const building = ref<ContentType<'Building'>>();
    const $error = useError();

    watch(
        () => route.params.id,
        () => {
            init();
        },
    );

    async function init() {
        isLoading.value = true;
        try {
            const { data } = await getBuilding(route.params.id.toString(), {
                // fields: ['name'],
                populate: {
                    company: {
                        fields: ['name'],
                    },
                    manager: {
                        fields: ['name'],
                    },
                },
            });
            building.value = data.data;
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: $error.getErrorMessage(error, 'views.events.errors'),
            });
        } finally {
            isLoading.value = false;
        }
    }

    provide('building', building);

    init();
</script>

<style scoped></style>
