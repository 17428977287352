import axios from 'axios';
import { useAuthStore } from '@/store/auth';
import slugify from 'slugify';
import { useRoute, useRouter } from 'vue-router';
import { get } from 'lodash';
import { useAppStore } from '@/store/app';

const instance = axios.create({
    baseURL: import.meta.env.VITE_API_URL + '/api',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = useAuthStore().getToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        const locale = useAppStore().getLocale;
        if (locale) {
            config.headers['Accept-Language'] = locale;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Add a response interceptor, if the response is 401, it will redirect to the login page
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (
            slugify(get(error, 'response.data.error.message', ''), {
                lower: true,
                trim: true,
                replacement: '_',
            }) == 'missing_or_invalid_credentials'
        ) {
            try {
                useAuthStore().logout();
            } catch (error) {
                console.log(error);
                localStorage.removeItem('auth');
            }
            try {
                if (useRoute().name != 'Login') useRouter().push({ name: 'Login' });
            } catch (error) {
                console.log(error);
                window.location.href = '/auth/login';
            }
        }
        return Promise.reject(error);
    },
);

export default instance;
