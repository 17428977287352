import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/loops';
export const API_REF = ContentTypesUID.Loop;

export async function getLoops(query: APIRequestParams<'Loop'> = {}): AxiosPromise<APIResponseMany<'Loop'>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`);
}

export async function getLoop(id: string, query: APIRequestParams<'Loop'> = {}): AxiosPromise<APIResponseSingle<'Loop'>> {
    return axios.get(`${API_URL}/${id}?${qs.stringify(query)}`);
}
