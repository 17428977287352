import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import { BuildingEventStatusCount, DataWrapper, BuildingCentralsEventsCount, BuildingFloorDevicesCount } from '@/types';
import axios from '@axios';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const API_URL = '/floors';
export const API_REF = ContentTypesUID.Floor;

export async function getFloor(id: string, query: APIRequestParams<'Floor'> = {}): AxiosPromise<APIResponseSingle<'Floor'>> {
    return axios.get(`${API_URL}/${id}?${qs.stringify(query)}`);
}

export async function getFloors(query: APIRequestParams<'Floor'> = {}): AxiosPromise<APIResponseMany<'Floor'>> {
    const params = qs.stringify(query);
    return axios.get(`${API_URL}?${params}`);
}

export async function createFloor(data: ContentType<'Floor', false>, query: APIRequestParams<'Floor'> = {}): AxiosPromise<APIResponseSingle<'Floor'>> {
    const params = qs.stringify(query);
    return axios.post(`${API_URL}?${params}`, { data });
}