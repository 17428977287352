export function useScroll(query: string) {
    function getElement() {
        const element = document.querySelector(query);
        if (element) {
            return element;
        } else {
            console.error(`Element with query ${query} not found`);
            return null;
        }
    }

    function scrollIntoView(position: 'start' | 'end' = 'start') {
        const element = getElement();
        if (!element) return;
        element.scrollIntoView({ behavior: 'smooth', block: position });
    }

    return {
        scrollIntoView,
    };
}
